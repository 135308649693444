import React from "react";
import {
  CloseButtonContainer,
  SuccessText,
  SuccessTitle,
} from "../../SuppliersPage.styles";
import ButtonComponent, {
  ButtonType,
} from "../../../../components/v2/Button/ButtonComponent";

import successImg from "../../../../assets/images/digital-ads.png";
import CloseIcon from "@mui/icons-material/Close";

interface SuccessfulSendProps {
  handleClose: () => void;
}
const SuccessfulSend: React.FC<SuccessfulSendProps> = ({ handleClose }) => {
  return (
    <>
      <img
        src={successImg}
        alt="thumb-up"
        style={{
          width: "400px",
          height: "400px",
          objectFit: "contain",
        }}
      />
      <SuccessTitle>Orçamento enviado com Sucesso!</SuccessTitle>
      <SuccessText>
        Agora nosso time de compras vai analisar sua proposta <br></br>e
        retornamos em breve.
      </SuccessText>
    </>
  );
};

export default SuccessfulSend;
