import {
  Box,
  Dialog,
  Typography,
  Stack,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../../components/v2/Button/ButtonComponent";
import { ArrowBackSharp } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Colors } from "../../../../theme/v2/variants";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  ContentContainer,
  ModalActionsContainer,
} from "./PurchasingAndContractingFilesModal.styles";
import ManageFilesService from "../../../../services/files/ManageFilesService";
import PlanFilesViewerModal from "../PlanFilesViewerModal/PlanFilesViewerModal";
import DeleteFileModal from "../DeleteFileModal/DeleteFileModal";
import FileCard from "./FileCard/FileCard";
import NoFilesDialog from "./NoFilesDialog/NoFilesDialog";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface PurchasingAndContractingFilesModalProps {
  planId: number | undefined;
  planType: string;
  toggleModal: () => void;
  open: boolean;
}

interface FileProps {
  id: number;
  fileName: string;
  uploadedBy: string;
  uploadedAt: string;
  fileUrl: string;
  category: string;
  fileType: string;
  identifier: string;
  uploadedFilePCC?: any;
}

const PurchasingAndContractingFilesModal: React.FC<
  PurchasingAndContractingFilesModalProps
> = ({ toggleModal, open, planId, planType }) => {
  const [filesList, setFilesList] = useState<FileProps[]>([]);
  const [fileId, setFileId] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const manageFilesService = new ManageFilesService();

  useEffect(() => {
    getUploadedFilesHandler();
  }, [planId]);

  const getUploadedFilesHandler = async () => {
    try {
      const data =
        await manageFilesService.getPurcharsingContractingUploadedFiles(
          planId!,
          planType
        );

      if (data && data.length > 0) {
        setFilesList([...data]);
        selectFileHandler(selectedIndex, data[selectedIndex]?.fileUrl);
      } else {
        setFilesList([]);
      }
    } catch (error) {
      console.error("Erro ao buscar arquivos:", error);
      setFilesList([]);
    }
  };

  const deleteUploadedFileHandler = async (id: number, type: string) => {
    setIsLoading(true);
    try {
      const data =
        await manageFilesService.deletePurcharsingContractingUploadedFiles(
          id,
          type
        );

      if (data?.id === id) {
        const updatedFilesList = filesList.filter((file) => file.id !== id);
        setFilesList([...updatedFilesList]);
        setIsLoading(false);
        setOpenDeleteModal(false);
      }
    } catch (error) {
      console.error("Erro ao excluir arquivo:", error);
      setIsLoading(false);
    }
  };

  const selectFileHandler = (idx: number, url: string) => {
    setSelectedIndex(idx);
    setSelectedFile(url);
  };

  const deleteFileHandler = (id: number) => {
    setFileId(id);
    setOpenDeleteModal(!openDeleteModal);
  };

  const selectedFileUrl = filesList[selectedIndex]?.fileUrl || selectedFile;

  if (filesList.length === 0) {
    return <NoFilesDialog open={open} toggleModal={toggleModal} />;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="paper"
      onClose={toggleModal}
      open={open}
      PaperProps={{
        style: dialogPaperStyles,
      }}
    >
      <Box sx={{ paddingX: "25px", paddingTop: "25px" }}>
        <Box sx={boxHeaderStyles}>
          <ButtonComponent
            text={"Voltar"}
            type={ButtonType.TRANSPARENT}
            startIcon={<ArrowBackSharp />}
            onClick={toggleModal}
          />
          <CloseRoundedIcon
            fontSize="medium"
            onClick={toggleModal}
            style={closeIconStyles}
          />
        </Box>
        <Typography sx={dialogTitleStyles}>Lista de Anexos</Typography>
      </Box>
      <DialogContent>
        <ContentContainer>
          <Box
            sx={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {filesList.map((file: FileProps, idx) => {
              return (
                <FileCard
                  idx={idx}
                  file={file}
                  selectedIndex={selectedIndex}
                  selectFileHandler={() => selectFileHandler(idx, file.fileUrl)}
                  deleteFileHandler={() => deleteFileHandler(file.id)}
                />
              );
            })}
          </Box>
          <Box
            height="100%"
            borderRadius="5px"
            sx={{ flex: "2", bgcolor: "#f5f5f5", overflow: "hidden" }}
          >
            <PlanFilesViewerModal fileUrl={selectedFileUrl} />
          </Box>
        </ContentContainer>
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <ModalActionsContainer>
            <a href={selectedFile} target="_blank" rel="noreferrer" download="document.pdf">
              <ButtonComponent
                state={
                  selectedFile === ""
                    ? ButtonState.DISABLED
                    : ButtonState.DEFAULT_ENABLED
                }
                fullWidth
                color={Colors.black}
                startIcon={<OpenInNewIcon fontSize="medium" />}
                text={"Abrir em Nova Aba"}
              />
            </a>
          <ButtonComponent
            startIcon={<FileUploadOutlinedIcon fontSize="medium" />}
            text={"Subir Novo Documento"}
            type={ButtonType.PRIMARY}
            state={ButtonState.DISABLED}
            onClick={() => {}}
          />
        </ModalActionsContainer>
      </DialogActions>
      {openDeleteModal && (
        <DeleteFileModal
          open={openDeleteModal}
          isLoading={isLoading}
          toggleModal={() => setOpenDeleteModal(!openDeleteModal)}
          deleteFileHandler={() => deleteUploadedFileHandler(fileId!, planType)}
        />
      )}
    </Dialog>
  );
};

const dialogPaperStyles = {
  width: "100vw",
  height: "100vh",
  borderRadius: "16px",
};

const boxHeaderStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const closeIconStyles = {
  cursor: "pointer",
};

const dialogTitleStyles = {
  fontSize: "32px",
  fontWeight: "bold",
};

const contentContainerStyles = {
  width: "100%",
  display: "flex",
  gap: "16px",
};

export default PurchasingAndContractingFilesModal;
