import React, { ChangeEvent, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import ReactPageBaseComponent from "../../components/BaseComponents/ReactPageBaseComponent";
import Alert from "../../components/Alert";

import {
  ContentContainer,
  FilesInput,
  FilesInputContainer,
  PageContainer,
  LogoContainer,
  UserImgContainer,
  WelcomeText,
  Highlight,
  SupplierName,
  SupplierCNPJ,
  FileItem,
  FileItemText,
  FilesContainer,
  SendContainer,
  SuccessTitle,
  SuccessText,
  CloseButtonContainer,
} from "./SuppliersPage.styles";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../components/v2/Button/ButtonComponent";
import Divider from "../../components/v2/Divider/Divider";
import { Colors } from "../../theme/v2/variants";

import logo from "../../vendor/logo.png";
import successImg from "../../assets/images/digital-ads.png";
import { User } from "react-feather";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CloseIcon from "@mui/icons-material/Close";

import LoadingProgress from "./components/LoadingProgress/LoadingProgress";
import SuccessfulSend from "./components/SuccessfulSend/SuccessfulSend";
import { useSearchParams } from "react-router-dom";
import ManageBudgetsService from '../../services/budgets/ManageBudgetsService'

interface FileProps {
  fileName: string;
}

interface OrderOfStepsProps {
  type: 'contracting' | 'purchasing';
}

const SuppliersPage: React.FC<OrderOfStepsProps> = ({ type }) => {
  const [isSendOpen, setIsSendOpen] = useState(true);
  const [isLoadingOpen, setIsLoadingOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [showFileSizeError, setShowFileSizeError] = useState<boolean>(false);
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpened: false,
  });

  const [supplier, setSupplier] = useState<any>({});
  const [purchasingPlan, setPurchasingPlan] = useState<any>({});
  const [contractingPlan, setContractingPlan] = useState<any>({});
  const [planType, setPlanType] = useState('');

  const [filesList, setFilesList] = useState<File[]>([]);

  const [progress, setProgress] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const manageBudgetsService = new ManageBudgetsService();

  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      const validateToken = async () => {
        try {
          const response = await manageBudgetsService.validateToken(token)
          if (response.status === 200 && response.data.success) {
            setPlanType(response.data.type);
            setSupplier(response.data.supplier);
            setPurchasingPlan(response.data.purchasingPlan);
            setContractingPlan(response.data.contractingPlan);
            setIsTokenValid(true);
          } else {
            setIsTokenValid(false)
          }
        } catch (error) {
          console.log(error);
        }
        setIsReady(true)
      }
      validateToken()
    }
  }, []);

  const onAlertClose = () => {
    setAlert({
      title: "",
      message: "",
      isOpened: false,
    });
  };

  const handleSendClick = async () => {
    setIsSendOpen(false);
    setIsLoadingOpen(true);
    setIsSuccessOpen(false);

    const budgetDTO = {
      fileName: `orcamento-${supplier.name}`,
      supplierId: supplier.id,
      purchasingPlanId: purchasingPlan?.id,
      contractingPlanId: contractingPlan?.id
    }

    try {
      const response = await manageBudgetsService.uploadFiles({
        files: filesList,
        token: token || '',
        budgetDTO: budgetDTO,
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(progress); // Update progress state
        }
      });

      if (response.success) {
        handleSuccessSend()
      } else {
        handleClose()
        setAlert({
          title: "Erro enviar cotação",
          message: response.message,
          isOpened: true,
        })
      }
    } catch(error) {
      handleClose()
      setAlert({
        title: "Erro ao enviar arquivos",
        message: "Não foi possível fazer o envio dos arquivos selecionados",
        isOpened: true,
      })
    }


  };

  const handleSuccessSend = () => {
    setIsSendOpen(false);
    setIsLoadingOpen(false);
    setIsSuccessOpen(true);
    setFilesList([]);
  };

  const handleClose = () => {
    setIsSendOpen(true);
    setIsLoadingOpen(false);
    setIsSuccessOpen(false);
  };

  const selectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0].size < 15 * 1024 * 1024) {
        const selectedFiles = Array.from([e.target.files[0]]);
        setFilesList(selectedFiles);
        setShowFileSizeError(false)
      } else {
        setShowFileSizeError(true)
      }
    }
  };

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        <ContentContainer>
          {!isReady && (
            <h2>Carregando...</h2>
          )}

          {isReady && !isTokenValid && (
            <h2>Token inválido ou já utilizado</h2>
          )}

          {isReady && isTokenValid && isSendOpen && (
            <>
              <UserImgContainer>
                <User size={48} />
              </UserImgContainer>

              <WelcomeText>
                Olá, <Highlight>{supplier.name}</Highlight>
              </WelcomeText>

              <Divider width="96px" marginBottom="23px" marginTop="25px" />

              <SupplierName>Selecione um arquivo e sua cotação será enviada automaticamente</SupplierName>
              <SupplierCNPJ>Formato PDF de no máximo 15 MB</SupplierCNPJ>

              <FilesContainer>
                {filesList
                  .slice()
                  .reverse()
                  .map((file: File, idx) => (
                    <FileItem key={idx}>
                      <InsertDriveFileOutlinedIcon fontSize="medium" />
                      <FileItemText>{file.name}</FileItemText>
                      <DeleteOutlineRoundedIcon
                        fontSize="medium"
                        onClick={() => {
                          const updatedFilesList = filesList.filter(
                            (_, index) => index !== idx
                          );
                          setFilesList(updatedFilesList);
                        }}
                      />
                    </FileItem>
                  ))}
              </FilesContainer>

              <FilesInputContainer>
                <FilesInput>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    accept=".pdf"
                    onChange={selectFile}
                  />
                  <ButtonComponent
                    type={ButtonType.LINK}
                    state={
                      filesList.length > 0
                        ? ButtonState.DISABLED
                        : ButtonState.DEFAULT_ENABLED
                    }
                    padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                    text={"Busque seu Arquivo Aqui"}
                    onClick={() => {
                      const fileInput = document.getElementById(
                        "fileInput"
                      ) as HTMLInputElement | null;
                      if (fileInput) {
                        fileInput.click();
                      }
                    }}
                  />
                </FilesInput>
              </FilesInputContainer>

              {showFileSizeError && (
                <div>
                  <Typography variant="caption" color={Colors.alertRed}>
                    O arquivo deve ter no máximo 15 MB
                  </Typography>
                </div>
              )}

              {filesList.length > 0 && (
                <SendContainer>
                  <ButtonComponent
                    startIcon={<SendOutlinedIcon />}
                    type={ButtonType.LINK}
                    padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                    text={"Enviar Orçamento"}
                    onClick={handleSendClick}
                  />
                </SendContainer>
              )}

              <LogoContainer>
                <img
                  src={logo}
                  alt="gobuilderLogo"
                  style={{
                    width: "110px",
                    height: "30px",
                    objectFit: "contain",
                  }}
                />
              </LogoContainer>
            </>
          )}

          {isLoadingOpen && <LoadingProgress progress={progress} />}

          {isSuccessOpen && <SuccessfulSend handleClose={handleClose} />}
        </ContentContainer>
      </PageContainer>
      <Alert
        onClose={() => onAlertClose()}
        isOpened={alert.isOpened}
        title={alert.title}
        message={alert.message}
        buttonLabel="Sair"
      />
    </ReactPageBaseComponent>
  );
};

export default SuppliersPage;
