import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import typography from "../../../../theme/newTheme/typography";
import ButtonComponent, {
  ButtonType,
} from "../../../../components/v2/Button/ButtonComponent";
import CheckIcon from "@mui/icons-material/Check";
import { LinearProgress } from "@mui/material";


interface DeleteFileModalProps {
  toggleModal: () => void;
  deleteFileHandler: () => void;
  open: boolean;
  isLoading: boolean; // Adicionando a nova prop
}

const DeleteFileModal: React.FC<DeleteFileModalProps> = ({
  open,
  toggleModal,
  deleteFileHandler,
  isLoading, // Recebendo a nova prop
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: { borderRadius: "15px", padding: "10px 5px" },
      }}
      onClose={toggleModal}
      open={open}
    >
      <DialogTitle
        fontSize={typography.h3?.fontSize}
        fontWeight={typography.fontWeightBold}
      >
        Excluir Arquivo
      </DialogTitle>
      <DialogContent>
        <Typography fontSize={typography.body1?.fontSize}>
          Tem certeza que deseja excluir esse arquivo? Essa ação é irreversível.
        </Typography>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <LinearProgress style={{ width: '100%' }} />
        ) : (
          <ButtonComponent
            startIcon={<CheckIcon fontSize="medium" />}
            text={"Confirmar"}
            type={ButtonType.DANGER}
            onClick={deleteFileHandler}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFileModal;
