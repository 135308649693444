import moment from "moment";

export const parseAsUTCWithOffset = (date: string): Date => {
  const [year, month, day] = date.split("T")[0].split("-");
  return new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), 3));
};

export const formatDate = (date?: Date): string => {
  if (!date || isNaN(date.getTime())) return "";
  return moment(date).format("DD/MM/YYYY");
};

export const setStartOfDay = (date: string | Date): Date => {
  const newDate = new Date(date);
  newDate.setUTCHours(0, 0, 0, 0);
  return newDate;
};

export const setEndOfDay = (date: string): Date => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 3);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};
