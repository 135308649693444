import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useNavigation } from "react-router-dom";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  IconButton,
  Grid,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Container,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/auth";
import EmptyClientAndConstructionState from "../../../components/StateComponents/EmptyClientAndConstruction";
import { useDashboard } from "../../../contexts/dashboard";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const WrapperDataGrid = styled.div`
  width: 100%;
`;

const ContentDataGrid = styled.div`
  height: 48vh;
  overflow-y: auto;
`;

type Construction = {
  id: number;
  name: string;
  updatedAt: Date;
};

type Props = {
  section: string;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbar />
    </GridToolbarContainer>
  );
}
const ActionsPlanList: React.FC<Props> = ({ section }) => {
  const [constructions, setConstructions] = useState<Construction[]>([]);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { constructionId } = useDashboard()

  useEffect(() => {
    if(constructionId) {
      navigate(`/planos-acoes/editar/${+constructionId}`)
    }
  }, [constructionId])
  

  return (
    <Container sx={{display: "flex", justifyContent: 'center' , height: "100%"}}>
      <EmptyClientAndConstructionState pageDescription="o plano de ação" />
    </Container>
  );

  useEffect(() => {
    if (user.id) {
      const responsibleClientId = user.responsibleClientId
        ? Number(user.responsibleClientId)
        : 0;

      const fetchActionsPlan = async () => {
        try {
          const response = await api.get("api/actions/", {
            headers: { clientId: responsibleClientId },
          });

          if (response.status === 200) {
            setConstructions(response.data);
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchActionsPlan();
    }
  }, [user]);

  const columns: GridColDef[] = [
    {
      field: "constructionId",
      headerName: "ID",
      flex: 0.3,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueFormatter: (params) => String(params.value),
    },
    {
      field: "constructionName",
      headerName: "Obra",
      flex: 1,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueFormatter: (params) => String(params.value),
    },
    {
      field: "restrictionMappingUpdatedAt",
      headerName: "Atualizado em",
      flex: 0.8,
      type: "string",
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        const date = new Date(String(params.value));
        return `${date.toLocaleDateString("pt-br")} ${date.toLocaleTimeString(
          "pt-br"
        )}`;
      },
    },
    {
      field: "actions",
      headerName: "Editar",
      width: 50,
      flex: 0.3,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <IconButton
            edge="end"
            aria-label="editar"
            onClick={() =>
              navigate(`/planos-acoes/editar/${params.row.constructionId}/`)
            }
            size="large"
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {section}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card>
        <CardContent>
          <WrapperDataGrid>
            <ContentDataGrid>
              <DataGrid
                rows={constructions}
                columns={columns}
                components={{ Toolbar: CustomToolbar }}
                getRowId={(row) => row.constructionId}
              />
            </ContentDataGrid>
          </WrapperDataGrid>
        </CardContent>
      </Card>
    </>
  );
};

export default ActionsPlanList;
