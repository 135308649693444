import { useEffect, useState } from "react";
import { useDashboard } from "../../../contexts/dashboard";
import { BaseState } from "../../Actions/ViewActions/utils/BaseState";
import ReactPageBaseComponent from "../../../components/BaseComponents/ReactPageBaseComponent";
import styled from "styled-components";
import { Colors } from "../../../theme/variants";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import ErrorComponent from "../../../components/StateComponents/ErrorComponent";
import EmptyClientAndConstructionState from "../../../components/StateComponents/EmptyClientAndConstruction";
import Title from "../../../components/Typography/Tittle";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import RestrictionsService from "../../../services/restrictions/RestrictionsService";
import TableComponent from "../../../components/table/TableComponent";
import InputValueComponent from "../../../components/InputValues/InputValueComponent";
import ButtonComponent, {
  ButtonType,
} from "../../../components/Button/ButtonComponent";
import RestrictionMappingModel, {
  IRestrictionMappingModel,
} from "../models/RestrictionModel";
import { TableItemModel } from "../../../components/table/models/TableItemModel";
import { TableItemTypeENUM } from "../../../components/table/models/TableItemENUM";
import { TableActionsENUM } from "../../../components/table/models/TableActionsENUM";
import { ReactComponent as AddIcon } from "../../../assets/icon-plus.svg";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteItemPopUp from "../../../components/Dialogs/ConfirmDeleteItemPopUp";
import { set } from "date-fns";
import RestrictionMappingService from "../../../services/restrictionMapping/RestrictionMappingService";
import { Snackbar } from "@mui/material";
import { useAuth } from "../../../contexts/auth";
import { isClientTeam } from "../../../config/app-info";

interface RestrictionsPageProps {}

const RestrictionListPage: React.FC<RestrictionsPageProps> = () => {
  const navigation = useNavigate();
  const service = new RestrictionsService();
  const restrictionMappingService = new RestrictionMappingService();
  const { constructionId, constructions, clientId, clients } = useDashboard();
  const [restrictions, setRestrictions] = useState<IRestrictionMappingModel[]>(
    []
  );
  const [filteredRestrictions, setFilteredRestrictions] = useState<
    IRestrictionMappingModel[]
  >([]);
  const [state, setState] = useState<BaseState>(BaseState.loading);
  const [selectedRestricionMapping, setSelectedRestrictionMapping] =
    useState<IRestrictionMappingModel | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [isLoadingDeletePopUp, setIsLoadingDeletePopUp] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { user } = useAuth();
  const tableItemsKeys: TableItemModel[] = [
    {
      valueKey: "name",
      columnName: "Restrição",
      uniqueIdKey: "id",
    },
    {
      valueKey: "criteriaName",
      columnName: "Critério",
      uniqueIdKey: "id",
    },
    {
      valueKey: "status",
      columnName: "Status",
      uniqueIdKey: "id",
    },
  ];

  const setActionsButIfUserIsNotClientTeam = () => {
    if (!isClientTeam(user?.role)) {
      tableItemsKeys.push({
        valueKey: "actions",
        columnName: "Ações",
        type: TableItemTypeENUM.action,
        uniqueIdKey: "id",
        actions: [TableActionsENUM.EDIT, TableActionsENUM.DELETE],
      });
    } else {
      tableItemsKeys.push({
        valueKey: "actions",
        columnName: "Ações",
        type: TableItemTypeENUM.action,
        uniqueIdKey: "id",
        actions: [],
      });
    }
  };

  setActionsButIfUserIsNotClientTeam();

  useEffect(() => {
    fetchRestrictions(constructionId);
  }, [constructionId]);

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const fetchRestrictions = async (constructionId: number) => {
    if (isConstructionNotSelected()) return;
    try {
      setState(BaseState.loading);
      const restrictions = await service.fetchRestrictionByConstructionId(
        constructionId
      );
      const restrictionList =
        RestrictionMappingModel.parseFromEntityArray(restrictions);
      setRestrictions(restrictionList);
      setFilteredRestrictions(restrictionList);

      setState(BaseState.success);
    } catch (error) {
      setState(BaseState.error);
    }
  };

  const onSearch = (value: string) => {
    setInputValue(value);

    const filtered = restrictions.filter(
      (restriction) =>
        restriction.name.toLowerCase().includes(value.toLocaleLowerCase()) ||
        restriction.criteriaName.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredRestrictions(filtered);
  };

  const seeAll = () => {
    setInputValue("");
    setFilteredRestrictions(restrictions);
  };

  const goToCreateRestriction = () => {
    navigation("/mapeamento-restricoes/cadastrar/");
  };

  const onActionClick = (
    action: TableActionsENUM,
    item: IRestrictionMappingModel
  ) => {
    if (action === TableActionsENUM.EDIT) {
      navigation(`/mapeamento-restricoes/editar/${item.restrictionId}`);
    }

    if (action === TableActionsENUM.DELETE) {
      setSelectedRestrictionMapping(item);
      setIsDeletePopUpOpen(true);
    }
  };

  const onConfirmDelete = async () => {
    setIsDeletePopUpOpen(false);

    if (
      selectedRestricionMapping?.id === undefined ||
      selectedRestricionMapping?.id === null
    ) {
      return;
    }

    if (state === BaseState.loading) {
      return;
    }

    setState(BaseState.loading);

    try {
      restrictionMappingService
        .deleteRestrictionMappingAndActions(selectedRestricionMapping?.id)
        .then(() => {
          fetchRestrictions(constructionId);
          setSelectedRestrictionMapping(null);
          setSnackbarMessage("Restrição excluída com sucesso");
          setOpenSnackbar(true);
        })
        .catch(() => {
          setSnackbarMessage("Erro ao excluir restrição");
          setOpenSnackbar(true);
        });
    } catch (error) {
      setSnackbarMessage("Erro ao excluir restrição");
      setOpenSnackbar(true);
      setState(BaseState.success);
    }
  };

  const onCloseDialog = () => {
    setIsDeletePopUpOpen(false);
    setSelectedRestrictionMapping(null);
  };

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {isConstructionNotSelected() ? (
          <CenterContainer>
            <EmptyClientAndConstructionState pageDescription="o cadastro de restrições" />
          </CenterContainer>
        ) : null}
        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <CenterContainer>
            <LoadingComponent />
          </CenterContainer>
        ) : null}
        {state === BaseState.error ? (
          <CenterContainer>
            <ErrorComponent />
          </CenterContainer>
        ) : null}
        {state === BaseState.success && !isConstructionNotSelected() ? (
          <ContentContainer>
            <HeaderContainer>
              <AboutContainer>
                <Title>CADASTRO DE RESTRIÇÕES</Title>
                <ButtonComponent
                  text="Nova"
                  type={ButtonType.PRIMARY}
                  startIcon={<AddIcon />}
                  padding={{ left: 16, right: 16, top: 8, bottom: 8 }}
                  onClick={() => goToCreateRestriction()}
                />
              </AboutContainer>
              <SearchContainer>
                <InputValueComponent
                  value={inputValue}
                  onChange={onSearch}
                  label="Buscar por restrição ou critério "
                  endIcon={<SearchIcon />}
                />
                <ButtonComponent
                  text="Ver todos"
                  type={ButtonType.TRANSPARENT}
                  padding={{ left: 16, right: 16, top: 8, bottom: 8 }}
                  onClick={() => seeAll()}
                />
              </SearchContainer>
            </HeaderContainer>
            <TableContainer>
              <TableComponent
                data={filteredRestrictions}
                columns={tableItemsKeys}
                onActionClick={(action, item) => onActionClick(item, action)}
                isHeaderFilterEnabled
              />
            </TableContainer>
          </ContentContainer>
        ) : null}{" "}
      </PageContainer>
      <ConfirmDeleteItemPopUp
        title="Excluir restrição"
        description="Deseja realmente excluir este serviço?"
        openDeleteActionDialog={isDeletePopUpOpen}
        isLoadingDialog={isLoadingDeletePopUp}
        dialogButtonCancelOnClick={() => onCloseDialog()}
        dialogButtonConfirmOnClick={() => onConfirmDelete()}
      />
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />
    </ReactPageBaseComponent>
  );
};

const PageContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-columns: 1fr;
  /* padding-top: 60px; */
  background-color: ${Colors.neutralColorSurface};
  overflow-x: hidden;
  overflow-y: hidden;
  align-content: start;
`;

const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const TableContainer = styled.div`
  display: grid;
  width: 100%;
  overflow-y: hidden;
  grid-template-rows: 1fr;
  margin-top: 32px;

  /* @media (min-width: ${MOBILE_BREAKPOINT}px) {
    padding-left: 32px;
    padding-right: 65px;
    padding-bottom: 24px;
  } */

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

const HeaderContainer = styled.div`
  display: grid;
  max-width: 100%;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content;
    align-content: start;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content;
  }
`;

const AboutContainer = styled.div`
  display: grid;
  align-content: start;
  grid-template-columns: 1fr max-content;
  /* padding-right: 32px; */
`;

const SearchContainer = styled.div`
  display: grid;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 400px 121px;
    grid-gap: 24px;
    /* padding-left: 32px; */
    /* padding-right: 32px; */
    margin-top: 32px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 254px max-content;
    grid-gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 16px;
  }
`;

export default RestrictionListPage;
