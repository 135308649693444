import styled from "styled-components";
import DropDownComponent from "../../../../components/InputValues/DropDownComponent";
import InputValueComponent from "../../../../components/InputValues/InputValueComponent";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { IActionModel } from "../../../Actions/models/ActionModel";
import DropDownChecklistComponent from "../../../../components/v2/InputValues/DropDownChecklist";
import { UserResponsible } from "../../../../services/UserService";
import ButtonComponent, {
  ButtonType,
} from "../../../../components/Button/ButtonComponent";
import { ReactComponent as TrashIcon } from "../../../../assets/trash-primary-color.svg";
import { Colors } from "../../../../theme/v2/variants";
import { ActionStatus } from "../../../Actions/models/ActionStatus";
import { restrictionPriorities } from "../../../../config/app-info";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import InputDateComponent from "../../../../components/InputValues/input-date/InputDateComponent";
import VerticalServiceModel from "../../../../services/interfaces/VerticalServiceModel";
import { useEffect } from "react";

interface NewRestrictionProps {
  action: IActionModel;
  responsibles: UserResponsible[];
  isDisabled?: boolean;
  isAddState?: boolean;
  index: number;
  onActionChange: (action: IActionModel) => void;
  onDeletedAction: (action: IActionModel) => void;
  isOnVerticalServicePopUp?: boolean;
  model?: VerticalServiceModel;
}

const NewActionComponent: React.FC<NewRestrictionProps> = ({
  action,
  responsibles,
  onActionChange,
  isDisabled,
  isAddState,
  onDeletedAction,
  index,
  isOnVerticalServicePopUp = false,
  model,
}) => {
  function statusColor(status: string): { color: string; title: string } {
    let color = null;
    let title = null;
    switch (status) {
      case ActionStatus.PENDING:
        color = Colors.primaryColor;
        title = "A fazer";
        break;
      case ActionStatus.IN_PROGRESS:
        color = Colors.secondaryColor;
        title = "Em andamento";
        break;
      case ActionStatus.DONE:
        color = Colors.feedbackSuccess;
        title = "Concluído";
        break;
      case ActionStatus.DELAYED:
        color = Colors.feedbackError;
        title = "Em atraso";
        break;
      default: {
        color = Colors.primaryColor;
        title = "A fazer";
      }
    }
    return { color: color, title: title };
  }

  const beginDateOnChange = (date: any) => {
    if (model) {
      model.beginDate = new Date(date);
      if (model.endDate && model.endDate < model.beginDate) {
        model.endDate = new Date(date);
      }
    }

    action.beginDate = new Date(date);
    if (action.endDate < action.beginDate) {
      action.endDate = new Date(date);
    }
    onActionChange(action);
  };

  const endDateOnChange = (date: any) => {
    if (model) {
      model.endDate = new Date(date);
      if (model.beginDate && model.beginDate > model.endDate) {
        model.beginDate = new Date(date);
      }
    }

    action.endDate = new Date(date);
    if (action.beginDate > action.endDate) {
      action.beginDate = new Date(date);
    }
    onActionChange(action);
  };

  const onActionDescriptionChange = (value: string) => {
    action.actionDescription = value;
    onActionChange(action);
  };

  const onObservationChange = (value: string) => {
    action.how = value;
    onActionChange(action);
  };

  const onResponsibleChange = (_value: string[]) => {
    const selectedResponsibles = responsibles.filter((responsible) =>
      _value.includes(responsible.name)
    );
    action.responsibles = selectedResponsibles;
    onActionChange(action);
  };

  const onPriorityChange = (value: string) => {
    action.priority = restrictionPriorities.indexOf(value);
    onActionChange(action);
  };

  useEffect(() => {
    if (model) {
      action.beginDate = model.beginDate || new Date();
      action.endDate = model.endDate || new Date();
    }
  }, [model]);

  return (
    <Container isOnVerticalServicePopUp={isOnVerticalServicePopUp}>
      {isOnVerticalServicePopUp ? (
        <>
          <RowZeroOnPopUp>
            <StatusContainer>
              {action.status ? (
                <StatusIndicator
                  style={{ backgroundColor: Colors.feedbackSuccess }}
                />
              ) : null}
              {action.status ? <SucessText>Concluída</SucessText> : null}
            </StatusContainer>
            {isDisabled === true ||
            (action.generatedByEmptyObject && index == 0) ? null : (
              <div style={{ marginBottom: "16px" }}>
                <ButtonComponent
                  type={ButtonType.OUTLINE}
                  startIcon={<TrashIcon />}
                  text="Excluir"
                  onClick={() => onDeletedAction(action)}
                />
              </div>
            )}
          </RowZeroOnPopUp>
          <RowOneOnPopUp>
            <InputValueComponent
              variant="filled"
              isGray={true}
              key="Ação"
              label="Ação"
              onChange={(value) => onActionDescriptionChange(value)}
              value={action.actionDescription}
              isDisabled={isDisabled}
            />
          </RowOneOnPopUp>
          <RowTwoOnPopUp>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <InputDateComponent
                label="Início"
                variant="filled"
                value={model ? model.beginDate : action.beginDate}
                format="DD/MM/YYYY"
                enable={!isDisabled}
                onChange={beginDateOnChange}
                isGray={true}
              />
              <InputDateComponent
                label="Fim"
                variant="filled"
                value={model ? model.endDate : action.endDate}
                format="DD/MM/YYYY"
                enable={!isDisabled}
                onChange={endDateOnChange}
                isGray={true}
              />
            </LocalizationProvider>
          </RowTwoOnPopUp>
          <RowThreeOnPopUp>
            <DropDownChecklistComponent
              variant="filled"
              height="56px"
              backgroundColor={Colors.concrete}
              key="Responsável"
              defaultValue="Status"
              placeholder="Responsáveis"
              size="small"
              isDisabled={isDisabled}
              selectedValues={
                responsibles
                  .filter((responsible) =>
                    action.responsibles?.find(
                      (actionResponsible) =>
                        responsible.id === actionResponsible.id
                    )
                  )
                  .map((responsible) => responsible.name) || []
              }
              values={responsibles.map((responsible) => responsible.name)}
              onSelectedValueChanged={(value) => onResponsibleChange(value)}
            />
            <DropDownComponent
              variant="filled"
              height="56px"
              bakcgrounColor={Colors.concrete}
              key="Prioridade"
              componentKey="priority"
              label="Prioridade"
              defaultValue="Prioridade"
              selectedValue={
                action.priority !== undefined
                  ? restrictionPriorities[action.priority || 0]
                  : undefined
              }
              disabled={isDisabled}
              values={restrictionPriorities}
              onSelectedValueChanged={(key, value) => onPriorityChange(value)}
            />
          </RowThreeOnPopUp>
          <RowFourOnPopUp>
            <InputValueComponent
              variant="filled"
              isGray={true}
              key="Observação"
              label="Observação"
              isDisabled={isDisabled}
              onChange={onObservationChange}
              value={action.how || action.observation || ""}
            />
          </RowFourOnPopUp>
        </>
      ) : (
        <>
          <RowZero>
            <StatusContainer>
              {action.status ? (
                <StatusIndicator
                  style={{ backgroundColor: Colors.feedbackSuccess }}
                />
              ) : null}
              {action.status ? <SucessText>Concluída</SucessText> : null}
            </StatusContainer>
            {isDisabled === true ||
            (action.generatedByEmptyObject && index == 0) ? null : (
              <div style={{ marginBottom: "16px" }}>
                <ButtonComponent
                  type={ButtonType.OUTLINE}
                  startIcon={<TrashIcon />}
                  text="Excluir"
                  onClick={() => onDeletedAction(action)}
                />
              </div>
            )}
          </RowZero>
          <RowOne>
            <InputValueComponent
              variant="filled"
              isGray={true}
              key="Ação"
              label="Ação"
              onChange={(value) => onActionDescriptionChange(value)}
              value={action.actionDescription}
              isDisabled={isDisabled}
            />
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <InputDateComponent
                label="Início"
                variant="filled"
                value={action.beginDate}
                format="DD/MM/YYYY"
                enable={!isDisabled}
                onChange={beginDateOnChange}
                isGray={true}
              />
              <InputDateComponent
                label="Fim"
                variant="filled"
                value={action.endDate}
                format="DD/MM/YYYY"
                enable={!isDisabled}
                onChange={endDateOnChange}
                isGray={true}
              />
            </LocalizationProvider>
          </RowOne>
          <RowTwo>
            <DropDownChecklistComponent
              variant="filled"
              height="56px"
              backgroundColor={Colors.concrete}
              key="Responsável"
              defaultValue="Status"
              placeholder="Responsáveis"
              size="small"
              isDisabled={isDisabled}
              selectedValues={
                responsibles
                  .filter((responsible) =>
                    action.responsibles?.find(
                      (actionResponsible) =>
                        responsible.id === actionResponsible.id
                    )
                  )
                  .map((responsible) => responsible.name) || []
              }
              values={responsibles.map((responsible) => responsible.name)}
              onSelectedValueChanged={(value) => onResponsibleChange(value)}
            />
            <DropDownComponent
              variant="filled"
              height="56px"
              bakcgrounColor={Colors.concrete}
              key="Prioridade"
              componentKey="priority"
              label="Prioridade"
              defaultValue="Prioridade"
              selectedValue={
                action.priority !== undefined
                  ? restrictionPriorities[action.priority || 0]
                  : undefined
              }
              disabled={isDisabled}
              values={restrictionPriorities}
              onSelectedValueChanged={(key, value) => onPriorityChange(value)}
            />
            <InputValueComponent
              variant="filled"
              isGray={true}
              key="Observação"
              label="Observação"
              isDisabled={isDisabled}
              onChange={onObservationChange}
              value={action.how || action.observation || ""}
            />
          </RowTwo>
        </>
      )}
    </Container>
  );
};

const Container = styled.div<{ isOnVerticalServicePopUp: boolean }>`
  display: grid;
  grid-template-rows: max-content max-content;
  ${(props) =>
    !props.isOnVerticalServicePopUp
      ? `
    border-radius: 16px;
    padding: 32px 24px;
    background-color: ${Colors.neutralColorWhite};
    border: 1px solid rgba(178, 178, 178, 0.25);
    `
      : {}}
`;

const RowOne = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 4fr 1fr 1fr;
`;

const RowOneOnPopUp = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr;
`;

const RowTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 4fr;
  grid-column-gap: 24px;
  margin-top: 16px;
`;

const RowTwoOnPopUp = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  margin-top: 16px;
`;

const RowThreeOnPopUp = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  margin-top: 16px;
`;

const RowFourOnPopUp = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 24px;
  margin-top: 16px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const RowZero = styled.div`
  display: grid;
  width: 100%;
  justify-content: end;
  grid-template-columns: max-content minmax(0px, 110px);
  column-gap: 16px;
`;

const RowZeroOnPopUp = styled.div`
  display: grid;
  width: 100%;
  justify-content: end;
  grid-template-columns: max-content minmax(0px, 110px);
  column-gap: 16px;
`;

const StatusIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-bottom: 16px;
`;

const SucessText = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
`;

export default NewActionComponent;
