import styled from "styled-components";
import DropDownComponent from "../../../../components/InputValues/DropDownComponent";
import { useEffect, useState } from "react";
import RestrictionMappingModel, {
  IRestrictionMappingModel,
} from "../../models/RestrictionModel";
import {
  restrictionCategories,
  restrictionImpacts,
  restrictionPriorities,
} from "../../../../config/app-info";
import ICriteriaModel from "../../models/CriteriaModel";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";
import { Autocomplete, TextField } from "@mui/material";
import { textFieldDarkStyle } from "../../../../utils/custom-styles/textFieldCustomDarkStyles";
import { Colors } from "../../../../theme/v2/variants";

interface NewRestrictionProps {
  restriction: RestrictionMappingModel;
  restrictions: RestrictionMappingModel[];
  criteria: ICriteriaModel[];
  disabled: boolean;
  disabledAllCriteriaAndRestriction: boolean;
  onRestrictionChange: (restriction: RestrictionMappingModel) => void;
  onCriteriaChangeCallback: (id: string, name: string) => void;
  onSelectedRestrictionChange: (id: string | null) => void;
  onNewRestrictionNameChange: (value: string | undefined) => void;
  isOnVerticalServicePopUp?: boolean;
}

const NewRestrictionComponent: React.FC<NewRestrictionProps> = ({
  restrictions,
  criteria,
  restriction,
  onRestrictionChange,
  onCriteriaChangeCallback,
  onSelectedRestrictionChange,
  onNewRestrictionNameChange,
  disabled,
  disabledAllCriteriaAndRestriction,
  isOnVerticalServicePopUp = false,
}) => {
  const [restrictionByCriteria, setRestrictionsByCriteria] =
    useState<IRestrictionMappingModel[]>(restrictions);
  const [selectedRestriction, setSelectedRestriction] =
    useState<IRestrictionMappingModel>(restriction);
  const [action, setAction] = useState<string>(restriction.comment || "");
  const [autoCompleteValue, setAutoCompleteValue] = useState<string>(
    restriction.name || ""
  );

  useEffect(() => {
    setSelectedRestriction(restriction);
  }, [restriction]);

  const onCriteriaChange = (value: string) => {
    const selectedCriterion = criteria.find(
      (criterion) => criterion.name === value
    );
    if (selectedCriterion) {
      setRestrictionsByCriteria(selectedCriterion.restrictions || []);
      selectedRestriction.criteriaId = selectedCriterion.id;
      selectedRestriction.criteriaName = selectedCriterion.name;
      setAutoCompleteValue("");
      onRestrictionChange(selectedRestriction);
      onCriteriaChangeCallback(selectedCriterion.id, selectedCriterion.name);
    }
  };

  const onRestrictionDropDownValueChange = (restriction: string) => {
    if (restriction === null || restriction === undefined) {
      return;
    }

    const selectedRestrictionFromDropDown = restrictionByCriteria.find(
      (r) => r.name === restriction
    );

    selectedRestriction.name = selectedRestrictionFromDropDown?.name || "";
    selectedRestriction.restrictionId =
      selectedRestrictionFromDropDown?.restrictionId || "";
    setSelectedRestriction(selectedRestriction);
    setAutoCompleteValue(restriction);
    onSelectedRestrictionChange(selectedRestriction.restrictionId);
    return;
  };

  const onActionDescriptionChange = (value: string) => {
    selectedRestriction.comment = value;
    setAction(value);
    setSelectedRestriction(selectedRestriction);
    onRestrictionChange(selectedRestriction);
  };

  const onPriorityChange = (value: string) => {
    selectedRestriction.priority = restrictionPriorities.indexOf(value);
    onRestrictionChange(selectedRestriction);
  };

  const onChangeAutoComplete = (value: string, event: any) => {

    setAutoCompleteValue(value);
    onSelectedRestrictionChange(null);
    onNewRestrictionNameChange(value);
  };

  return (
    <Container isOnVerticalServicePopUp={isOnVerticalServicePopUp}>
      <RowOne isOnVerticalServicePopUp={isOnVerticalServicePopUp}>
        <DropDownComponent
          variant="filled"
          bakcgrounColor={Colors.concrete}
          key="Critério"
          componentKey="criteria"
          label="Critério"
          defaultValue={selectedRestriction.criteriaName || "Critério"}
          selectedValue={
            criteria.find(
              (item) => item.name === selectedRestriction.criteriaName
            )?.name || ""
          }
          values={criteria.map((criterion) => criterion.name)}
          onSelectedValueChanged={(key, value) => onCriteriaChange(value)}
          disabled={disabledAllCriteriaAndRestriction}
        />
        <Autocomplete
          options={restrictionByCriteria.map((restriction) => restriction.name)}
          value={
            restrictionByCriteria.find(
              (restriction) => restriction.name === selectedRestriction.name
            )?.name || ""
          }
          disabled={selectedRestriction.criteriaId === undefined}
          renderInput={(params) => (
            <TextField
              variant="filled"
              {...params}
              label="Nova restrição"
              InputProps={{
                ...params.InputProps,
              }}
              sx={textFieldDarkStyle}
              disabled={disabledAllCriteriaAndRestriction}
            />
          )}
          inputValue={autoCompleteValue}
          fullWidth
          size="small"
          onChange={(e: any, data: any) =>
            onRestrictionDropDownValueChange(data)
          }
          onInputChange={(event: any, value: any) => {
            if (event?.type === "clear") {
              onChangeAutoComplete("", event);
            } else {
              onChangeAutoComplete(value, event);
            }
          }}
          noOptionsText="Nenhuma opção encontrada"
          freeSolo
        />
        <DropDownComponent
          variant="filled"
          bakcgrounColor={Colors.concrete}
          key="Prioridade"
          componentKey="priority"
          label="Prioridade"
          defaultValue="Prioridade"
          selectedValue={
            selectedRestriction.priority !== undefined
              ? restrictionPriorities[selectedRestriction.priority || 0]
              : undefined
          }
          values={restrictionPriorities}
          onSelectedValueChanged={(key, value) => onPriorityChange(value)}
          disabled={disabled}
        />
      </RowOne>
    </Container>
  );
};

const Container = styled.div<{ isOnVerticalServicePopUp: boolean }>`
  display: grid;
  grid-template-rows: max-content max-content;
  ${(props) =>
    !props.isOnVerticalServicePopUp
      ? `
    border-radius: 16px;
    padding: 32px 24px;
    background-color: ${Colors.neutralColorWhite};
    border: 1px solid rgba(178, 178, 178, 0.25);
    `
      : {}}
`;

const RowOne = styled.div<{ isOnVerticalServicePopUp: boolean }>`
  display: grid;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-column-gap: 24px;
    grid-row-gap: 16px;
    grid-template-columns: ${(props) =>
      props.isOnVerticalServicePopUp ? "1fr" : "25% 1fr 25%"};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-row-gap: 24px;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

export default NewRestrictionComponent;
