import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";

const SuppliersFormContainer = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 3fr;
  gap: 18px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
  }
`;

const SupplierTableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.borderColorGray};

`;

const SupplierTag = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${Colors.greenLight};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 40px;
    width: 40px;
  }
`;

const NoContentContainer = styled.div`
  display: flex;
  height: 20vh;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-top: 30px;
  border: 1px solid ${Colors.borderColorGray};
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 24px;

  
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column-reverse;
    gap: 10px;
    width: 100%;
  }

`

export { SuppliersFormContainer, SupplierTableRow, NoContentContainer, SupplierTag, ButtonContainer };
