import styled from "styled-components";
import { Colors } from "../../../theme/v2/variants";
import { IconButton, LinearProgress, withStyles } from "@mui/material";
import React, { useEffect, useImperativeHandle } from "react";
import { UpdateServiceProgressModel } from "../models/UpdateServiceProgressModel";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import {
  ProgressUpdate,
  ProgressUpdateType,
} from "../../../services/interfaces/VerticalServiceProgressModel";
import { use } from "i18next";
import { ReactComponent as HideIcon } from "../../../assets/show-advancement-historic.svg";
import { ReactComponent as EditIcon } from "../../../assets/edit-blue-update-action-progress.svg";
import { ReactComponent as EditIconDisabled } from "../../../assets/edit-blue-update-action-progress-disabled.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import { ComponentState } from "./UpdateServiceProgressPopUp";

export enum AdvancementIndexType {
  PHYSICAL = "Físico",
  FINANCIAL = "Financeiro",
}

interface AdvancementIndexComponentProps {
  title: string;
  value: number;
  type: AdvancementIndexType;
  concludedActions?: number;
  totalActions?: number;
  budgetUsed?: number;
  totalProgress?: number;
  totalValue?: number;
  allUpdates?: ProgressUpdate[];
  isPastUpdatesListEnabled?: boolean;
  showBudgetDescription: boolean;
  showMaterialUserDescription: boolean;
  materialCostRealized?: number;
  workForceCostRealized?: number;
  unity?: string;
  onSelectedUpdate?: (update: ProgressUpdate) => void;
  onDeletedUpdate?: (update: ProgressUpdate) => void;
  onChangeComponentState?: (state: ComponentState) => void;
  onPastProgressListChangeState?: (
    state: AdvancementIndexType,
    isShowing: boolean
  ) => void;
  itemsToDelete?: ProgressUpdate[];
  printState?: boolean;
}

export interface AdvancementIndexComponentRef {
  removeUpdateStateAndSelectedUpdate: () => void;
}

const AdvancementIndexVerticalComponent = React.forwardRef<
  AdvancementIndexComponentRef | undefined,
  AdvancementIndexComponentProps
>(
  (
    {
      title,
      value,
      type,
      concludedActions,
      totalActions,
      budgetUsed,
      totalProgress,
      totalValue,
      materialCostRealized,
      workForceCostRealized,
      allUpdates,
      unity,
      isPastUpdatesListEnabled,
      showBudgetDescription,
      showMaterialUserDescription,
      onChangeComponentState,
      onSelectedUpdate,
      onDeletedUpdate,
      onPastProgressListChangeState,
      itemsToDelete,
      printState = false,
    },
    componentRef
  ) => {

    // Rest of the code...
    const ref = React.useRef<any>(null);
    const [advancementSteps, setAdvancementSteps] = React.useState<number[]>(
      []
    );
    const [isShowState, setIsShowState] = React.useState(
      isPastUpdatesListEnabled || false
    );
    const [state, setState] = React.useState(ComponentState.UPDATE_PROGRESS);
    const [selectedUpdate, setSelectedUpdate] =
      React.useState<ProgressUpdate>();

    const [deleteIcon, setDeleteIcon] = React.useState(false);

    const isItemToDelete = (item: ProgressUpdate) => {
      if (itemsToDelete?.some((toDelete) => toDelete.id === item.id)) {
        setDeleteIcon(true);
        return true;
      } else {
        setDeleteIcon(false);
        return false;
      }
    };

    const [itemsMarkedForDeletion, setItemsMarkedForDeletion] = React.useState<
      Set<number>
    >(new Set());

    const toggleDeleteIcon = (itemId: number) => {
      setItemsMarkedForDeletion((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(itemId)) {
          newSet.delete(itemId);
        } else {
          newSet.add(itemId);
        }
        return newSet;
      });
    };

    useImperativeHandle(componentRef, () => ({
      removeUpdateStateAndSelectedUpdate: () => {
        setSelectedUpdate(undefined);
        setState(ComponentState.UPDATE_PROGRESS);
      },
    }));

    useEffect(() => {
      setAdvancementSteps(
        allUpdates?.map((update) => update.accumulatedPercentageValue) || []
      );
    }, [allUpdates]);

    useEffect(() => {
      setIsShowState(isPastUpdatesListEnabled || false);
    }, [isPastUpdatesListEnabled]);

    const calcVisualSteps = (steps: number[]): number[] => {
      const width = ref?.current?.offsetWidth;

      if (width) {
        return steps.map((step) => {
          return (step * width) / 100;
        });
      } else {
        return [];
      }
    };

    const editPastProgress = (progress: ProgressUpdate) => {
      setState(ComponentState.EDIT_PAST_PROGRESS);
      setSelectedUpdate(progress);
      onChangeComponentState?.(ComponentState.EDIT_PAST_PROGRESS);
      onSelectedUpdate?.(progress);
    };

    const deletePastProgress = (progress: ProgressUpdate) => {
      console.log("Deleting progress", progress);
      toggleDeleteIcon(progress.id);
      onDeletedUpdate?.(progress);
    };

    const onClickShowPastProgress = () => {
      onPastProgressListChangeState?.(type, !isShowState);
      setIsShowState(!isShowState);
    };

    return (
      <Container>
        <Header>
          <IndiceTitle printState={printState}>{title}</IndiceTitle>
          <IndiceValue printState={printState}>{value}%</IndiceValue>
        </Header>
        <IndiceContainer>
          <LinearProgress
            ref={ref}
            sx={{
              backgroundColor: "rgba(217, 217, 217, 0.5)",
              "& .MuiLinearProgress-bar": {
                backgroundColor: Colors.sweetPink,
              },
              height: printState ? "12px" : "4px",

              borderRadius: "8px",
              marginTop: "8px",
            }}
            variant="determinate"
            value={value > 100 ? 100 : value}
          />

          {state === ComponentState.UPDATE_PROGRESS && (
            <StepsContainer
              style={{
                width: ref?.current?.offsetWidth
                  ? ref?.current?.offsetWidth - 2 + "px"
                  : "0px",
              }}
            >
              {calcVisualSteps(advancementSteps).map((step, index) => {
                return (
                  <Step style={{ position: "absolute", left: step + "px" }} />
                );
              })}
            </StepsContainer>
          )}
          {allUpdates && allUpdates.length > 0 && (
            <IconButton
              onClick={() => onClickShowPastProgress()}
              style={{ transform: !isShowState ? "rotate(180deg)" : "" }}
            >
              <HideIcon />
            </IconButton>
          )}
        </IndiceContainer>
        {/* {showDescription === true && <div style={{ height: "14px" }} />} */}
        {showBudgetDescription &&
        !!(
          (materialCostRealized && materialCostRealized > 0) ||
          (workForceCostRealized && workForceCostRealized > 0)
        ) ? (
          <>
            <Description>{`R$${materialCostRealized} do orçamento previsto consumido em Materiais`}</Description>
            <Description>{`R$${workForceCostRealized} do orçamento previsto consumido em Mão de Obra`}</Description>
          </>
        ) : null}
        {showMaterialUserDescription &&
          !!(totalProgress && totalProgress > 0) && (
            <Description>{`${totalProgress}${unity} do total de ${totalValue}${unity} executados`}</Description>
          )}
        {isShowState && (
          <ListUpdatesContainer>
            {allUpdates?.map((update) => {
              const isSelected =
                state === ComponentState.UPDATE_PROGRESS
                  ? true
                  : selectedUpdate === update
                  ? true
                  : false;
              const isMarkedForDeletion = itemsMarkedForDeletion.has(update.id);
              return (
                <UpdateRowContainer key={update.id}>
                  <UpdateRowText isSelected={isSelected}>
                    {new Date(update.date).toLocaleDateString("pt-BR", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })}
                  </UpdateRowText>
                  <UpdateRowText
                    isSelected={isSelected}
                    style={{ marginRight: "4px" }}
                  >
                    {`${
                      type === AdvancementIndexType.PHYSICAL
                        ? `${update.value + `${unity}`}`
                        : `R$${update.value}`
                    }`}
                  </UpdateRowText>
                  <IconButtonWrapper onClick={() => editPastProgress(update)}>
                    {isSelected ? <EditIcon /> : <EditIconDisabled />}
                  </IconButtonWrapper>
                  <IconButtonWrapper onClick={() => deletePastProgress(update)}>
                    {isMarkedForDeletion ? (
                      <AutoDeleteIcon />
                    ) : (
                      <DeleteIcon style={{ color: "#4865cc" }} />
                    )}
                  </IconButtonWrapper>
                </UpdateRowContainer>
              );
            })}
          </ListUpdatesContainer>
        )}
      </Container>
    );
  }
);

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
`;

const IconButtonWrapper = styled(IconButton)`
  padding-top: 8;
  padding-bottom: 8;
  padding-left: 0;
  padding-right: 0;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr max-content max-content;
  align-content: start;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content max-content;
  }
  height: fit-content;
`;

const IndiceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content max-content;
  justify-content: center;
`;

const IndiceTitle = styled.div<{ printState: boolean }>`
  font-size: ${(props) => (props.printState ? "16px" : "14px")};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${Colors.neutralGrayDark};
`;

const IndiceValue = styled.div<{ printState: boolean }>`
  font-size: ${(props) => (props.printState ? "16px" : "14px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${Colors.neutralGrayDark};
  justify-self: end;
`;

const Description = styled.div`
  font-family: "Noto Sans", Inter, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${Colors.textColorBlack};
`;

const StepsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 16px;
  border-radius: 8px;
  margin-top: 2px;
  margin-left: -2px;
`;

const Step = styled.div`
  height: 15px;
  width: 1px;
  background-color: ${Colors.secoundaryColorDark};
`;

const ListUpdatesContainer = styled.div`
  max-height: 228px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
`;

const UpdateRowContainer = styled.div`
  width: 100%;
  height: 55px;
  background-color: ${Colors.neutralCOlorBlue};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr max-content;
  align-items: center;
  padding-right: 10px;
`;

const UpdateRowText = styled.div<{ isSelected: boolean }>`
  font-family: "Noto Sans", Inter, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  font-style: normal;
  color: ${(props) => (props.isSelected ? "#000" : Colors.neutralColorGray)};
  margin-left: 15px;
`;

export default AdvancementIndexVerticalComponent;
