import { InputAdornment, InputProps } from "@mui/material";
import TextField from "@mui/material/TextField";
import { size } from "polished";
import InputMask from "react-input-mask";
import { textFieldStyle } from "../../utils/custom-styles/textFieldCustomStyles";
import { textFieldDarkStyle } from "../../utils/custom-styles/textFieldCustomDarkStyles";

interface InputValueComponentProps {
  label: string;
  value: string | number;
  isDisabled?: boolean | false;
  onChange?: (value: string) => void;
  size?: any;
  placeholder?: string;
  ref?: any;
  mask?: string;
  onClick?: () => void;
  children?: any;
  isError?: boolean;
  errorMessage?: string;
  endIcon?: any;
  v2?: boolean;
  type?: string;
  prefixView?: React.ReactNode;
  sufixView?: React.ReactNode;
  variant?: string;
  sx?: any;
  multiline?: boolean;
  rows?: number;
  isGray?: boolean;
}

export default function InputValueComponent(props: InputValueComponentProps) {
  // const buttonStyle: React.CSSProperties = {
  //   height: '48px',
  //   borderColor: '#485',
  // };

  function onChange(value: string) {
    // if (props.mask) {
    //   props.onChange ? props.onChange(props.mask(value)) : null
    // } else {
    //   props.onChange ? props.onChange(value) : null
    // }

    if (props.onChange != null && props.mask != null) {
      props.onChange(value);
      return;
    }

    if (props.onChange != null) {
      props.onChange(value);
      return;
    }
  }

  return (
    <InputMask
      value={props.value}
      disabled={props.isDisabled}
      mask={props.mask || ""}
      onChange={(event) => onChange(event.target.value)}
    >
      <TextField
        id="outlined-required"
        label={props.label}
        defaultValue={props.value}
        type={props.type || "text"}
        placeholder={props.placeholder}
        onClick={props.onClick}
        error={props.isError}
        variant={props.variant ? "filled" : "outlined"}
        sx={
          props.sx
            ? props.sx
            : props.variant
            ? props.isGray
              ? textFieldDarkStyle
              : textFieldStyle
            : {}
        }
        multiline={props.multiline}
        rows={props.rows}
        size={props.variant ? "medium" : "small"}
        helperText={props.isError ? props.errorMessage : null}
        InputProps={
          props.endIcon
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {props.endIcon}
                  </InputAdornment>
                ),
              }
            : props.prefixView
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    {props.prefixView}
                  </InputAdornment>
                ),
              }
            : props.sufixView
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {props.sufixView}
                  </InputAdornment>
                ),
              }
            : {}
        }
      >
        {props.children}
      </TextField>
    </InputMask>
  );
}
