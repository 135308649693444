// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
// import {
//   Table as TablePdf,
//   TableHeader as TableHeaderPdf,
//   TableCell as TableCellPdf,
//   DataTableCell as DataTableCellPdf,
//   TableBody as TableBodyPdf,
// } from "@david.kucsai/react-pdf-table";
import {
  ActionItem,
  restrictionCategories,
  restrictionPriorities,
} from "../../../config/app-info";
import { stylesPdf } from "./style";
import VerticalService from "../../../services/VeritcalService";

type Props = {
  criteriaName: string;
  actions: ActionItem[];
  labelDtEnd: string;
};
const ActionsPlanPdf: React.FC<Props> = ({
  criteriaName,
  actions,
  labelDtEnd,
}) => {
  const verticalService = new VerticalService();

  const sortedActions = [...actions].sort((a, b) => {
    const dateA = new Date(a.whenBegin || 0).getTime();
    const dateB = new Date(b.whenBegin || 0).getTime();
    const endDateA = new Date(a.whenEnd || 0).getTime();
    const endDateB = new Date(b.whenEnd || 0).getTime();

    return dateA - dateB || endDateA - endDateB;
  });

  const [verticalServiceDescriptions, setVerticalServiceDescriptions] =
    useState<{ [key: number]: string }>({});

  useEffect(() => {
    const fetchVerticalServiceDescriptions = async () => {
      const descriptions: { [key: number]: string } = {};
      for (const action of sortedActions) {
        if (action.verticalService) {
          try {
            const response = await verticalService.getVerticalServiceById(
              10,
              action.verticalService
            );
            descriptions[action.verticalService] = response.description ?? "";
          } catch (error) {
            console.error("Error fetching vertical service:", error);
          }
        }
      }
      setVerticalServiceDescriptions(descriptions);
    };

    fetchVerticalServiceDescriptions();
  }, []);

  return (
    <View>
      <Text style={styles.criteriaName} wrap>
        {criteriaName}
      </Text>

      <View style={styles.table}>
        <View style={[styles.row, styles.headerRow]} wrap={false}>
          <Text style={[styles.cell, styles.headerCell, styles.widerCell]}>
            Restrição
          </Text>
          <Text style={[styles.cell, styles.headerCell, { flex: 3 }]}>
            Ação
          </Text>
          <Text style={[styles.cell, styles.headerCell, styles.widerCell]}>
            Responsáveis
          </Text>
          <Text style={[styles.cell, styles.headerCell]}>Data Início</Text>
          <Text style={[styles.cell, styles.headerCell]}>Data Fim</Text>
          <Text style={[styles.cell, styles.headerCell]}>Serviço</Text>
          <Text style={[styles.cell, styles.headerCell]}>Status</Text>
          <Text style={[styles.cell, styles.headerCell]}>Prioridade</Text>
          <Text style={[styles.cell, styles.headerCell, styles.widerCell]}>
            Observação
          </Text>
        </View>

        {sortedActions.map((action, index) => (
          <View key={index} style={styles.row} wrap={false}>
            <Text style={[styles.cell, styles.widerCell]}>
              {action.restrictionName}
            </Text>
            <Text style={[styles.cell, { flex: 3 }]}>{action.what}</Text>
            <Text style={[styles.cell, styles.widerCell]}>
              {action.responsibleUsers}
            </Text>
            <Text style={styles.cell}>
              {action.whenBegin
                ? new Date(action.whenBegin).toLocaleDateString("pt-BR")
                : ""}
            </Text>
            <Text style={styles.cell}>
              {action.whenEnd
                ? new Date(action.whenEnd).toLocaleDateString("pt-BR")
                : ""}
            </Text>
            <Text style={styles.cell}>
              {verticalServiceDescriptions[action.verticalService] || ""}
            </Text>
            <Text style={styles.cell}>{action.statusAsString}</Text>
            <Text style={styles.cell}>
              {restrictionPriorities[action.priority]}
            </Text>
            <Text style={[styles.cell, styles.widerCell]}>{action.how}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default ActionsPlanPdf;

const styles = StyleSheet.create({
  criteriaName: {
    fontSize: 12,
    marginBottom: 5,
  },
  table: {
    display: "table",
    width: "100%",
    borderColor: "#222",
    marginBottom: 10,
    flexGrow: 1,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderBottomColor: "#222",
    borderBottomStyle: "solid",
    borderTopWidth: 0.5,
    borderTopColor: "#222",
    borderTopStyle: "solid",
    flexGrow: 1,
  },
  headerRow: {
    backgroundColor: "#e0e0e0",
    textAlign: "center",
  },
  cell: {
    padding: 5,
    fontSize: 9,
    borderRightWidth: 0.5,
    borderRightColor: "#222",
    borderRightStyle: "solid",
    borderLeftWidth: 0.5,
    borderLeftColor: "#222",
    borderLeftStyle: "solid",
    textAlign: "center",
    flex: 1,
  },
  widerCell: {
    flex: 2,
  },
  headerCell: {
    fontWeight: "bold",
    fontSize: 9,
  },
});
