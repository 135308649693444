import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { CardContainer } from "../PurchasingAndContractingFilesModal.styles";
import { Colors } from "../../../../../theme/v2/variants";
import styled from "styled-components";

interface FileProps {
  id: number;
  fileName: string;
  uploadedBy: string;
  uploadedAt: string;
  fileUrl: string;
  category: string;
  fileType: string;
  identifier: string;
  uploadedFilePCC?: any;
}

interface FileCardProps {
  idx: number;
  selectedIndex: number;
  file: FileProps;
  selectFileHandler: (index: number, fileUrl: string) => void;
  deleteFileHandler: (fileId: number) => void;
}

const FileCard: React.FC<FileCardProps> = ({
  idx,
  selectedIndex,
  file,
  selectFileHandler,
  deleteFileHandler,
}) => {
  const isSelected = selectedIndex === idx;
  const cardBackgroundColor = isSelected
    ? Colors.neutralColorWhite
    : Colors.textColorLight;

  return (
    <CardContainer
      isSelected={isSelected}
      style={{ backgroundColor: cardBackgroundColor }}
      onClick={() => selectFileHandler(idx, file.fileUrl)}
    >
      <Box>
        <InsertDriveFileOutlinedIcon
          fontSize="large"
          sx={{ color: Colors.textColorGray }}
        />
      </Box>
      <InfoContainer>
        <div>
          <Typography sx={smallTextStyles}>
            Pedido nº {file.identifier}
          </Typography>
          <Typography sx={clearTextStyles}>
            {`${file.category} - ${file.fileType}`}
          </Typography>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "11rem",
            }}
          >
            <Typography sx={boldTextStyles} noWrap>
              {file.fileName}
            </Typography>
          </div>
        </div>
        <div>
          <Typography sx={smallTextStyles}>
            Atualizado em {new Date(file.uploadedAt).toLocaleDateString()}
          </Typography>
          <Typography sx={smallTextStyles}>
            por <b>{file.uploadedBy}</b>
          </Typography>
        </div>
      </InfoContainer>
      <Box sx={boxIconContainerStyles}>
        <IconButton
          disableRipple={false}
          sx={{
            backgroundColor: isSelected ? "rgba(0, 0, 0, 0.1)" : "none",
          }}
        >
          <VisibilityOutlinedIcon fontSize="large" sx={iconStyles} />
        </IconButton>
        <IconButton
          disableRipple={false}
          onClick={() => deleteFileHandler(file.id)}
        >
          <DeleteOutlineOutlinedIcon fontSize="large" sx={iconStyles} />
        </IconButton>
      </Box>
    </CardContainer>
  );
};

const iconStyles = {
  cursor: "pointer",
  color: Colors.textColorGray,
};

const InfoContainer = styled.div`
  flex-grow: 1;
  text-align: start;
  gap: 20px;

  @media (max-width: 1000px) {
    display: flex;
  }
`;

const clearTextStyles = {
  fontSize: "12px",
  color: Colors.neutralColor,
};

const smallTextStyles = {
  fontSize: "12px",
};

const boldTextStyles = {
  fontSize: "18px",
  fontWeight: "bold",
};

const boxIconContainerStyles = {
  display: "flex",
  gap: "5px",
};

export default FileCard;
