import styled from "styled-components";
import { Colors } from "../../../theme/variants";
import ActionCard, {
  ActionCardProps,
  ActionDetails,
} from "../../../components/ActionCard/ActionCard";
import React, { useEffect, useState } from "react";
import {
  DropResult,
  OnDragEndResponder,
  ResponderProvided,
} from "@hello-pangea/dnd";
import ReactPageBaseComponent from "../../../components/BaseComponents/ReactPageBaseComponent";
import KanbanComponent from "../../Kanban/KanbanComponent";
import DropDownComponent from "../../../components/InputValues/DropDownComponent";
import {
  ViewActionsByENUM,
  ViewActionsByENUMLabel,
  viewTypeActionValues,
  viewTypeActionByValue,
} from "./utils/ViewActionsByENUM";

import ActionsService from "../../../services/action/ActionsService";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import { BaseState } from "./utils/BaseState";
import ErrorComponent from "../../../components/StateComponents/ErrorComponent";
import Snackbar from "@mui/material/Snackbar";

import { useAuth } from "../../../contexts/auth";
import { useDashboard } from "../../../contexts/dashboard";
import MoveActionToDoPopUp from "./MoveActionToDoPopUp";
import { KanbanColumnStatus } from "../../Kanban/KanbanColumn";
import EmptyClientAndConstructionState from "../../../components/StateComponents/EmptyClientAndConstruction";
import Title from "../../../components/v2/Typography/Tittle";
import ConfirmDeleteItemPopUp from "../../../components/Dialogs/ConfirmDeleteItemPopUp";
import DateFilter, { DateFilterType } from "./DateFilter";
import ButtonComponent, {
  ButtonType,
} from "../../../components/Button/ButtonComponent";
import { set } from "date-fns";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import { bgcolor, width } from "@mui/system";
import { Container as MUIContainer } from "@mui/material";
import { useNavbarVisibility } from "../../../contexts/navbar";

interface Props {}

interface LastSource {
  lastSource: KanbanColumnStatus | null;
  lastSourcePosition: number | null;
  destination: string | null;
}

const ViewActionsPage: React.FC<Props> = ({}) => {
  const actionService = new ActionsService();
  const [state, setState] = useState<BaseState>(BaseState.loading);
  const [actions, setActions] = useState<ActionCardProps[][]>([[], [], [], []]);
  const [openDeleteActionDialog, setOpenDeleteActionDialog] =
    useState<boolean>(false);
  const [openMoveActionToDoDialog, setOpenMoveActionToDoDialog] =
    useState<boolean>(false);
  const [openDateFilterDialog, setOpenDateFilterDialog] =
    useState<boolean>(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] =
    useState<ActionCardProps | null>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const { user } = useAuth();
  const [viewBy, setViewBy] = useState<ViewActionsByENUM>(
    ViewActionsByENUM.byMonth
  );
  const { constructionId, clientId, dtBegin, dtEnd } = useDashboard();
  const [lastSource, setLastSource] = useState<LastSource>();
  const [selectedDates, setSelectedDates] = useState<DateFilterType>();
  const [actionTitle, setActionTitle] = useState<string>("");
  const { screenWidth } = useNavbarVisibility();

  useEffect(() => {
    findActionsByConstructionSortByStatus(viewBy);
  }, [constructionId]);

  const findActionsByConstructionSortByStatus = async (
    viewBy: string,
    beginDate: Date | undefined = selectedDates?.startDate,
    endDate: Date | undefined = selectedDates?.deadlineDate
  ) => {
    if (isConstructionNotSelected()) {
      return;
    }

    setState(BaseState.loading);
    actionService
      .findActionsByConstructionSortByStatus(
        constructionId.toString(),
        viewBy,
        undefined,
        undefined,
        beginDate,
        endDate
      )
      .then((response) => {
        console.log(response);
        const pedingActions = response.pending;
        const inProgressActions = response.inProgress;
        const doneActions = response.concluded;
        const lateAction = response.late;

        const arrayActions: ActionCardProps[][] = [
          parseDataToActionCardProps(pedingActions),
          parseDataToActionCardProps(inProgressActions),
          parseDataToActionCardProps(doneActions),
          parseDataToActionCardProps(lateAction),
        ];
        setState(BaseState.success);
        setActions(arrayActions);
      })
      .catch((error) => {
        setState(BaseState.error);
      });
  };

  const handleViewByChange = async (viewBy: string) => {
    setSelectedDates(undefined);
    const viewByAsEnum = viewTypeActionByValue(viewBy);
    setViewBy(viewByAsEnum);
    const viewByEnum = viewTypeActionByValue(viewBy);
    findActionsByConstructionSortByStatus(viewByEnum);
  };

  const parseDataToActionCardProps = (data: any[]): ActionCardProps[] => {
    return data.map((action: any) => {
      const responsibleUsersNames = action.responsibleUsersId.map(
        (responsibleUser: any) => responsibleUser.responsibleUserId.name
      );

      const details: ActionDetails = {
        responsibleList: [],
        criteria: null,
        priority: null,
      };
      // const tagListArray: string[] = [];
      responsibleUsersNames.forEach((name: string) => {
        details.responsibleList.push(name);
      });

      const criteria = action.restrictionMappingId.restrictionId.name;
      if (criteria) {
        // tagListArray.push(criteria);
        details.criteria = criteria;
      }

      const priorityAsString = action.priorityAsString;
      if (priorityAsString) {
        // tagListArray.push(priorityAsString);
        details.priority = priorityAsString;
      }

      const actionCardProps: ActionCardProps = {
        id: action.id,
        title: action.what,
        // tagList: tagListArray,
        details: details,
        startDate: new Date(action.whenBegin),
        deadlineDate: new Date(action.whenEnd),
        commentsCount: 0,
      };
      return actionCardProps;
    });
  };

  const onDragEnd: OnDragEndResponder = async (
    result: DropResult,
    provided: ResponderProvided
  ) => {
    const { source, destination } = result;
    const newStatus = destination?.droppableId;
    const actionId = result.draggableId;

    if (!newStatus) {
      return;
    }

    if (
      (destination.droppableId === KanbanColumnStatus.PENDING ||
        destination.droppableId === KanbanColumnStatus.IN_PROGRESS) &&
      (source.droppableId === KanbanColumnStatus.DONE ||
        source.droppableId === KanbanColumnStatus.DELAYED ||
        source.droppableId === KanbanColumnStatus.IN_PROGRESS)
    ) {
      let selectedActions: ActionCardProps | undefined = undefined;

      if (destination.droppableId === KanbanColumnStatus.PENDING) {
        setActionTitle(`Mover para "A fazer"`);
        selectedActions = actions[0].find((action) => action.id === actionId);
      }

      if (destination.droppableId === KanbanColumnStatus.IN_PROGRESS) {
        setActionTitle(`Mover para "Em andamento"`);
        selectedActions = actions[1].find((action) => action.id === actionId);
      }

      setOpenMoveActionToDoDialog(true);
      setSelectedAction(selectedActions);
      setLastSource({
        lastSource: source.droppableId,
        lastSourcePosition: source.index,
        destination: newStatus,
      });
      return;
    }
    updateActionStatus(actionId, newStatus);
  };

  const dialogButtonCancelOnClick = () => {
    setOpenDeleteActionDialog(false);
    setSelectedAction(null);
  };

  const dialogButtonConfirmOnClick = () => {
    if (!selectedAction) {
      return;
    }
    setIsLoadingDialog(true);

    actionService
      .deleteAction(selectedAction.id)
      .then((response) => {
        setOpenDeleteActionDialog(false);
        setIsLoadingDialog(false);
        setSnackbarMessage("Ação excluída com sucesso");
        setOpenSnackbar(true);
        removeActionFromState(selectedAction);
        // remove loading state in dialog button
        setSelectedAction(null);
      })
      .catch((error) => {
        setOpenDeleteActionDialog(false);
        setIsLoadingDialog(false);
        setSnackbarMessage("Erro ao excluir ação");
        setOpenSnackbar(true);
        setSelectedAction(null);
      });
  };

  const handleTrashClick = (action: ActionCardProps) => {
    setOpenDeleteActionDialog(true);
    setSelectedAction(action);
  };

  const removeActionFromState = (action: ActionCardProps) => {
    const newActions = actions.map((column) => {
      const newColumn = column.filter((actionCardProps) => {
        return actionCardProps.id !== action.id;
      });
      return newColumn;
    });
    setActions(newActions);
  };

  const handleEditPermission = (): boolean => {
    const editPermission = [0, 1, 2, 4, 6];
    return !editPermission.includes(user.role);
  };

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const onCancelDialogCallback = (action: ActionCardProps) => {
    if (lastSource?.lastSourcePosition === null) {
      return;
    }

    let newActions = [...actions];
    newActions = newActions.map((column) => {
      const newColumn = column.filter((actionCardProps) => {
        return actionCardProps.id !== action.id;
      });
      return newColumn;
    });

    if (lastSource?.lastSource === KanbanColumnStatus.IN_PROGRESS) {
      newActions[1].splice(lastSource?.lastSourcePosition, 0, action);
      setActions(newActions);
    }

    if (lastSource?.lastSource === KanbanColumnStatus.DONE) {
      newActions[2].splice(lastSource?.lastSourcePosition, 0, action);
      setActions(newActions);
    }

    if (lastSource?.lastSource === KanbanColumnStatus.DELAYED) {
      newActions[3].splice(lastSource?.lastSourcePosition, 0, action);
      setActions(newActions);
    }

    setOpenMoveActionToDoDialog(false);
  };

  const onConfirmDialogCallback = (action: ActionCardProps) => {
    if (
      lastSource?.lastSource === null ||
      lastSource?.lastSource === undefined ||
      lastSource?.destination === null
    ) {
      return;
    }

    updateActionStatus(action.id, lastSource?.destination);
    actionService
      .updateAction(action.id, {
        beginDate: action.startDate,
        endDate: action.deadlineDate,
      })
      .then(() => {})
      .catch(() => {});
    setOpenMoveActionToDoDialog(false);
  };

  function updateActionStatus(actionId: string, newStatus: string) {
    actionService
      .updateActionStatus(actionId, newStatus)
      .then((response) => {})
      .catch((error) => {
        setOpenSnackbar(true);
        setSnackbarMessage("Erro ao atualizar status da ação");
      });
  }

  const onSelectDateFilter = (dates: DateFilterType) => {
    setSelectedDates(dates);
    setOpenDateFilterDialog(false);
    findActionsByConstructionSortByStatus(
      viewBy,
      dates.startDate,
      dates.deadlineDate
    );
  };

  const onCanceledDateFilter = () => {
    setOpenDateFilterDialog(false);
  };

  if (isConstructionNotSelected()) {
    return (
      <MUIContainer
        sx={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        <EmptyClientAndConstructionState pageDescription="o quadro de Acompanhamento de ações" />
      </MUIContainer>
    );
  }

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <LoadingComponent />
        ) : null}
        {state === BaseState.success ? (
          <ContentContainer>
            <AboutContainer>
              <Title>Acompanhamento de Ações</Title>
              <ViewByContainer>
                <DropDownComponent
                  defaultValue={ViewActionsByENUMLabel[viewBy]}
                  key="Visualizar por"
                  componentKey="viewBy"
                  onSelectedValueChanged={(key, value) => {
                    handleViewByChange(value);
                  }}
                  values={viewTypeActionValues()}
                  sx={{
                    width: screenWidth > MOBILE_BREAKPOINT ? "225px" : "calc(100vw - 40px)",
                  }}
                  bakcgrounColor="white"
                  variant="filled"
                />
                  <ButtonComponent
                    text={
                      !selectedDates
                        ? "Filtrar por data"
                        : selectedDates?.startDate?.toLocaleDateString(
                            "pt-BR",
                            {
                              day: "2-digit",
                              month: "2-digit",
                            }
                          ) +
                          " à " +
                          selectedDates?.deadlineDate?.toLocaleDateString(
                            "pt-BR",
                            {
                              day: "2-digit",
                              month: "2-digit",
                            }
                          )
                    }
                    type={ButtonType.OUTLINE}
                    fullWidth
                    onClick={() => setOpenDateFilterDialog(true)}
                  />
              </ViewByContainer>
            </AboutContainer>
            <SliderContainer>
              <KanbanContainer>
                <KanbanComponent
                  actionsByProps={actions}
                  onDragEndCallback={onDragEnd}
                  actionCardTrashOnClick={handleTrashClick}
                  isDragDisabled={handleEditPermission()}
                />
              </KanbanContainer>
            </SliderContainer>
          </ContentContainer>
        ) : null}

        {state === BaseState.error ? <ErrorComponent /> : null}
      </PageContainer>
      <ConfirmDeleteItemPopUp
        title="Excluir ação"
        description="Deseja realmente excluir a ação?"
        openDeleteActionDialog={openDeleteActionDialog}
        isLoadingDialog={isLoadingDialog}
        dialogButtonCancelOnClick={dialogButtonCancelOnClick}
        dialogButtonConfirmOnClick={dialogButtonConfirmOnClick}
      />
      <MoveActionToDoPopUp
        openDialog={openMoveActionToDoDialog}
        action={selectedAction}
        onCancelDialogCallback={onCancelDialogCallback}
        onConfirmDialogCallback={onConfirmDialogCallback}
        title={actionTitle}
      />
      <DateFilter
        openDialog={openDateFilterDialog}
        dates={selectedDates}
        onConfirmDialogCallback={onSelectDateFilter}
        onCancelDialogCallback={() => onCanceledDateFilter()}
      />
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />
    </ReactPageBaseComponent>
  );
};


export const PageContainer = styled.div`
  display: grid;
  height: 100%;
  max-width: 100%;
  min-height: 100%;
  grid-template-columns: 1fr;
  background-color: ${Colors.neutralColorSurface};
  overflow-x: hidden;
  align-content: space-between;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  max-width: 100%;
  height: 100%;
  flex-grow: 1;
`;

const AboutContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const ViewByContainer = styled.div`
  display: grid;
  min-width: 156px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  place-items: center;
  gap: 24px;

  @media (max-width: 900px) {
    grid-template-columns: 200px 1fr;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    grid-template-columns: 1fr;
    margin-top: 20px;
    gap: 16px;
  }
`;

const KanbanContainer = styled.div`
  height: 100%;
  width: 100vw;

  @media (max-width: 1300px) {
    width: 110vw;
  }

  @media (max-width: 1100px) {
    width: 140vw;
  }

  @media (max-width: 900px) {
    width: 160vw;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: max-content;
  }
`;

const SliderContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  padding: 20px 0px;
  margin-top: 24px;
  min-height: 80vh;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 10px;
  }
`;


export default ViewActionsPage;
