import React from "react";
import styled, { css } from "styled-components/macro";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { SidebarItemsType } from "../../types/sidebar";
import SidebarNavSection from "./SidebarNavSection";
import SidebarNavListItem from "./SidebarNavListItem";
import { Users, UserPlus, LogOut, BarChart2 } from "react-feather";

import "../../vendor/perfect-scrollbar.css";

const baseScrollbar = css`
display: flex;
flex-direction: column;
  /* height: 100vh; */
  /* height: 100%; */
  background-color: ${(props) => props.theme.sidebar.background};
  padding-bottom: 80px;
  /* border-right: 1px solid rgba(0, 0, 0, 0.12); */
`;

const LogOutButton = styled.div`
  position: fixed;
  width: 135px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  bottom: 0;
  left: 0;
`;

const Scrollbar = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
  ${baseScrollbar}
`;
// @ts-ignore
const PerfectScrollbar = styled(ReactPerfectScrollbar)`
display: flex;
flex-direction: column;
justify-content: space-between;
  ${baseScrollbar}
`;

const Items = styled.div`
  /* padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)}; */
`;

type SidebarNavProps = {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  onDrawerToggle: () => void;
};

const SidebarNav: React.FC<SidebarNavProps> = ({ items, onDrawerToggle }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ScrollbarComponent = (
    matches ? PerfectScrollbar : Scrollbar
  ) as React.ElementType;

  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items>
          {items &&
            items.map((item) => (
              <SidebarNavSection
                component="div"
                key={item.title}
                pages={item.pages}
                title={item.title}
                onDrawerToggle={onDrawerToggle}
              />
            ))}
        </Items>
      </List>
      <LogOutButton>
        <SidebarNavListItem
          href="/sair/"
          icon={LogOut}
          title="Sair"
          depth={0}
        />
      </LogOutButton>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
