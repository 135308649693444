import React, { useState } from "react";
import styled from "styled-components";
import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import typography from "../../../../theme/newTheme/typography";
import ButtonComponent, {
  ButtonType,
} from "../../../../components/v2/Button/ButtonComponent";
import CheckIcon from "@mui/icons-material/Check";
import { LinearProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Edit } from "@mui/icons-material";
import ManageBudgetsService from "../../../../services/budgets/ManageBudgetsService";
import InputValueComponent from "../../../../components/v2/InputValues/InputValueComponent";

interface EditBudgetTotalValueDialogProps {
  budgetId?: number;
  onSave: () => void;
}

const EditBudgetTotalValueDialog: React.FC<EditBudgetTotalValueDialogProps> = ({
  budgetId,
  onSave,
}) => {
  const budgetService = new ManageBudgetsService();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace(".", ",");

    const formattedValue = inputValue.replace(/[^0-9,]/g, "");

    setValue(formattedValue);
  };

  const handleBlur = () => {
    const numericValue = parseFloat(value.replace(",", "."));
    console.log("Valor numérico:", numericValue);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const numericValue = value.replace(",", ".");

    if (budgetId) {
      await budgetService.updateValue(budgetId, numericValue);
    }

    handleClose();
    onSave();
  };

  return (
    <>
      <EditTotalValue>
        <IconButton aria-label="edit" color="primary" onClick={handleClickOpen}>
          <Edit />
        </IconButton>
      </EditTotalValue>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar valor total</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Utilize o campo abaixo para editar valor total do orçamento enviado
            pelo fornecedor
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              required
              margin="dense"
              id="value"
              name="value"
              label="Valor total"
              type="text"
              fullWidth
              variant="standard"
              value={value}
              onChange={handleInputChange}
              onBlur={handleBlur} // Converte no blur, mas processa no submit
            />
            <DialogActions>
              <Button onClick={handleClose}>Sair</Button>
              <Button type="submit">Salvar</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

const EditTotalValue = styled.span`
  vertical-align: text-bottom;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin-left: 10px;
`;

export default EditBudgetTotalValueDialog;
