import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";

interface CardContainerProps {
  isSelected?: boolean;
}

const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-radius: 10px;
  transition: all 300ms ease;
  border: 1px solid ${Colors.neutralColorLight};
  cursor: pointer;

  box-shadow: ${({ isSelected }) =>
    isSelected ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : "none"};

  &:hover {
    box-shadow: none;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 10px;
  }
`;

const ModalActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 25px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;

  
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export { CardContainer, ModalActionsContainer, ContentContainer };
