import styled from "styled-components";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import { Colors } from "../../../theme/v2/variants";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import DateCard from "../../../components/Cards/DateCard/DateCard";

import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import LabeledItemCard from "../../../components/Cards/LabeledItemCard/LabeledItemCard";
import ProfileAvatar from "../../../components/Profile/ProfileAvatar";

interface MouseHoverComponentProps {
  serviceType?: string;
  timePeriod?: { startDate: Date; endDate: Date };
  responsibleName?: string;
  status?: any;
  serviceGroupColor: string;
  totalDays?: number;
  isLastColumns?: boolean;
  showIcon?: boolean;
  lastRescheduling?: string;
}

const MouseHoverDetailsComponent: React.FC<MouseHoverComponentProps> = ({
  serviceType,
  timePeriod,
  responsibleName,
  status,
  serviceGroupColor,
  totalDays,
  isLastColumns,
  showIcon,
  lastRescheduling,
}) => {
  const [startDate, setStartDate] = useState<Date>(
    timePeriod?.startDate ?? new Date()
  );
  const [endDate, setEndDate] = useState<Date>(
    timePeriod?.endDate ?? new Date()
  );
  const [QuantityDays, setQuantityDays] = useState<number>(totalDays ?? 0);

  const colorByStatus = (status?: any) => {
    switch (status?.value || "") {
      case 1:
        return Colors.attentionColor;
      case 2:
        return Colors.feedbackSuccess;
      case 3:
        return Colors.feedbackError;
      case 4:
        return Colors.warningColor;
    }
  };

  return (
    <ReactBaseComponent>
      <Container>
        {lastRescheduling ? (
          <>
            <InfoContainer>
              <ServiceType>{serviceType}</ServiceType>
              <Divider style={{ backgroundColor: Colors.doveGray }}></Divider>
              <ReschedulingReasonContainer>
                <LabeledItemCard
                  title="Causa da reprogramação"
                  text={lastRescheduling}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                  fontWeight={400}
                />
              </ReschedulingReasonContainer>
              <TimePeriodContainer>
                <DateCard
                  title="Início"
                  date={startDate}
                  shortFormat={true}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                />
                <ArrowForwardOutlinedIcon
                  style={{
                    color: Colors.neutralSilverChalice,
                    margin: "0 12px",
                  }}
                />
                <DateCard
                  title="Fim"
                  date={endDate}
                  shortFormat={true}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                />
                <HorizontalDivider></HorizontalDivider>
                <LabeledItemCard
                  title="Total"
                  text={`${QuantityDays} dias`}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                />
              </TimePeriodContainer>
              <StatusContainer>
                <LabeledItemCard
                  title="Responsável"
                  text={responsibleName ? responsibleName : "Não informado"}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                  startIcon={
                    <ProfileAvatar
                      userName={
                        responsibleName ? responsibleName : "Não informado"
                      }
                      bgColor={Colors.dullLavender}
                    />
                  }
                />
                <LabeledItemCard
                  title="Status"
                  text={status.name}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                  startIcon={
                    <StatusSymbol
                      style={{ backgroundColor: colorByStatus(status) }}
                    />
                  }
                />
              </StatusContainer>
            </InfoContainer>
            <SymbolContainer isLastColumns={isLastColumns} />
          </>
        ) : (
          <>
            <InfoContainer>
              <ServiceType>{serviceType}</ServiceType>
              <Divider style={{ backgroundColor: Colors.doveGray }}></Divider>
              <TimePeriodContainer>
                <DateCard
                  title="Início"
                  date={startDate}
                  shortFormat={true}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                />
                <ArrowForwardOutlinedIcon
                  style={{
                    color: Colors.neutralSilverChalice,
                    margin: "0 12px",
                  }}
                />
                <DateCard
                  title="Fim"
                  date={endDate}
                  shortFormat={true}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                />
                <HorizontalDivider></HorizontalDivider>
                <LabeledItemCard
                  title="Total"
                  text={`${QuantityDays} dias`}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                />
              </TimePeriodContainer>
              <StatusContainer>
                <LabeledItemCard
                  title="Responsável"
                  text={responsibleName ? responsibleName : "Não informado"}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                  startIcon={
                    <ProfileAvatar
                      userName={
                        responsibleName ? responsibleName : "Não informado"
                      }
                      bgColor={Colors.dullLavender}
                    />
                  }
                />
                <LabeledItemCard
                  title="Status"
                  text={status.name}
                  color={Colors.dullLavender}
                  fontColor={Colors.neutralColorWhite}
                  fontSize="14px"
                  startIcon={
                    <StatusSymbol
                      style={{ backgroundColor: colorByStatus(status) }}
                    />
                  }
                />
              </StatusContainer>
            </InfoContainer>
            <SymbolContainer isLastColumns={isLastColumns} />
          </>
        )}
      </Container>
    </ReactBaseComponent>
  );
};

const HorizontalDivider = styled.div`
  width: 2px;
  height: 26px;
  background-color: ${Colors.doveGray};
  margin: 0 12px;
`;

const Container = styled.div`
  width: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
`;

const InfoContainer = styled.div`
  width: 100%;
  background-color: ${Colors.tundora};
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const ServiceType = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: start;
  color: ${Colors.neutralColorWhite};
  padding: 16px 24px;
`;

const ReschedulingReasonContainer = styled.div`
  padding: 0px 24px;
  padding-top: 16px;
  padding-bottom: 6px;
`;

const TimePeriod = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

const Responsible = styled.div`
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  padding-bottom: 17px;
`;

const StatusText = styled.div`
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-top: -3px;
  color: #000;
`;

const StatusSymbol = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
`;

const SymbolContainer = styled.div<{ isLastColumns?: boolean }>`
  width: 20px;
  height: 20px;
  rotate: 45deg;
  background-color: ${Colors.doveGray};
  margin-top: -10px;
  place-self: ${(props) => (props.isLastColumns ? "end" : "center")};
  margin-right: ${(props) => (props.isLastColumns ? "15px" : "0px")};
`;

const TimePeriodContainer = styled.div`
  display: flex;
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
`;

const TimePeriodContainerRescheduling = styled.div`
  display: flex;
  color: ${Colors.textColorBlack};
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  padding: 0px 24px;
  padding-top: 6px;
  padding-bottom: 16px;
  gap: 18px;
  justify-content: center;
  align-items: center;
`;

export default MouseHoverDetailsComponent;
