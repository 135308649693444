import { Box, Typography } from "@mui/material";
import type { HeaderColRigthProps } from "./type";
import { Colors } from "../../../../../theme/v2/variants";
import {
  formatDate,
  setEndOfDay,
  setStartOfDay,
} from "../../../../../utils/date";
import { TABS } from "../../../../../constants/tabs";

const getPeriodBySelectedTabForPrintedState = (
  selectedTab?: string,
  timePeriodListState?: any[],
  dates?: any
) => {
  let dateFormated = "";

  if (!timePeriodListState?.length) return null;

  const dateAsString = timePeriodListState[0].startDateAsDate;
  const endDateAsString =
    timePeriodListState[timePeriodListState.length - 1].endDateAsDate;

  const date = setStartOfDay(dateAsString);
  const endDate = setEndOfDay(endDateAsString);

  if (!date || !endDate) return "";

  switch (selectedTab) {
    case TABS.semanal:
      const firstDay = date.toLocaleDateString("pt-BR", {
        day: "2-digit",
        timeZone: "America/Sao_Paulo",
      });
      const secoundDay = endDate.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        timeZone: "America/Sao_Paulo",
      });
      dateFormated = `${firstDay} à ${secoundDay}`;
      break;

    case TABS.mensal:
      dateFormated = dates?.startDate.toLocaleDateString("pt-BR", {
        month: "long",
        timeZone: "America/Sao_Paulo",
      });
      break;

    case TABS.trimestral:
      const startDateTrimester = date.toLocaleDateString("pt-BR", {
        month: "short",
        timeZone: "America/Sao_Paulo",
      });
      const endDateTrimester = endDate.toLocaleDateString("pt-BR", {
        month: "short",
        timeZone: "America/Sao_Paulo",
      });
      dateFormated =
        `Trimestral - ${startDateTrimester} à ${endDateTrimester}`.replaceAll(
          ".",
          ""
        );
      break;

    case TABS.semestral:
      const startDateFormated = date.toLocaleDateString("pt-BR", {
        month: "short",
        timeZone: "America/Sao_Paulo",
      });
      const endDateFormated = endDate.toLocaleDateString("pt-BR", {
        month: "short",
        timeZone: "America/Sao_Paulo",
      });
      dateFormated =
        `Semestral - ${startDateFormated} à ${endDateFormated}`.replaceAll(
          ".",
          ""
        );
      break;

    case TABS.anual:
      dateFormated +=
        "Anual " +
        date.toLocaleDateString("pt-BR", {
          year: "numeric",
          timeZone: "America/Sao_Paulo",
        });
      dateFormated = dateFormated.replace(".", "");
      break;

    case TABS.custom:
      dateFormated = `${formatDate(dates?.startDate)} - ${formatDate(
        dates?.endDate
      )}`;
      break;

    default:
      break;
  }

  return dateFormated;
};

export const Print: React.FC<HeaderColRigthProps> = ({
  selectedClientName,
  selectedConstructionName,
  selectedTab,
  timePeriodListState,
  dates,
}) => (
  <Box
    display="grid"
    flexDirection="column"
    gap={1}
    sx={{
      gridTemplateColumns: "1fr",
      gridTemplateRows: "max-content max-content max-content",
      alignContent: "start",
      marginTop: "16px",
      rowGap: "12px",
      justifySelf: "flex-end",
    }}
  >
    <Typography
      fontSize="16px"
      fontWeight={700}
      color={Colors.neutralColorWhite}
    >
      Empresa: {selectedClientName}
    </Typography>
    <Typography
      fontSize="16px"
      fontWeight={700}
      color={Colors.neutralColorWhite}
    >
      Obra: {selectedConstructionName}
    </Typography>
    <Typography
      fontSize="16px"
      fontWeight={700}
      color={Colors.neutralColorWhite}
    >
      Período:{" "}
      {getPeriodBySelectedTabForPrintedState(
        selectedTab,
        timePeriodListState,
        dates
      )}
    </Typography>
  </Box>
);
