import { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  TextField,
  LinearProgress as MuiLinearProgress,
  InputAdornment,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { spacing } from "@mui/system";

import { useAuth } from "../../contexts/auth";
import typography from "../../theme/v2/typography";
import { Colors } from "../../theme/v2/variants";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../v2/Button/ButtonComponent";

const Alert = styled(MuiAlert)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);

function FormLogin() {
  const { message, signIn } = useAuth();
  const [inputType, setInputType] = useState("password");

  const toggleInputType = () => {
    setInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  const isButtonEnabled = (
    isValid: boolean,
    values: {
      login: string;
      password: string;
      submit: boolean;
    }
  ): boolean => {
    return isValid && !!values.login && !!values.password;
  };

  return (
    <Formik
      initialValues={{
        login: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        login: Yup.string().max(20).required("Campo login é obrigatório"),
        password: Yup.string().max(255).required("Campo senha é obrigatório"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.login, values.password);
        } catch (error: any) {
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="text"
            name="login"
            label="Login"
            size="medium"
            variant="filled"
            value={values.login}
            error={Boolean(touched.login && errors.login)}
            fullWidth
            helperText={touched.login && errors.login}
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{ marginTop: "14px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    {touched.login && errors.login ? (
                      <ErrorRoundedIcon sx={{ color: Colors.feedbackError }} />
                    ) : (
                      <PersonRoundedIcon
                        sx={{ color: values.login && Colors.primaryColor }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type={inputType}
            name="password"
            label="Senha"
            size="medium"
            variant="filled"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{ marginTop: "14px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleInputType}>
                    {touched.password && errors.password ? (
                      <ErrorRoundedIcon sx={{ color: Colors.feedbackError }} />
                    ) : (
                      <>
                        {inputType === "password" ? (
                          <VisibilityIcon
                            sx={{ color: values.password && Colors.primaryColor }}
                          />
                        ) : (
                          <VisibilityOffIcon
                            sx={{ color: values.password && Colors.primaryColor }}
                          />
                        )}
                      </>
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {message.length > 0 && (
            <Alert mt={3} mb={3} severity="error">
              <strong>{message}</strong>
            </Alert>
          )}
          <ButtonContainer>
            <Typography
              fontSize={typography.body1?.fontSize}
              color={
                errors.login || errors.password
                  ? Colors.feedbackError
                  : Colors.primaryColor
              }
            >
              *Todos os campos são obrigatórios.
            </Typography>
            <ButtonComponent
              startIcon={<LoginRoundedIcon />}
              type={
                isButtonEnabled(isValid, values)
                  ? ButtonType.LINK
                  : ButtonType.TRANSPARENT
              }
              state={
                isButtonEnabled(isValid, values)
                  ? ButtonState.DEFAULT_ENABLED
                  : ButtonState.DISABLED
              }
              text={"Entrar"}
              onClick={() => {
                handleSubmit();
              }}
              padding={{ left: 16, right: 16, top: 8, bottom: 8 }}
            />
          </ButtonContainer>
          {isSubmitting && <LinearProgress mt={6} />}
        </form>
      )}
    </Formik>
  );
}

export default FormLogin;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
