import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import VerticalServiceGroup from "../../../services/interfaces/VerticalServiceGroup";
import MouseHoverDetailsComponent from "./../components/MouseHoverDetailsComponent";
import VerticalServiceType from "../../../services/interfaces/VerticalServiceType";
import MouseHoverActionsComponent from "./../components/MouseHoverActionsComponent";
import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { VerticalServiceReschedulingDto } from "../../../services/interfaces/VerticalServiceReschedulingDto";

interface TableItemProps {
  gridItem: any;
  index: any;
  serviceType: VerticalServiceGroup;
  hoveredItem: any;
  clickedItem: any;
  item: VerticalServiceType;
  showOnlyOneService: boolean;
  onMouseOver: (item: any) => void;
  onMouseLeave: (item: any) => void;
  serviceOnClick: (item: any) => void;
  serviceClickEditActionCallback: (model: any) => void;
  serviceClickDeleteActionCallback: (model: any) => void;
  openUploadImageDialog: (verticalServiceModel?: VerticalServiceModel) => void;
  openUpdateServiceProgressDialog: (model?: VerticalServiceModel) => void;
  moreItemsOnClick: (item: any[], groupColor: string, type: string) => void;
  getServiceTypeOnClick?: (type: string) => void;
  addPurchasingContractingPlanOnClick?: (model?: VerticalServiceModel) => void;
  openAddRestrictionAndActionPlanDialog?: (
    model?: VerticalServiceModel
  ) => void;
  gridLength?: number;
  showIcon?: boolean;
  lastRescheduling?: VerticalServiceReschedulingDto | null;
}

const TableItem: React.FC<TableItemProps> = ({
  gridItem,
  index,
  serviceType,
  hoveredItem,
  clickedItem,
  item,
  showOnlyOneService,
  onMouseOver,
  onMouseLeave,
  serviceOnClick,
  serviceClickEditActionCallback,
  serviceClickDeleteActionCallback,
  openUploadImageDialog,
  moreItemsOnClick,
  getServiceTypeOnClick,
  openUpdateServiceProgressDialog,
  addPurchasingContractingPlanOnClick,
  openAddRestrictionAndActionPlanDialog,
  gridLength,
  showIcon = false,
  lastRescheduling,
}) => {
  const isLastColumns = index >= (gridLength || 0) - 2;

  return (
    <ItemGridContainer key={index} gridTemplate={(gridItem as any).rowTemplate}>
      {!gridItem.isEmpty ? (
        (gridItem.subItems as any[]).length === 1 ? (
          gridItem.subItems.map((subItem: any, index: number) => (
            <SubItemGridContainer
              style={{
                backgroundColor: `${serviceType.color}`,
              }}
              onMouseEnter={() => onMouseOver(subItem)}
              onMouseLeave={() => onMouseLeave(subItem)}
              onClick={() => {
                serviceOnClick(subItem);
                getServiceTypeOnClick?.(item.name);
              }}
            >
              {lastRescheduling && showIcon ? (
                <LocationTextContainerWithIcon
                  gridLength={gridLength}
                  title={subItem.location}
                >
                  <InfoOutlinedIcon />
                  {subItem.location}
                </LocationTextContainerWithIcon>
              ) : (
                <LocationTextContainer
                  gridLength={gridLength}
                  title={subItem.location}
                >
                  {subItem.location}
                </LocationTextContainer>
              )}
              {hoveredItem &&
              hoveredItem.uniqueApplicationId ===
                gridItem.subItems[0].uniqueApplicationId ? (
                <MouseHoverContainer
                  isLastColumns={isLastColumns}
                  showIcon={showIcon}
                  lastRescheduling={lastRescheduling ? true : false}
                >
                  <MouseHoverDetailsComponent
                    serviceGroupColor={serviceType.color}
                    serviceType={item.name}
                    timePeriod={{
                      startDate: new Date(gridItem.subItems[0].beginDate),
                      endDate: new Date(gridItem.subItems[0].endDate),
                    }}
                    totalDays={gridItem.subItems[0].totalDays}
                    responsibleName={
                      gridItem.subItems[0].responsibleUserId.name
                    }
                    status={gridItem.subItems[0].status}
                    isLastColumns={isLastColumns}
                    showIcon={showIcon}
                    lastRescheduling={lastRescheduling?.reschedulingReason}
                  />
                </MouseHoverContainer>
              ) : null}

              {clickedItem &&
              clickedItem.uniqueApplicationId ===
                gridItem.subItems[0].uniqueApplicationId ? (
                <MouseHoverActionContainer isLastColumns={isLastColumns}>
                  <MouseHoverActionsComponent
                    model={gridItem.subItems[0]}
                    editServiceOnClick={(model) =>
                      serviceClickEditActionCallback(model)
                    }
                    deleteServiceOnClick={(model) =>
                      serviceClickDeleteActionCallback(model)
                    }
                    uploadImageOnClick={(model) => openUploadImageDialog(model)}
                    updateServiceProgressOnClick={(model) =>
                      openUpdateServiceProgressDialog(model)
                    }
                    addPurchasingContractingPlanOnClick={(model) => {
                      addPurchasingContractingPlanOnClick?.(model);
                    }}
                    addRestrictionAndActionPlanOnClick={(model) => {
                      openAddRestrictionAndActionPlanDialog?.(model);
                    }}
                  />
                </MouseHoverActionContainer>
              ) : null}
            </SubItemGridContainer>
          ))
        ) : (
          <SubItemMoreItemsGridContainer
            onClick={() =>
              moreItemsOnClick(
                gridItem.subItems as any[],
                serviceType.color,
                item.name
              )
            }
          >
            {"+ " + (gridItem.subItems as any[]).length + " serviços"}
          </SubItemMoreItemsGridContainer>
        )
      ) : null}
    </ItemGridContainer>
  );
};

const LocationTextContainer = styled.div<{ gridLength?: number }>`
  overflow: hidden;
  font-size: ${(props) =>
    props.gridLength ? (props.gridLength > 10 ? "10px" : "13px") : "13px"};
`;

const LocationTextContainerWithIcon = styled.div<{ gridLength?: number }>`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  gap: 12px;
  font-size: ${(props) =>
    props.gridLength ? (props.gridLength > 10 ? "10px" : "13px") : "13px"};
`;

const BaseItemGridContainer = styled.div``;

// eslint-disable-next-line prettier/prettier
const ItemGridContainer = styled(BaseItemGridContainer)<{gridTemplate: string}>`
  max-width: 100%;
  height: 52px;
  background: #f8f9fc;
  border-left: 1px rgba(178, 178, 178, 0.5) solid;
  border-bottom: 1px rgba(178, 178, 178, 0.5) solid;
  size: 12px;
  font-family: "Noto Sans", Inter, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: grid;

  color: #000;
  row-gap: 1px;

  /* @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 48px;
    font-size: 10px;
  } */
`;

const SubItemGridContainer = styled.div`
  display: grid;
  color: #f8f9fc;
  width: 100%;
  text-align: center;
  align-content: center;
  padding: 4px;
`;

const SubItemMoreItemsGridContainer = styled.div`
  display: grid;
  background-color: #000;
  color: #f8f9fc;
  width: 100%;
  text-align: center;
  align-content: center;
`;

const MouseHoverContainer = styled.div<{
  isLastColumns: boolean;
  showIcon: boolean;
  lastRescheduling: boolean;
}>`
  position: relative;
  margin-top: ${(props) =>
    props.lastRescheduling && props.isLastColumns
      ? "-285px"
      : props.lastRescheduling
      ? "-285px"
      : "-224px"};
  margin-left: ${(props) =>
    props.showIcon && props.lastRescheduling && props.isLastColumns
      ? "-230px"
      : props.isLastColumns
      ? "-230px"
      : "-95px"};
  z-index: 99;
`;

const MouseHoverActionContainer = styled.div<{ isLastColumns: boolean }>`
  position: relative;
  margin-top: -20px;
  margin-left: ${(props) => (props.isLastColumns ? "-200px" : "0")};
  z-index: 99;
`;

export default TableItem;
