import React, { KeyboardEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Formik, Field, FormikHelpers } from "formik";
import * as yup from "yup";
import {
  TextField,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Select,
  Switch,
  SelectChangeEvent,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { DoneAll as DoneAllIcon } from "@mui/icons-material";
import InputMask from "react-input-mask";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import Alert from "../../../components/Alert";
import { api } from "../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import {
  Client,
  Construction,
  statesOfBrazil,
  User,
} from "../../../config/app-info";
import { useAuth } from "../../../contexts/auth";
import "dayjs/locale/pt-br";
import { useDashboard } from "../../../contexts/dashboard";
import { AxiosResponse } from "axios";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const CustomUISwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FF0000",
    "&.Mui-checked": {
      color: "#32CD32",
    },
  },
}));

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, "O nome deve ter no mínimo 3 letras")
    .required("Nome é obrigatório"),
  managerUserId: yup.string().required("Gestor de Obra é obrigatório"),
  executiveAdvisorUserId: yup
    .string()
    .required("Assessor Executivo Responsável é obrigatório"),
  clientId: yup.string().required("Cliente Responsável é obrigatório"),
});

type Props = {
  section: string;
};

const ConstructionsForm: React.FC<Props> = ({ section }) => {
  const [constructionEdit, setConstructionEdit] = useState<Construction>(
    {} as Construction
  );
  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpened: false,
  });
  const [dtBegin, setDtBegin] = useState<Date | null>(null);
  const [dtEnd, setDtEnd] = useState<Date | null>(null);
  const [clients, setClients] = useState<Client[]>([]);
  const { clientId: cId } = useDashboard();
  const [clientId, setClientId] = useState(cId || 0);
  const [managerUsers, setManagerUsers] = useState<User[]>([]);
  const [executiveAdvisorUsers, setExecutiveAdvisorUsers] = useState<User[]>(
    []
  );
  const { user } = useAuth();
  const navigate = useNavigate();
  let { id: constructionEditId } = useParams();

  // useEffect(() => {
  //   if(constructionEditId) {
  //     navigate(`/obras/editar/${+constructionEditId}`)
  //   }
  // }, [constructionEditId])

  useEffect(() => {
    let endpoint = clientId
      ? `api/users/managers/client/${clientId}`
      : "api/users/managers";
        
    const fetchManagerUSers = async () => {
      const response = await api.get(endpoint);
      setManagerUsers(response.data);
    };
    fetchManagerUSers();
  }, [clientId]);

  useEffect(() => {
    if (constructionEditId) {
      const fetchConstructionData = async () => {
        const response: AxiosResponse<Construction, any> = await api.get(
          `api/constructions/${constructionEditId}`
        );

        response.data.budget = String(response.data.budget).replace(".", ",");

        setConstructionEdit(response.data);
        setClientId(response.data.clientId)
        setDtBegin(response.data.dtBegin);
        setDtEnd(response.data.dtEnd);
      };
      
      fetchConstructionData();
    } else {
      setConstructionEdit({
        id: 0,
        code: "",
        name: "",
        address: "",
        number: "",
        cep: "",
        neighborhood: "",
        complement: "",
        city: "",
        uf: "",
        dtBegin: new Date(),
        dtEnd: new Date(),
        status: true,
        finished: false,
        budget: "",
        additionalInformation: "",
        scope: "",
        latitude: "",
        longitude: "",
        userId: 0,
        managerUserId: 0,
        executiveAdvisorUserId: 0,
        clientId: 0,
      } as Construction);
    }

    const fetchClients = async () => {
      const response = await api.get("api/clients");
      setClients(response.data);
    };
    fetchClients();

    const fetchExecutiveAdvisorUsers = async () => {
      const response = await api.get("api/users/executive-advisors");
      setExecutiveAdvisorUsers(response.data);
    };
    fetchExecutiveAdvisorUsers();
  }, [constructionEditId]);

  const onSubmit = async (
    values: Construction,
    { setSubmitting, resetForm }: FormikHelpers<Construction>
  ) => {
    setSubmitting(true);
    values.dtBegin = new Date(String(dtBegin).split("/").reverse().join("-"));
    values.dtEnd = new Date(String(dtEnd).split("/").reverse().join("-"));
    values.userId = user.id;
    if (!values.finished) {
      values.finished = false;
    }
    if (!values.status) {
      values.status = false;
    }

    if (values.budget !== "null") {
      values.budget = values.budget
        ? String(values.budget).replace(".", "").replace(",", ".")
        : "0";
    } else {
      values.budget = "0";
    }

    if (constructionEditId) {
      try {
        const response = await api.put(
          `api/constructions/${constructionEditId}/`,
          values
        );
        if (response.status === 200 || response.status === 204) {
          values.budget = String(values.budget).replace(".", ",");
          setAlert({
            title: "Alteração concluída!",
            message: "Obra atualizada com sucesso",
            isOpened: true,
          });
        }
      } catch (error) {
        setAlert({
          title: "Erro na alteração",
          message: "Algo deu errado. Tente novamente mais tarde.",
          isOpened: true,
        });
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const response = await api.post(`api/constructions`, values);

        if (response.status === 201) {
          setAlert({
            title: "Cadastro concluído!",
            message: "Obra cadastrada com sucesso",
            isOpened: true,
          });
          setDtBegin(null);
          setDtEnd(null);
          resetForm();
        }
      } catch (error) {
        setAlert({
          title: "Erro no cadastro",
          message: "Algo deu errado. Tente novamente mais tarde.",
          isOpened: true,
        });
      } finally {
        setSubmitting(false);
      }
    }
  };

  const closeError = () => {
    setAlert({
      title: "",
      message: "",
      isOpened: false,
    });
  };

  const checkKeyDown = (e: KeyboardEvent) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const handleChangeClient = async (client: number) => {
    setClientId(client);
  };

  return (
    <>
      <Helmet>
        <title>{section}</title>
      </Helmet>
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            {section}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          {constructionEdit && (
            <Formik
              initialValues={constructionEdit}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                errors,
                isSubmitting,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  noValidate
                  onKeyDown={(e) => checkKeyDown(e)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={2} mb={4}>
                      <Field
                        component={TextField}
                        label="Código da obra"
                        id="code"
                        name="code"
                        value={values.code || ""}
                        onChange={handleChange}
                        error={Boolean(errors.code)}
                        helperText={errors.code}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={6} mb={4}>
                      <Field
                        component={TextField}
                        label="Nome"
                        id="name"
                        name="name"
                        onChange={handleChange}
                        value={values.name || ""}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={2} mb={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="pt-br"
                      >
                        <DatePicker
                          label="Data início"
                          onChange={(newValue) => {
                            setDtBegin(newValue);
                          }}
                          value={dtBegin}
                          renderInput={(params) => (
                            <TextField
                              placeholder="dd/mm/aaaa"
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={2} mb={4}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="pt-br"
                      >
                        <DatePicker
                          label="Data fim"
                          onChange={(newValue) => {
                            setDtEnd(newValue);
                          }}
                          value={dtEnd}
                          renderInput={(params) => (
                            <TextField
                              placeholder="dd/mm/aaaa"
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={4} mb={4}>
                      <Field
                        component={TextField}
                        label="Logradouro"
                        id="address"
                        name="address"
                        onChange={handleChange}
                        value={values.address || ""}
                        error={Boolean(errors.address)}
                        helperText={errors.address}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={2} mb={4}>
                      <Field
                        component={TextField}
                        label="Número"
                        id="number"
                        name="number"
                        onChange={handleChange}
                        value={values.number || ""}
                        error={Boolean(errors.number)}
                        helperText={errors.number}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={2} mb={4}>
                      <Field
                        name="cep"
                        error={Boolean(errors.cep)}
                        helperText={errors.cep}
                      >
                        {({ field }: any) => (
                          <InputMask
                            {...field}
                            mask="99999-999"
                            value={values.cep || ""}
                          >
                            <TextField
                              {...field}
                              name="cep"
                              id="cep"
                              variant="outlined"
                              size="small"
                              fullWidth
                              label="CEP"
                              onChange={handleChange}
                              type="text"
                            />
                          </InputMask>
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={4} mb={4}>
                      <Field
                        component={TextField}
                        label="Bairro"
                        id="neighborhood"
                        name="neighborhood"
                        onChange={handleChange}
                        value={values.neighborhood || ""}
                        error={Boolean(errors.neighborhood)}
                        helperText={errors.neighborhood}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={3} mb={4}>
                      <Field
                        component={TextField}
                        label="Complemento"
                        id="complement"
                        name="complement"
                        onChange={handleChange}
                        value={values.complement || ""}
                        error={Boolean(errors.complement)}
                        helperText={errors.complement}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Field
                        component={TextField}
                        label="Cidade"
                        id="city"
                        name="city"
                        value={values.city || ""}
                        onChange={handleChange}
                        error={Boolean(errors.city)}
                        helperText={errors.city}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={1} mb={2}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="uf">UF</InputLabel>
                        <Field
                          component={Select}
                          native
                          value={values.uf || ""}
                          onChange={handleChange}
                          error={Boolean(errors.uf)}
                          label="UF"
                          inputProps={{
                            name: "uf",
                            id: "uf",
                          }}
                        >
                          <option aria-label="Escolha uma UF" value=""></option>
                          {statesOfBrazil.map((uf) => {
                            return (
                              <option key={uf} value={uf}>
                                {uf}
                              </option>
                            );
                          })}
                        </Field>
                        <FormHelperText error={Boolean(errors.uf)}>
                          {errors.uf}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2} mb={2}>
                      <FormControlLabel
                        control={
                          <Field
                            component={CustomUISwitch}
                            checked={values.finished || false}
                            onChange={handleChange}
                            color="default"
                            id="finished"
                            name="finished"
                            value={values.finished || false}
                            inputProps={{ "aria-label": "finished" }}
                          />
                        }
                        label="Obra concluída"
                      />
                    </Grid>

                    <Grid item xs={2} mb={2}>
                      <FormControlLabel
                        control={
                          <Field
                            component={CustomUISwitch}
                            checked={values.status || false}
                            onChange={handleChange}
                            color="default"
                            id="status"
                            name="status"
                            value={values.status || false}
                            inputProps={{ "aria-label": "status" }}
                          />
                        }
                        label="Obra Ativa"
                      />
                    </Grid>

                    <Grid item xs={4} mb={2}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="client">
                          Cliente responsável
                        </InputLabel>
                        <Field
                          component={Select}
                          native
                          value={values.clientId || -1}
                          onChange={(event: SelectChangeEvent) => {
                            handleChange(event);
                            handleChangeClient(Number(event.target.value));
                          }}
                          error={Boolean(errors.clientId)}
                          label="Cliente responsável"
                          inputProps={{
                            name: "clientId",
                            id: "clientId",
                          }}
                        >
                          <option
                            aria-label="Cliente responsável"
                            value=""
                          ></option>
                          {clients.map((client) => {
                            return (
                              <option key={client.id} value={client.id}>
                                {client.name}
                              </option>
                            );
                          })}
                        </Field>
                        <FormHelperText error={Boolean(errors.clientId)}>
                          {errors.clientId}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} mb={2}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="managerUser">
                          Gestor da obra
                        </InputLabel>
                        <Field
                          component={Select}
                          native
                          value={values.managerUserId || -1}
                          onChange={handleChange}
                          error={Boolean(errors.managerUserId)}
                          label="Gestor da obra"
                          inputProps={{
                            name: "managerUserId",
                            id: "managerUserId",
                          }}
                        >
                          <option aria-label="Gestor da obra" value=""></option>
                          {managerUsers.map((managerUser) => {
                            return (
                              <option
                                key={managerUser.id}
                                value={managerUser.id}
                              >
                                {managerUser.name}
                              </option>
                            );
                          })}
                        </Field>
                        <FormHelperText error={Boolean(errors.managerUserId)}>
                          {errors.managerUserId}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} mb={2}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="executiveAdvisorUser">
                          Assessor executivo responsável
                        </InputLabel>
                        <Field
                          component={Select}
                          native
                          value={values.executiveAdvisorUserId || -1}
                          onChange={handleChange}
                          error={Boolean(errors.executiveAdvisorUserId)}
                          label="Assessor executivo responsável"
                          inputProps={{
                            name: "executiveAdvisorUserId",
                            id: "executiveAdvisorUserId",
                          }}
                        >
                          <option
                            aria-label="Assessor executivo responsável"
                            value=""
                          ></option>
                          {executiveAdvisorUsers.map((executiveAdvisorUser) => {
                            return (
                              <option
                                key={executiveAdvisorUser.id}
                                value={executiveAdvisorUser.id}
                              >
                                {executiveAdvisorUser.name}
                              </option>
                            );
                          })}
                        </Field>
                        <FormHelperText
                          error={Boolean(errors.executiveAdvisorUserId)}
                        >
                          {errors.executiveAdvisorUserId}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2} mb={4}>
                      <Field
                        component={TextField}
                        label="Orçamento (R$)"
                        id="budget"
                        name="budget"
                        value={
                          values.budget === "null" ? "0" : values.budget || ""
                        }
                        onChange={handleChange}
                        error={Boolean(errors.budget)}
                        helperText={errors.budget}
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ marginBottom: "12px" }}
                      />
                      <Field
                        component={TextField}
                        label="Latitude"
                        id="latitude"
                        name="latitude"
                        value={values.latitude || ""}
                        onChange={handleChange}
                        error={Boolean(errors.latitude)}
                        helperText={errors.latitude}
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{ marginBottom: "12px" }}
                      />
                      <Field
                        component={TextField}
                        label="Longitude"
                        id="longitude"
                        name="longitude"
                        value={values.longitude || ""}
                        onChange={handleChange}
                        error={Boolean(errors.longitude)}
                        helperText={errors.longitude}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={5} mb={4}>
                      <Field
                        component={TextField}
                        multiline
                        rows={6}
                        label="Escopo"
                        id="scope"
                        name="scope"
                        onChange={handleChange}
                        value={values.scope || ""}
                        error={Boolean(errors.scope)}
                        helperText={errors.scope}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item xs={5} mb={4}>
                      <Field
                        component={TextField}
                        multiline
                        rows={6}
                        label="Informações adicionais"
                        id="additionalInformation"
                        name="additionalInformation"
                        onChange={handleChange}
                        value={values.additionalInformation || ""}
                        error={Boolean(errors.additionalInformation)}
                        helperText={errors.additionalInformation}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>

                  {user.role !== 6 && (
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      endIcon={<DoneAllIcon />}
                    >
                      Salvar
                    </Button>
                  )}
                </form>
              )}
            </Formik>
          )}
        </CardContent>
      </Card>

      <Alert
        onClose={() => closeError()}
        isOpened={alert.isOpened}
        title={alert.title}
        message={alert.message}
        buttonLabel="Fechar"
      />
    </>
  );
};

export default ConstructionsForm;
