import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import { ContractingPlanEntity } from "../../../services/purchasingContractingPlan/entity/ContractingPlanEntity";
import { ContractingPhaseENUM } from "./ContractingPhaseENUM";
import { ContractingPlanStatusENUM } from "./ContractingPlanStatusENUM";

interface IResponsibleUser {
  id: number;
  name: string;
}

export interface ContractingItem {
  id?: number;
  name: string;
  specifications: string;
  cost: number;
}

interface ContractingModel {
  id?: number;
  name: string;
  specifications?: string;
  unit?: string;
  cost?: number;
  quantity?: number;
  supplierQuotationDate?: Date;
  contractingDateLimit?: Date;
  deliveryDateLimit?: Date;
  supplierQuotationDays?: number;
  purchasingLimitDays?: number;
  deliveryLimitDays?: number;
  securityDays?: number;
  purchaseLimitDate?: string;
  deliveryLimitDate?: string;
  status?: ContractingPlanStatusENUM;
  phase?: ContractingPhaseENUM;
  isPhaseInitiated?: boolean;
  responsisbleName?: string;
  responsibleId?: number;
  responsibleRole?: number;
  requesterResponsableId?: number;
  requesterResponsable?: IResponsibleUser;
  supplierQuotatioResponsableId?: number;
  supplierQuotatioResponsable?: IResponsibleUser;
  approveResponsableId?: number;
  approveResponsable?: IResponsibleUser;
  purchasingResponsableId?: number;
  purchasingResponsable?: IResponsibleUser;
  finishPlanResponsableId?: number;
  finishPlanResponsable?: IResponsibleUser;
  services: VerticalServiceModel[];
  supplierQuotationStartDate?: string;
  supplierQuotationFinishedDate?: string;
  approveStartDate?: string;
  approveFinishedDate?: string;
  contractingStartDate?: string;
  contractingFinishedDate?: string;
  approveReceiptStartDate?: string;
  approveReceiptFinishedDate?: string;
  description?: string;
  contractingItems?: ContractingItem[]
}

export const parseContractingEntityToModel = (
  item: ContractingPlanEntity
): ContractingModel => {
  return {
    id: item.id || 0,
    name: item.name,
    cost: item.cost,
    // name: item.name,
    // specifications: item.specifications,
    supplierQuotationDate: item.supplierQuotationDate
      ? new Date(item.supplierQuotationDate)
      : undefined,
    contractingDateLimit: item.contractingLimitDate
      ? new Date(item.contractingLimitDate)
      : undefined,
    deliveryDateLimit: item.deliveryLimitDate
      ? new Date(item.deliveryLimitDate)
      : undefined,
    securityDays: item.safetyDays,
    supplierQuotationDays: item.supplierQuotationDays,
    purchasingLimitDays: item.contractingLimitDays,
    deliveryLimitDays: item.deliveryLimitDays,
    status: item.status,
    phase: item.phase,
    isPhaseInitiated: item.isPhaseInitiated === true,
    responsisbleName: item.currentResponsible?.name || undefined,
    responsibleId: item.currentResponsible?.id || undefined,
    responsibleRole: item.currentResponsible?.role || undefined,
    services: item.verticalServices,
    requesterResponsable: item.requesterResponsable,
    requesterResponsableId: item.requesterResponsableId,
    supplierQuotatioResponsable: item.supplierQuotatioResponsable,
    supplierQuotatioResponsableId: item.supplierQuotatioResponsableId,
    approveResponsable: item.approveResponsable,
    approveResponsableId: item.approveResponsableId,
    purchasingResponsable: item.contractingResponsable,
    purchasingResponsableId: item.contractingResponsableId,
    finishPlanResponsable: item.approveReceiptResponsible,
    finishPlanResponsableId: item.approveReceiptResponsibleId,
    supplierQuotationStartDate: item.supplierQuotationStartDate,
    supplierQuotationFinishedDate: item.supplierQuotationFinishedDate,
    approveStartDate: item.approveStartDate,
    approveFinishedDate: item.approveFinishedDate,
    approveReceiptStartDate: item.approveReceiptStartDate,
    approveReceiptFinishedDate: item.approveReceiptFinishedDate,
    contractingStartDate: item.contractingStartDate,
    contractingFinishedDate: item.contractingFinishedDate,
    contractingItems: item.contractingItems,
  } as ContractingModel;
};

export default ContractingModel;
