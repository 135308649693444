import { StringIfPlural } from "react-i18next";
import { api } from "./api";
import { StringArraySupportOption } from "prettier";

export default class ConstructionService {
  updateImage = async (constructionId: string, image: any) => {
    const result = await api.post(
      `/api/constructions/${constructionId}/image`,
      image,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data;
  };

  async deleteConstructionImage(id: number) {
    try {
      await api.delete(`/api/constructions/${id}/delete-image`);
      return true;
    } catch (error) {
      console.error("Erro ao excluir a imagem da construção:", error);
      return false;
    }
  }

  async findById(id: number) {
    try {
      const response = await api.get(`/api/constructions/info/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getConstructionImage(id: number) {
    try {
      const response = await api.get(`/api/constructions/image/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
