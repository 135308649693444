import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: 10,
    },
    formControl: {
      width: "100%",
      marginBottom: "10px",
    },
    buttonSave: {},
    input: {
      marginBottom: "10px",
    },
  })
);

export default useStyles;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
  margin-bottom: 28px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
  }

  /* @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  } */

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 100px;
  width: auto;

  @media (max-width: 1000px) {
    gap: 24px;
  }

  @media (max-width: 900px) {
    width: 100%;
    gap: 16px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 16px;
  }
`;
