import React, {
  ChangeEvent,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ReactBaseComponent from "../../../components/BaseComponents/ReactBaseComponent";
import {
  AutoCompleteCustomMenuItem,
  BootstrapDialog,
  BuilderAndSupplierContainer,
  BuilderAndSupplierContainerDescription,
  ButtonContainerWrap,
  ButtonContainerStart,
  ButtonContainerEnd,
  CenterContainer,
  ChooseFormContainer,
  ContentContainer,
  ContractingForm,
  CustomMenuItemText,
  CustomTab,
  DialogTitle,
  FormDescriptionText,
  HeaderContainer,
  GroupedHeaderContainer,
  LocationFormContainer,
  PageContainer,
  PurchasingAndContractionContainer,
  PurchasingForm,
  PurchasingFormRowOne,
  ResponsibleContainer,
  ResponsibleContainerTitle,
  ResponsibleRow,
  ResponsibleRowDesctipion,
  SelectedTag,
  SelectedTagText,
  StartAndEndDateContainer,
  ServiceFormContainer,
  TabContainer,
  ResponsibleForm,
  FilesInputContainer,
  FilesInput,
  ResponsibleFormRow,
  ContractingFormRow,
  ModalContent,
  CloseButton,
  ModalHeader,
  ModalTitle,
  ModalForm,
  ModalFormRow,
  ModalButtonRow,
  ModalToggleButton,
  FileItem,
  FileItemText,
  ListFilesContainer,
  ModalSucessFilesContent,
  ModalSuccesFilesText,
  ButtonContainer,
} from "./PurchasingAndContractingPlanForm.styles";
import PopUpBaseComponent from "../../../components/PopUps/PopUpBaseComponent";
import CustomDropDownComponent, {
  ItemType,
} from "../../../components/InputValues/CustomDropDownComponent";
import InputDateComponent from "../../../components/InputValues/input-date/InputDateComponent";
import Alert from "../../../components/Alert";
import {
  Autocomplete,
  Box,
  Checkbox,
  Snackbar,
  Tabs,
  TextField,
  Modal,
  IconButton,
  Typography,
  ToggleButtonGroup,
  FormHelperText,
} from "@mui/material";
import { PurchasingContractingENUM } from "../models/PurchasingContractingENUM";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import InputValueComponent from "../../../components/InputValues/InputValueComponent";
import { ReactComponent as InfoIcon } from "../../../../assets/info-icon.svg";
import ReactPageBaseComponent from "../../../components/BaseComponents/ReactPageBaseComponent";
import { BaseState } from "../../Actions/ViewActions/utils/BaseState";
import { useDashboard } from "../../../contexts/dashboard";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import EmptyClientAndConstructionState from "../../../components/StateComponents/EmptyClientAndConstruction";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import ErrorComponent from "../../../components/StateComponents/ErrorComponent";
import VerticalService from "../../../services/VeritcalService";
import { set } from "date-fns";
import VerticalServiceModel from "../../../services/interfaces/VerticalServiceModel";
import DropDownComponent from "../../../components/InputValues/DropDownComponent";
import UserService, { UserResponsible } from "../../../services/UserService";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/v2/Button/ButtonComponent";
import Title from "../../../components/v2/Typography/Tittle";
import PurchasingContractingPlanService from "../../../services/purchasingContractingPlan/PurchasingContractingPlanService";
import ManageFilesService from "../../../services/files/ManageFilesService";
import PurchasingModel, {
  parsePurchasingEntityToModel,
} from "../models/PurchasingModel";
import ContractingModel, {
  parseContractingEntityToModel,
} from "../models/ContractingMode";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  PURCHASING_CONTRACTING_PLAN_PATH,
  User,
} from "../../../config/app-info";
import { modalSelectStyle } from "../../../utils/custom-styles/selectCustomStyleModal";
import { modalTextFieldStyle } from "../../../utils/custom-styles/textFieldCustomStylesModal";
import { textFieldStyle } from "../../../utils/custom-styles/textFieldCustomStyles";
import { ArrowBackSharp } from "@mui/icons-material";

import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CheckIcon from "@mui/icons-material/Check";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { Colors } from "../../../theme/v2/variants";

import { useAuth } from "../../../contexts/auth";
import PurchasingAndContractingListUploadSuccessModal from "../components/Modals/PurchasingAndContractingListUploadSuccessModal";
import LoadingModal from "../components/Modals/LoadingModal";
import Footer from "../../../components/v2/Footer";
import PurchasingAndContractingSuppliersModal from "../components/PurchasingAndContractingSuppliersModal/PurchasingAndContractingSuppliersModal";
import FormPurchasingItems from "../components/FormPurchasingItems";
import FormContractingItems from "../components/FormContractingItems";

interface PurchasingAndContractingPlanFormProps {}

export interface PurchasingAndContractingPlanFormRef {
  cleanData: () => void;
}

export enum PurchasingContractingPlanFormType {
  CREATE = "CREATE",
  EDIT_PURCHASING = "EDIT_PURCHASING",
  EDIT_CONTRACTING = "EDIT_CONTRACTING",
}

interface FileProps {
  id: number;
  fileName: string;
  uploadedBy: string;
}

const fileCategories = [
  {
    key: "pre-quoting",
    name: "Pré-cotação",
    subCategories: [
      {
        name: "Requisição de compra",
      },
      {
        name: "Especificações técnicas",
      },
      {
        name: "Projetos e Desenhos",
      },
      {
        name: "Lista de Fornecedores",
      },
    ],
  },
  {
    key: "quoting",
    name: "Cotação",
    subCategories: [
      {
        name: "Planilha de Cotação",
      },
      {
        name: "Política de Compras",
      },
    ],
  },
  {
    key: "negociation",
    name: "Negociação e Seleção",
    subCategories: [
      {
        name: "Planilha Comparativa",
      },
      {
        name: "Análise de Fornecedores",
      },
      {
        name: "Minuta de Contrato",
      },
    ],
  },
  {
    key: "purchasing-emission",
    name: "Emissão do Pedido de Compra",
    subCategories: [
      {
        name: "Pedido de Compra",
      },
      {
        name: "Ordem de Serviço",
      },
      {
        name: "Nota Fiscal",
      },
    ],
  },
  {
    key: "order-accompaniment",
    name: "Acompanhamento do Pedido",
    subCategories: [
      {
        name: "Cronograma de Entregas",
      },
      {
        name: "Relatório de Status",
      },
      {
        name: "Checklists",
      },
    ],
  },
  {
    key: "building-receiving",
    name: "Recebimento na Obra",
    subCategories: [
      {
        name: "Comprovante de Recebimento",
      },
      {
        name: "Nota Fiscal",
      },
      {
        name: "Registro de Ocorrências",
      },
    ],
  },
  {
    key: "pos-delivery-and-payment",
    name: "Pós-Entrega e Pagamento",
    subCategories: [
      {
        name: "Comprovante de Pagamento",
      },
      {
        name: "Avaliação do Fornecedor",
      },
      {
        name: "Histórico de Compras",
      },
    ],
  },
];

/*
  const AddServicePopUp = React.forwardRef<
  AddServicePopUpRef | undefined,
  AddServicePopUpProps
>(({ ...props }, ref) => {
*/

const yupSchema = Yup.object().shape({
  // product: Yup.string().when("selectedTab", {
  //   is: PurchasingContractingENUM.PURCHASING,
  //   then: Yup.string()
  //     .required("Campo obrigatório")
  //     .min(2, "Mínimo de 2 caracteres"),
  // }),
  // quantity: Yup.number().when("selectedTab", {
  //   is: PurchasingContractingENUM.PURCHASING,
  //   then: Yup.number()
  //     .required("Campo obrigatório")
  //     .required("Campo obrigatório"),
  // }),
  // unit: Yup.string().when("selectedTab", {
  //   is: PurchasingContractingENUM.PURCHASING,
  //   then: Yup.string()
  //     .required("Campo obrigatório")
  //     .min(1, "Mínimo de 1 caractere"),
  // }),
  // purchasingSpecifications: Yup.string().when("selectedTab", {
  //   is: PurchasingContractingENUM.PURCHASING,
  //   then: Yup.string()
  //     .required("Campo obrigatório")
  //     .min(1, "Mínimo de 1 caractere"),
  // }),
  service: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.CONTRACTING,
    then: Yup.string().min(1, "Mínimo de 1 caractere"),
  }),
  contractingSpecifications: Yup.string().when("selectedTab", {
    is: PurchasingContractingENUM.CONTRACTING,
    then: Yup.string().min(1, "Mínimo de 1 caractere"),
  }),
});

const PurchasingAndContractingPlanForm = React.forwardRef<
  PurchasingAndContractingPlanFormRef | undefined,
  PurchasingAndContractingPlanFormProps
>(({ ...props }, ref) => {
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { constructionId } = useDashboard();
  const verticalService = new VerticalService();
  const userService = new UserService();
  const purchasingContractingService = new PurchasingContractingPlanService();
  const manageFilesService = new ManageFilesService();

  const [state, setState] = useState<BaseState>(BaseState.success);
  const [formType, setFormType] = useState<PurchasingContractingPlanFormType>(
    searchParams.get("type") === null || searchParams.get("type") === undefined
      ? PurchasingContractingPlanFormType.CREATE
      : searchParams.get("type") === PurchasingContractingENUM.PURCHASING
      ? PurchasingContractingPlanFormType.EDIT_PURCHASING
      : PurchasingContractingPlanFormType.EDIT_CONTRACTING
  );

  const [purchasingSelectedPlan, setPurchasingSelectedPlan] = useState<
    PurchasingModel | undefined
  >();

  const [contractingSelectedPlan, setContractingSelectedPlan] = useState<
    ContractingModel | undefined
  >();

  const [selectedServiceType, setSelectedServiceType] = useState<ItemType>();

  const [servicesTypes, setServicesTypes] = useState<any[]>([]);
  const [verticalServices, setVerticalServices] = useState<
    VerticalServiceModel[]
  >([]);
  const [selectedServices, setSelectedServices] = useState<
    VerticalServiceModel[]
  >([]);
  const [responsibleUsers, setResponsibleUsers] = useState<UserResponsible[]>(
    []
  );
  const [requesterResponsible, setRequesterResponsible] = useState<string>("");
  const [cotationResponsible, setCotationResponsible] = useState<string>("");
  const [approvalResponsible, setApprovalResponsible] = useState<string>("");
  const [purchasingResponsible, setPurchasingResponsible] =
    useState<string>("");
  const [approveReceiptResponsible, setApproveReceiptResponsible] =
    useState<string>("");

  const [contractingRequesterResponsible, setContractingRequesterResponsible] =
    useState<string>("");
  const [contractingCotationResponsible, setContractingCotationResponsible] =
    useState<string>("");
  const [contractingApprovalResponsible, setContractingApprovalResponsible] =
    useState<string>("");
  const [
    contractingPurchasingResponsible,
    setContractingPurchasingResponsible,
  ] = useState<string>("");
  const [
    contractingApproveReceiptResponsible,
    setContractingApproveReceiptResponsible,
  ] = useState<string>("");

  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>();
  const [firstContructionId, setFirstContructionId] = useState<number>();

  const [alignment, setAlignment] = React.useState("anexar");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFilesOpen, setIsModalFilesOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [isSuppliersModalOpen, setIsSuppliersModalOpen] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [identification, setIdentification] = useState<string>("");
  const [filesList, setFilesList] = useState<FileProps[]>([]);

  // Esse estado armazena o Id do VerticalServiceGroup selecionado
  const [groupId, setGroupId] = useState<number | null>(null);

  const [alert, setAlert] = useState({
    title: "",
    message: "",
    isOpened: false,
  });

  const planType =
    formType === PurchasingContractingPlanFormType.EDIT_PURCHASING
      ? "PURCHASING"
      : "CONTRACTING";

  const toggleModal = () => {
    getUploadedFilesHandler();
    setIsModalOpen(!isModalOpen);
  };
  const closeModal = () => setIsModalOpen(false);

  const toggleFilesModal = () => setIsModalFilesOpen(!isModalFilesOpen);
  const closeFilesModal = () => setIsModalFilesOpen(false);

  const toggleLoadingModal = () => setIsLoadingModalOpen(!isLoadingModalOpen);
  const closeLoadingModal = () => setIsLoadingModalOpen(false);

  const [progress, setProgress] = useState(0);

  const { user } = useAuth();

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleCategoryChange = (value: string) => {
    setSelectedCategory(value);
  };

  const handleTypeChange = (value: string) => {
    setSelectedType(value);
  };

  const handleIdentificationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIdentification(e.target.value);
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.files[0].size < 15 * 1024 * 1024) {
        setIsLoadingModalOpen(true);

        const selectedFiles = Array.from(e.target.files);
        const fileName = selectedFiles.map((file) => file.name);

        const uploadedFileDto = {
          fileName: fileName,
          userId: user.id,
          uploadedBy: user.name,
          category: selectedCategory,
          fileType: selectedType,
          identifier: identification,
        };

        const pcpDto = {
          serviceType:
            formType === PurchasingContractingPlanFormType.EDIT_PURCHASING
              ? "PURCHASING"
              : "CONTRACTING",
          ContractingPlanId:
            formType === PurchasingContractingPlanFormType.EDIT_CONTRACTING
              ? contractingSelectedPlan?.id
              : "",
          contractingPhase:
            formType === PurchasingContractingPlanFormType.EDIT_CONTRACTING
              ? contractingSelectedPlan?.phase
              : null,
          PurchasingPlanId:
            formType === PurchasingContractingPlanFormType.EDIT_PURCHASING
              ? purchasingSelectedPlan?.id
              : "",
          purchasingPhase:
            formType === PurchasingContractingPlanFormType.EDIT_PURCHASING
              ? purchasingSelectedPlan?.phase
              : null,
          verticalServiceGroupId: groupId,
        };

        try {
          const response = await manageFilesService.uploadFiles(
            selectedFiles,
            uploadedFileDto,
            pcpDto,
            (progressEvent: ProgressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(progress); // Update progress state
            }
          );
          getUploadedFilesHandler();
          setIsLoadingModalOpen(false);
          setIsModalFilesOpen(true);
          setAlignment("listar");
        } catch (error) {
          console.error("Erro ao enviar arquivos:", error);
          setAlert({
            title: "Error",
            message: "Erro ao enviar arquivos",
            isOpened: true,
          });
        }
      } else {
        e.target.value = "";
        setAlert({
          title: "Error",
          message: "Tamanho máximo de 15 MB",
          isOpened: true,
        });
        return false;
      }
    }
  };

  const handleFilesDelete = async (id: number, type: string) => {
    try {
      await manageFilesService.deletePurcharsingContractingUploadedFiles(
        id,
        type
      );

      getUploadedFilesHandler();
    } catch (error) {
      console.error("Erro ao deletar o arquivo:", error);
    }
  };

  const getUploadedFilesHandler = async () => {
    const planId =
      formType === PurchasingContractingPlanFormType.EDIT_PURCHASING
        ? purchasingSelectedPlan?.id
        : contractingSelectedPlan?.id;
    const data =
      await manageFilesService.getPurcharsingContractingUploadedFiles(
        planId!,
        planType
      );

    setFilesList(data);
  };

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const handleResize = () => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    getUploadedFilesHandler();
  }, [purchasingSelectedPlan?.id || contractingSelectedPlan?.id]);

  useEffect(() => {}, []);

  const goToList = () => {
    navigation(PURCHASING_CONTRACTING_PLAN_PATH);
  };

  // create an event listener
  useEffect(() => {
    if (isMobile === undefined) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
  });

  useImperativeHandle(ref, () => ({
    cleanData: () => {
      cleanData();
    },
  }));

  useEffect(() => {
    // if (currentConstructionId === undefined) {
    //   setCurrentConstructionId(constructionId);
    // }

    try {
      setState(BaseState.loading);

      const id = searchParams.get("id");
      const type = searchParams.get("type");

      setFirstContructionId(constructionId);
      if (constructionId !== undefined && constructionId !== null) {
        setFirstContructionId(constructionId);
      }

      if (isConstructionIdChanged()) {
        goToList();
      }

      function isConstructionIdChanged() {
        return (
          constructionId !== undefined &&
          firstContructionId !== undefined &&
          constructionId !== firstContructionId
        );
      }

      loadPageData(id, type);
    } catch (error) {
      setState(BaseState.error);
    }
  }, [constructionId]);

  const cleanData = () => {};

  const loadPageData = async (id: string | null, type: string | null) => {
    const verticalServiceId = searchParams.get("verticalServiceId");
    let selectedPlan: PurchasingModel | ContractingModel | undefined;
    if (id && type) {
      selectedPlan = await findPlanById(Number(id), type || "");
    }

    let selectedVerticalService = undefined;

    if (verticalServiceId) {
      selectedVerticalService = await verticalService.getVerticalServiceById(
        constructionId,
        Number(verticalServiceId)
      );
      setSelectedServices([selectedVerticalService]);
    }

    findServiceTypes(
      constructionId,
      selectedPlan?.services[0]?.verticalServiceTypeId ||
        selectedVerticalService?.verticalServiceTypeId
    );
    findUserResponsibles(constructionId);

    setState(BaseState.success);
  };

  const findPlanById = async (
    id: number,
    type: string
  ): Promise<PurchasingModel | ContractingModel | undefined> => {
    try {
      if (id !== undefined && type === PurchasingContractingENUM.PURCHASING) {
        setFormType(PurchasingContractingPlanFormType.EDIT_PURCHASING);
        const response =
          await purchasingContractingService.getPurchasingPlanById(id);
        const model = parsePurchasingEntityToModel(response);
        setPurchasingSelectedPlan(model);
        setSelectedServices(model.services);
        setRequesterResponsible(model.requesterResponsable?.name || "");
        setCotationResponsible(model.supplierQuotatioResponsable?.name || "");
        setApprovalResponsible(model.approveResponsable?.name || "");
        setPurchasingResponsible(model.purchasingResponsable?.name || "");
        setApproveReceiptResponsible(model.finishPlanResponsable?.name || "");
        return model;
      }

      if (id !== undefined && type === PurchasingContractingENUM.CONTRACTING) {
        setFormType(PurchasingContractingPlanFormType.EDIT_CONTRACTING);
        const response =
          await purchasingContractingService.getContractingPlanById(id);
        const model = parseContractingEntityToModel(response);
        setContractingSelectedPlan(model);
        setSelectedServices(model.services);
        setContractingRequesterResponsible(
          model.requesterResponsable?.name || ""
        );
        setContractingCotationResponsible(
          model.supplierQuotatioResponsable?.name || ""
        );
        setContractingApprovalResponsible(model.approveResponsable?.name || "");
        setContractingPurchasingResponsible(
          model.purchasingResponsable?.name || ""
        );
        setContractingApproveReceiptResponsible(
          model.finishPlanResponsable?.name || ""
        );
        return model;
      }

      return undefined;
    } catch (error) {
      setState(BaseState.error);
      return undefined;
    }
  };

  const findServiceTypes = async (
    constructionId?: number,
    selectedServicesTypeId?: number
  ) => {
    const response = await verticalService.getServiceTypes(
      constructionId,
      false,
      true
    );
    setServicesTypes(response);

    if (selectedServicesTypeId) {
      response.find((serviceGroup) => {
        const service = serviceGroup.services.find(
          (serviceType: any) => serviceType.id === selectedServicesTypeId
        );

        if (service) {
          setGroupId(serviceGroup.id);
          setSelectedServiceType({
            group: serviceGroup.name,
            color: serviceGroup.color,
            id: service.id,
            type: service.name,
            addByUser: service.isAddByUser === true,
          });
        }
      });
    }
  };

  const findUserResponsibles = async (constructionId: number) => {
    userService
      .fetchResponsibleUsers(constructionId.toString())
      .then((data) => {
        setResponsibleUsers(data);
      });
  };

  const findVerticalServicesByServiceType = async (serviceTypeId: number) => {
    try {
      const response = await verticalService.findVerticalServicesByFilters(
        constructionId,
        serviceTypeId
      );
      setVerticalServices(response);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeSelectedService = (key: any, value: any) => {
    try {
      const serviceGroup = servicesTypes.find(
        (service) => service.name === key
      );
      const serviceType = serviceGroup.services.find(
        (service: any) => service.name === value
      );
      setSelectedServices([]);
      setVerticalServices([]);
      findVerticalServicesByServiceType(serviceType.id);
    } catch (error) {
      setSnackbarMessage("Erro ao selecionar o serviço");
      setOpenSnackbar(true);
    }
  };

  const onSelectedVerticalService = (
    verticalService: VerticalServiceModel[] | null
  ) => {
    if (!verticalService) {
      return;
    }

    verticalService.sort((a, b) => {
      if (!a.beginDate) {
        return 0;
      }

      if (!b.beginDate) {
        return 0;
      }

      if (a.beginDate < b.beginDate) {
        return -1;
      }
      if (a.beginDate > b.beginDate) {
        return 1;
      }

      return 0;
    });
    setSelectedServices(verticalService);
  };

  const handleSubmit = async (values: any) => {
    if (formType === PurchasingContractingPlanFormType.CREATE) {
      createNewPurchasingOrContractingPlan(values);
    } else {
      editPurchasingOrContractingPlan(values);
    }
  };

  async function createNewPurchasingOrContractingPlan(values: any) {
    try {
      setState(BaseState.loading);
      const ids: number[] = [];

      selectedServices.forEach((service) => {
        if (service.id !== undefined) {
          ids.push(service.id);
        }
      });

      if (values.selectedTab === PurchasingContractingENUM.PURCHASING) {
        const requesterResponsableId = responsibleUsers.find(
          (user: UserResponsible) => user.name === requesterResponsible
        )?.id;

        const supplierQuotatioResponsableId = responsibleUsers.find(
          (user: UserResponsible) => user.name === cotationResponsible
        )?.id;

        const approvalResponsibleId = responsibleUsers.find(
          (user: UserResponsible) => user.name === approvalResponsible
        )?.id;

        const purchasingResponsableId = responsibleUsers.find(
          (user: UserResponsible) => user.name === purchasingResponsible
        )?.id;

        const approveReceiptResponsibleId = responsibleUsers.find(
          (user: UserResponsible) => user.name === approveReceiptResponsible
        )?.id;

        await purchasingContractingService.savePurchasingPlan(constructionId, {
          name: values.description,
          purchasingItems: values.purchasingItems,
          supplierQuotationDays: values.purchasingSupplierQuotationDays,
          purchasingLimitDays: values.purchasingDays,
          deliveryLimitDays: values.purchasingDeliveryDays,
          safetyDays: values.purchasingSecurityDays,
          serviceIds: ids,
          requesterResponsableId: requesterResponsableId
            ? Number(requesterResponsableId)
            : undefined,
          supplierQuotatioResponsableId: supplierQuotatioResponsableId
            ? Number(supplierQuotatioResponsableId)
            : undefined,
          approveResponsableId: approvalResponsibleId
            ? Number(approvalResponsibleId)
            : undefined,
          purchasingResponsableId: purchasingResponsableId
            ? Number(purchasingResponsableId)
            : undefined,
          approveReceiptResponsibleId: approveReceiptResponsibleId
            ? Number(approveReceiptResponsibleId)
            : undefined,
          supplierIds: values.suppliers,
        });
      }

      if (values.selectedTab === PurchasingContractingENUM.CONTRACTING) {
        const requesterResponsableId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingRequesterResponsible
        )?.id;

        const supplierQuotatioResponsableId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingCotationResponsible
        )?.id;

        const approvalResponsibleId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingApprovalResponsible
        )?.id;

        const purchasingResponsableId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingPurchasingResponsible
        )?.id;

        const approveReceiptResponsibleId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingApproveReceiptResponsible
        )?.id;

        await purchasingContractingService.saveContractingPlan(constructionId, {
          name: values.description,
          contractingItems: values.contractingItems,
          // name: values.service,
          // cost: values.cost,
          // specifications: values.contractingSpecifications,
          supplierQuotationDays: values.contractingSupplierQuotationDays,
          contractingLimitDays: values.contractingDays,
          deliveryLimitDays: values.contractingDeliveryDays,
          safetyDays: values.contractingSecurityDays,
          serviceIds: ids,
          requesterResponsableId: requesterResponsableId
            ? Number(requesterResponsableId)
            : undefined,
          supplierQuotatioResponsableId: supplierQuotatioResponsableId
            ? Number(supplierQuotatioResponsableId)
            : undefined,
          approveResponsableId: approvalResponsibleId
            ? Number(approvalResponsibleId)
            : undefined,
          contractingResponsableId: purchasingResponsableId
            ? Number(purchasingResponsableId)
            : undefined,
          approveReceiptResponsibleId: approveReceiptResponsibleId
            ? Number(approveReceiptResponsibleId)
            : undefined,
          supplierIds: values.suppliers,
        });
      }
      setState(BaseState.success);
      setSnackbarMessage("Plano de compras e contratação salvo com sucesso");
      setOpenSnackbar(true);
      goToList();
    } catch (error) {
      // setState(BaseState.error);
      setState(BaseState.success);
      setSnackbarMessage("Erro ao salvar o plano de compras e contratação");
      setOpenSnackbar(true);
    }
  }

  async function editPurchasingOrContractingPlan(values: any) {
    console.log({ values })
    try {
      setState(BaseState.loading);
      const ids: number[] = [];

      selectedServices.forEach((service) => {
        if (service.id !== undefined) {
          ids.push(service.id);
        }
      });

      if (values.selectedTab === PurchasingContractingENUM.PURCHASING) {
        const requesterResponsableId = responsibleUsers.find(
          (user: UserResponsible) => user.name === requesterResponsible
        )?.id;

        const supplierQuotatioResponsableId = responsibleUsers.find(
          (user: UserResponsible) => user.name === cotationResponsible
        )?.id;

        const approvalResponsibleId = responsibleUsers.find(
          (user: UserResponsible) => user.name === approvalResponsible
        )?.id;

        const purchasingResponsableId = responsibleUsers.find(
          (user: UserResponsible) => user.name === purchasingResponsible
        )?.id;

        const approveReceiptResponsibleId = responsibleUsers.find(
          (user: UserResponsible) => user.name === approveReceiptResponsible
        )?.id;

        await purchasingContractingService.updatePurchasePlan(
          purchasingSelectedPlan?.id || 0,
          {
            name: values.description,
            purchasingItems: values.purchasingItems,
            supplierQuotationDays: values.purchasingSupplierQuotationDays,
            purchasingLimitDays: values.purchasingDays,
            deliveryLimitDays: values.purchasingDeliveryDays,
            safetyDays: values.purchasingSecurityDays,
            serviceIds: ids,
            requesterResponsableId: requesterResponsableId
              ? Number(requesterResponsableId)
              : undefined,
            supplierQuotatioResponsableId: supplierQuotatioResponsableId
              ? Number(supplierQuotatioResponsableId)
              : undefined,
            approveResponsableId: approvalResponsibleId
              ? Number(approvalResponsibleId)
              : undefined,
            purchasingResponsableId: purchasingResponsableId
              ? Number(purchasingResponsableId)
              : undefined,
            approveReceiptResponsibleId: approveReceiptResponsibleId
              ? Number(approveReceiptResponsibleId)
              : undefined,
            supplierIds: values.suppliers,
          }
        );
      }

      if (values.selectedTab === PurchasingContractingENUM.CONTRACTING) {
        const requesterResponsableId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingRequesterResponsible
        )?.id;

        const supplierQuotatioResponsableId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingCotationResponsible
        )?.id;

        const approvalResponsibleId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingApprovalResponsible
        )?.id;

        const purchasingResponsableId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingPurchasingResponsible
        )?.id;

        const approveReceiptResponsibleId = responsibleUsers.find(
          (user: UserResponsible) =>
            user.name === contractingApproveReceiptResponsible
        )?.id;

        await purchasingContractingService.updateContractingPlan(
          contractingSelectedPlan?.id || 0,
          {
            // name: values.service,
            // cost: values.cost,
            // specifications: values.contractingSpecifications,
            name: values.description,
            contractingItems: values.contractingItems,
            supplierQuotationDays: values.contractingSupplierQuotationDays,
            contractingLimitDays: values.contractingDays,
            deliveryLimitDays: values.contractingDeliveryDays,
            safetyDays: values.contractingSecurityDays,
            serviceIds: ids,
            requesterResponsableId: requesterResponsableId
              ? Number(requesterResponsableId)
              : undefined,
            supplierQuotatioResponsableId: supplierQuotatioResponsableId
              ? Number(supplierQuotatioResponsableId)
              : undefined,
            approveResponsableId: approvalResponsibleId
              ? Number(approvalResponsibleId)
              : undefined,
            contractingResponsableId: purchasingResponsableId
              ? Number(purchasingResponsableId)
              : undefined,
            approveReceiptResponsibleId: approveReceiptResponsibleId
              ? Number(approveReceiptResponsibleId)
              : undefined,
            supplierIds: values.suppliers,
          }
        );
      }

      const id = searchParams.get("id");
      await loadPageData(id, values.selectedTab);

      setState(BaseState.success);
      setSnackbarMessage("Plano de compras e contratação salvo com sucesso");
      setOpenSnackbar(true);
      goToList();
    } catch (error) {
      setState(BaseState.error);
      setSnackbarMessage("Erro ao salvar o plano de compras e contratação");
      setOpenSnackbar(true);
    }
  }

  const handleOpenSuppliersModal = () => {
    setIsSuppliersModalOpen(!isSuppliersModalOpen);
  };

  const cancelOnClick = () => {
    goToList();
  };

  const isButtonEnabled = (values: any): boolean => {
    if (values.selectedTab == PurchasingContractingENUM.CONTRACTING) {
      return ( selectedServices.length > 0 &&
        values.description !== "" &&
        values.contractingItems.length > 0 &&
        values.service !== "" &&
        values.contractingSpecifications !== "" &&
        contractingCotationResponsible !== "" &&
        contractingApprovalResponsible !== "" &&
        contractingPurchasingResponsible !== "" &&
        contractingApproveReceiptResponsible !== ""
      );
    } else {
      return ( selectedServices.length > 0 &&
        values.description !== "" &&
        values.purchasingItems.length > 0 &&
        cotationResponsible !== "" &&
        approvalResponsible !== "" &&
        purchasingResponsible !== "" &&
        approveReceiptResponsible !== ""
      );
    }
  };

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {state === BaseState.success && !isConstructionNotSelected() && (
          <Formik
            initialValues={{
              service: contractingSelectedPlan?.description || "",
              purchasingSupplierQuotationDays:
                purchasingSelectedPlan?.supplierQuotationDays || 0,
              purchasingDays: purchasingSelectedPlan?.purchasingLimitDays || 0,
              purchasingDeliveryDays:
                purchasingSelectedPlan?.deliveryLimitDays || 0,
              purchasingSecurityDays: purchasingSelectedPlan?.securityDays || 0,
              selectedTab:
                searchParams.get("type") === undefined ||
                searchParams.get("type") === null
                  ? PurchasingContractingENUM.PURCHASING
                  : searchParams.get("type") ===
                    PurchasingContractingENUM.PURCHASING
                  ? PurchasingContractingENUM.PURCHASING
                  : PurchasingContractingENUM.CONTRACTING,
              contractingSupplierQuotationDays:
                contractingSelectedPlan?.supplierQuotationDays || 0,
              contractingDays:
                contractingSelectedPlan?.purchasingLimitDays || 0,
              contractingDeliveryDays:
                contractingSelectedPlan?.deliveryLimitDays || 0,
              contractingSecurityDays:
                contractingSelectedPlan?.securityDays || 0,
              selectedServices: purchasingSelectedPlan?.services || [],
              suppliers: [],
              description: purchasingSelectedPlan?.name || contractingSelectedPlan?.name || "",
              purchasingItems: purchasingSelectedPlan?.purchasingItems || [],
              contractingItems: contractingSelectedPlan?.contractingItems || [],
            }}
            validationSchema={yupSchema}
            isInitialValid={true}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({ values, handleSubmit, setFieldValue, isValid, errors }) => {
              return (
              <ContentContainer>
                <Box
                  display={{ md: "flex" }}
                  alignItems="center"
                  gap="10px"
                  mb="40px"
                >
                  <ButtonComponent
                    text={"Voltar"}
                    type={ButtonType.TRANSPARENT}
                    startIcon={<ArrowBackSharp />}
                    onClick={() => cancelOnClick?.()}
                  />
                  <Title>Plano de Compras e Contratação</Title>
                </Box>
                <ChooseFormContainer>
                  <HeaderContainer isMobile={isMobile}>
                    <DialogTitle>Vincular serviços</DialogTitle>

                    <ButtonContainer>
                      {formType ===
                      (PurchasingContractingPlanFormType.EDIT_PURCHASING ||
                        PurchasingContractingPlanFormType.EDIT_CONTRACTING) ? (
                        <ButtonComponent
                          text="Anexar Documentos"
                          type={ButtonType.OUTLINELINK}
                          fullWidth={true}
                          onClick={() => toggleModal()}
                          startIcon={<AttachFileIcon />}
                          padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                        />
                      ) : (
                        <></>
                      )}

                      <ButtonComponent
                        text="Cadastrar Novo Serviço"
                        type={ButtonType.PRIMARY}
                        fullWidth={true}
                        onClick={() => navigation("/vertical-servicos")}
                        startIcon={<AddIcon />}
                        // endIcon={<Arow />}
                        padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                      />
                    </ButtonContainer>
                  </HeaderContainer>
                  {/* <FormDescriptionText>
                    {formType === PurchasingContractingPlanFormType.CREATE
                      ? "Selecione um serviço para vincular o novo plano "
                      : "Edite os serviços vinculados"}
                  </FormDescriptionText> */}
                  <ServiceFormContainer>
                    <CustomDropDownComponent
                      values={servicesTypes}
                      variant="filled"
                      key="CustomDropDownComponent"
                      onSelectedValueChanged={(key, value) =>
                        onChangeSelectedService(key, value)
                      }
                      selectedValue={selectedServiceType}
                      disabled={selectedServiceType !== undefined}
                    />

                    <Autocomplete
                      id="verticalService"
                      value={selectedServices}
                      options={verticalServices}
                      fullWidth
                      size="small"
                      multiple
                      getOptionLabel={(option) =>
                        typeof option === "string" ? option : option.location
                      }
                      renderOption={(props, verticalService) => (
                        <AutoCompleteCustomMenuItem
                          {...props}
                          key={verticalService.id}
                          autoFocus={false}
                          value={verticalService.location}
                          title={verticalService.location}
                        >
                          <Checkbox
                            checked={
                              selectedServices.find(
                                (service) => service.id === verticalService.id
                              ) !== undefined
                            }
                          />
                          <CustomMenuItemText>
                            {verticalService.location}
                          </CustomMenuItemText>
                        </AutoCompleteCustomMenuItem>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Localização"
                          variant="filled"
                          size="medium"
                          sx={textFieldStyle}
                          select={false}
                        />
                      )}
                      renderTags={(value, getTagProps) => {
                        return value
                          .slice(0, 1)
                          .map((option, index) => (
                            <SelectedTag {...getTagProps({ index })}>
                              {
                                <SelectedTagText>
                                  {`${value.length} serviços selecionado(s)`}
                                </SelectedTagText>
                              }
                            </SelectedTag>
                          ));
                      }}
                      disableCloseOnSelect
                      onChange={(event, value) =>
                        onSelectedVerticalService(value)
                      }
                      disableClearable
                    />

                    <InputDateComponent
                      label="Início"
                      variant="filled"
                      value={selectedServices[0]?.beginDate || undefined}
                      format="DD/MM/YYYY"
                      enable={false}
                      onChange={() => {}}
                    />

                    <InputDateComponent
                      label="Término"
                      variant="filled"
                      value={
                        selectedServices[selectedServices.length - 1]
                          ?.endDate || undefined
                      }
                      enable={false}
                      format="DD/MM/YYYY"
                      onChange={() => {}}
                    />

                    {/* <LocationFormContainer>
                      <Autocomplete
                        id="verticalService"
                        value={selectedServices}
                        options={verticalServices}
                        fullWidth
                        size="small"
                        multiple
                        getOptionLabel={(option) =>
                          typeof option === "string" ? option : option.location
                        }
                        renderOption={(props, verticalService) => (
                          <AutoCompleteCustomMenuItem
                            {...props}
                            key={verticalService.id}
                            autoFocus={false}
                            value={verticalService.location}
                            title={verticalService.location}
                          >
                            <Checkbox
                              checked={
                                selectedServices.find(
                                  (service) => service.id === verticalService.id
                                ) !== undefined
                              }
                            />
                            <CustomMenuItemText>
                              {verticalService.location}
                            </CustomMenuItemText>
                          </AutoCompleteCustomMenuItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Localização"
                            variant="filled"
                            size="medium"
                            sx={textFieldStyle}
                            select={false}
                          />
                        )}
                        renderTags={(value, getTagProps) => {
                          return value
                            .slice(0, 1)
                            .map((option, index) => (
                              <SelectedTag {...getTagProps({ index })}>
                                {
                                  <SelectedTagText>
                                    {`${value.length} serviços selecionado(s)`}
                                  </SelectedTagText>
                                }
                              </SelectedTag>
                            ));
                        }}
                        disableCloseOnSelect
                        onChange={(event, value) =>
                          onSelectedVerticalService(value)
                        }
                        disableClearable
                      />

                      {!isMobile && (
                        <InputDateComponent
                          label="Início"
                          variant="filled"
                          value={selectedServices[0]?.beginDate || undefined}
                          format="DD/MM/YYYY"
                          enable={false}
                          onChange={() => {}}
                        />
                      )}
                      {!isMobile && (
                        <InputDateComponent
                          label="Término"
                          variant="filled"
                          value={
                            selectedServices[selectedServices.length - 1]
                              ?.endDate || undefined
                          }
                          enable={false}
                          format="DD/MM/YYYY"
                          onChange={() => {}}
                        />
                      )}
                      {isMobile && (
                        <StartAndEndDateContainer>
                          <InputDateComponent
                            label="Início"
                            variant="filled"
                            value={selectedServices[0]?.beginDate || undefined}
                            format="DD/MM/YYYY"
                            enable={false}
                            onChange={() => {}}
                          />
                          <InputDateComponent
                            label="Término"
                            variant="filled"
                            value={
                              selectedServices[selectedServices.length - 1]
                                ?.endDate || undefined
                            }
                            enable={false}
                            format="DD/MM/YYYY"
                            onChange={() => {}}
                          />
                        </StartAndEndDateContainer>
                      )}
                    </LocationFormContainer> */}
                  </ServiceFormContainer>
                </ChooseFormContainer>
                <PurchasingAndContractionContainer>
                  <GroupedHeaderContainer isMobile={isMobile}>
                    {/* <DialogTitle>
                      {formType === PurchasingContractingPlanFormType.CREATE
                        ? "Plano de Compras e Contratação"
                        : formType ===
                          PurchasingContractingPlanFormType.EDIT_PURCHASING
                        ? "Compras"
                        : "Contratações"}
                    </DialogTitle> */}
                    <DialogTitle>
                      {formType === PurchasingContractingPlanFormType.CREATE
                        ? "Plano de Compras e Contratação"
                        : formType ===
                          PurchasingContractingPlanFormType.EDIT_PURCHASING
                        ? "Compras"
                        : "Contratações"}
                    </DialogTitle>

                    {formType ===
                    (PurchasingContractingPlanFormType.EDIT_PURCHASING ||
                      PurchasingContractingPlanFormType.EDIT_CONTRACTING) ? (
                      <ButtonComponent
                        text={`Há ${filesList.length} arquivos adicionados`}
                        type={ButtonType.TRANSPARENT}
                        startIcon={<FolderOutlinedIcon />}
                        onClick={() => {
                          setAlignment("listar");
                          toggleModal();
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </GroupedHeaderContainer>
                  {formType === PurchasingContractingPlanFormType.CREATE && (
                    <TabContainer>
                      <Tabs
                        value={values.selectedTab}
                        onChange={(event, value) =>
                          setFieldValue("selectedTab", value)
                        }
                        style={{ width: "100%" }}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: Colors.primaryColorDark,
                            height: "4px",
                          },
                        }}
                      >
                        <CustomTab
                          value={PurchasingContractingENUM.PURCHASING}
                          label="Compras"
                        />
                        <CustomTab
                          value={PurchasingContractingENUM.CONTRACTING}
                          label="Contratação"
                        />
                      </Tabs>
                    </TabContainer>
                  )}

                  {values.selectedTab ===
                    PurchasingContractingENUM.PURCHASING && (
                    <PurchasingForm>
                      {/* <PurchasingFormRowOne>
                        <Field
                          name="product"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Produto"
                                value={values.product}
                                onChange={(value: string) => {
                                  setFieldValue("product", value);
                                }}
                              />
                            );
                          }}
                        />

                        <Field
                          name="quantity"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Quantidade"
                                type="number"
                                mask=""
                                value={values.quantity}
                                onChange={(value: string) => {
                                  let newValue = Number.parseFloat(value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  setFieldValue("quantity", newValue);
                                }}
                              />
                            );
                          }}
                        />

                        <Field
                          name="unit"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Unidade"
                                value={values.unit}
                                onChange={(value: string) => {
                                  setFieldValue("unit", value);
                                }}
                              />
                            );
                          }}
                        />

                        {formType !==
                          PurchasingContractingPlanFormType.CREATE && (
                          <Field
                            name="cost"
                            render={() => {
                              return (
                                <InputValueComponent
                                  isDisabled
                                  variant="filled"
                                  label="Custo (R$)"
                                  type="number"
                                  value={String(values.cost)}
                                  onChange={(value: string) => {
                                    let newValue = Number.parseFloat(value);
                                    if (isNaN(newValue)) {
                                      newValue = 0;
                                    }
                                    setFieldValue("cost", newValue);
                                  }}
                                />
                              );
                            }}
                          />
                        )}
                      </PurchasingFormRowOne> */}
                      {/* <Field
                        name="specifications"
                        render={() => {
                          return (
                            <InputValueComponent
                              variant="filled"
                              label="Especificações"
                              value={values.purchasingSpecifications}
                              onChange={(value: string) => {
                                setFieldValue(
                                  "purchasingSpecifications",
                                  value
                                );
                              }}
                            />
                          )}}
                        /> */}
                      <FormPurchasingItems
                        description={values.description}
                        items={values.purchasingItems}
                        onChange={(items, description) => {
                          setFieldValue("purchasingItems", items)
                          setFieldValue("description", description)
                        }}
                      />
                      <BuilderAndSupplierContainer>
                        <BuilderAndSupplierContainerDescription>
                          Construtora
                        </BuilderAndSupplierContainerDescription>
                        <Field
                          name="supplierQuotationDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Cotação (dias)"
                                value={
                                  values.purchasingSupplierQuotationDays?.toString() ||
                                  ""
                                }
                                type="number"
                                onChange={(value: string) => {
                                  let newValue = Number.parseFloat(value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  setFieldValue(
                                    "purchasingSupplierQuotationDays",
                                    newValue
                                  );
                                }}
                              />
                            );
                          }}
                        />
                        <Field
                          name="contractingDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Compra (dias)"
                                value={values.purchasingDays?.toString() || ""}
                                type="number"
                                onChange={(value: string) => {
                                  let newValue = Number.parseFloat(value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  setFieldValue("purchasingDays", newValue);
                                }}
                              />
                            );
                          }}
                        />
                        <BuilderAndSupplierContainerDescription>
                          Fornecedor
                        </BuilderAndSupplierContainerDescription>
                        <Field
                          name="deliveryDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Fornecimento (dias)"
                                type="number"
                                value={
                                  values.purchasingDeliveryDays?.toString() ||
                                  ""
                                }
                                onChange={(value: string) => {
                                  let newValue = Number.parseFloat(value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  setFieldValue(
                                    "purchasingDeliveryDays",
                                    newValue
                                  );
                                }}
                              />
                            );
                          }}
                        />
                        <Field
                          name="securityDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Margem de segurança (dias)"
                                type="number"
                                value={
                                  values.purchasingSecurityDays?.toString() ||
                                  ""
                                }
                                onChange={(value: string) => {
                                  let newValue = Number.parseFloat(value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  setFieldValue(
                                    "purchasingSecurityDays",
                                    newValue
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </BuilderAndSupplierContainer>
                    </PurchasingForm>
                  )}

                  {values.selectedTab ===
                    PurchasingContractingENUM.CONTRACTING && (
                    <ContractingForm>
                      {/* <ContractingFormRow>
                        <Field
                          name="specifications"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Serviço"
                                value={values.service}
                                onChange={(value: string) => {
                                  setFieldValue("service", value);
                                }}
                              />
                            );
                          }}
                        />
                        {formType !==
                          PurchasingContractingPlanFormType.CREATE && (
                          <Field
                            name="cost"
                            render={() => {
                              return (
                                <InputValueComponent
                                  isDisabled
                                  variant="filled"
                                  label="Custo (R$)"
                                  type="number"
                                  value={String(values.cost)}
                                  onChange={(value: string) => {
                                    let newValue = Number.parseFloat(value);
                                    if (isNaN(newValue)) {
                                      newValue = 0;
                                    }
                                    setFieldValue("cost", newValue);
                                  }}
                                />
                              );
                            }}
                          />
                        )}
                      </ContractingFormRow>
                      <Field
                        name="specifications"
                        render={() => {
                          return (
                            <InputValueComponent
                              variant="filled"
                              label="Especificações"
                              value={values.contractingSpecifications}
                              onChange={(value: string) => {
                                setFieldValue(
                                  "contractingSpecifications",
                                  value
                                );
                              }}
                            />
                          )}}
                      /> */}
                      <FormContractingItems
                        description={values.description}
                        items={values.contractingItems}
                        onChange={(items, description) => {
                          setFieldValue("contractingItems", items)
                          setFieldValue("description", description)
                        }}
                      />
                      <BuilderAndSupplierContainer>
                        <BuilderAndSupplierContainerDescription>
                          Construtora
                        </BuilderAndSupplierContainerDescription>
                        <Field
                          name="supplierQuotationDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Cotação (dias)"
                                value={
                                  values.contractingSupplierQuotationDays.toString() ||
                                  ""
                                }
                                onChange={(value: string) => {
                                  setFieldValue(
                                    "contractingSupplierQuotationDays",
                                    value
                                  );
                                }}
                              />
                            );
                          }}
                        />
                        <Field
                          name="contractingDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Contratação (dias)"
                                value={values.contractingDays?.toString() || ""}
                                onChange={(value: string) => {
                                  setFieldValue("contractingDays", value);
                                }}
                              />
                            );
                          }}
                        />
                        <BuilderAndSupplierContainerDescription>
                          Fornecedor
                        </BuilderAndSupplierContainerDescription>
                        <Field
                          name="deliveryDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Fornecimento (dias)"
                                value={
                                  values.contractingDeliveryDays?.toString() ||
                                  ""
                                }
                                onChange={(value: string) => {
                                  setFieldValue(
                                    "contractingDeliveryDays",
                                    value
                                  );
                                }}
                              />
                            );
                          }}
                        />
                        <Field
                          name="securityDays"
                          render={() => {
                            return (
                              <InputValueComponent
                                variant="filled"
                                label="Margem de segurança (dias)"
                                value={
                                  values.contractingSecurityDays?.toString() ||
                                  ""
                                }
                                onChange={(value: string) => {
                                  setFieldValue(
                                    "contractingSecurityDays",
                                    value
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </BuilderAndSupplierContainer>
                    </ContractingForm>
                  )}
                </PurchasingAndContractionContainer>

                {/* <FilesInputContainer>
                  <FilesInput>
                    <Typography fontSize={16}>
                      Arraste seu arquivo aqui
                    </Typography>
                    <Typography fontSize={16}>Ou</Typography>
                    <ButtonComponent
                      text={"Busque seu Arquivo Aqui"}
                      onClick={() => {}}
                    />
                  </FilesInput>
                </FilesInputContainer> */}

                <ResponsibleContainer>
                  <ResponsibleContainerTitle>
                    Responsáveis por Etapa
                  </ResponsibleContainerTitle>
                  <ResponsibleForm>
                    <DropDownComponent
                      values={responsibleUsers.map((user) => user.name)}
                      key="1"
                      componentKey="requester"
                      variant="filled"
                      selectedValue={
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? requesterResponsible
                          : contractingRequesterResponsible
                      }
                      label="Requisitante"
                      defaultValue="Requisitante"
                      onSelectedValueChanged={(key: any, value: any) => {
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? setRequesterResponsible(value)
                          : setContractingRequesterResponsible(value);
                      }}
                    />

                    <DropDownComponent
                      values={responsibleUsers.map((user) => user.name)}
                      key="1"
                      componentKey="cotation"
                      variant="filled"
                      selectedValue={
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? cotationResponsible
                          : contractingCotationResponsible
                      }
                      label="Cotação"
                      defaultValue="Cotação"
                      onSelectedValueChanged={(key: any, value: any) => {
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? setCotationResponsible(value)
                          : setContractingCotationResponsible(value);
                      }}
                    />

                    <DropDownComponent
                      values={responsibleUsers.map((user) => user.name)}
                      key="1"
                      componentKey="approval"
                      variant="filled"
                      label="Aprovar Pedido"
                      defaultValue="Aprovar Pedido"
                      selectedValue={
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? approvalResponsible
                          : contractingApprovalResponsible
                      }
                      onSelectedValueChanged={(key: any, value: any) => {
                        values.selectedTab ===
                        PurchasingContractingENUM.PURCHASING
                          ? setApprovalResponsible(value)
                          : setContractingApprovalResponsible(value);
                      }}
                    />
                    <ResponsibleFormRow>
                      <DropDownComponent
                        values={responsibleUsers.map((user) => user.name)}
                        key="1"
                        componentKey="purchasing"
                        variant="filled"
                        selectedValue={
                          values.selectedTab ===
                          PurchasingContractingENUM.PURCHASING
                            ? purchasingResponsible
                            : contractingPurchasingResponsible
                        }
                        label={
                          values.selectedTab ===
                          PurchasingContractingENUM.PURCHASING
                            ? "Fazer compra"
                            : "Contratar serviço"
                        }
                        defaultValue={
                          values.selectedTab ===
                          PurchasingContractingENUM.PURCHASING
                            ? "Fazer compra"
                            : "Contratar serviço"
                        }
                        onSelectedValueChanged={(key: any, value: any) => {
                          values.selectedTab ===
                          PurchasingContractingENUM.PURCHASING
                            ? setPurchasingResponsible(value)
                            : setContractingPurchasingResponsible(value);
                        }}
                      />

                      <DropDownComponent
                        values={responsibleUsers.map((user) => user.name)}
                        key="1"
                        componentKey="approveReceipt"
                        variant="filled"
                        label={
                          values.selectedTab ===
                          PurchasingContractingENUM.PURCHASING
                            ? "Aprovar recebimento"
                            : "Aprovar contratado"
                        }
                        defaultValue={
                          values.selectedTab ===
                          PurchasingContractingENUM.PURCHASING
                            ? "Aprovar recebimento"
                            : "Aprovar contratado"
                        }
                        selectedValue={
                          values.selectedTab ===
                          PurchasingContractingENUM.PURCHASING
                            ? approveReceiptResponsible
                            : contractingApproveReceiptResponsible
                        }
                        onSelectedValueChanged={(key: any, value: any) => {
                          values.selectedTab ===
                          PurchasingContractingENUM.PURCHASING
                            ? setApproveReceiptResponsible(value)
                            : setContractingApproveReceiptResponsible(value);
                        }}
                      />
                    </ResponsibleFormRow>
                  </ResponsibleForm>
                </ResponsibleContainer>
                <ButtonContainerWrap>
                  <ButtonContainerStart>
                    <ButtonComponent
                      startIcon={<CheckIcon />}
                      type={ButtonType.APPROVED}
                      text={"Salvar pedido de Cotação"}
                      onClick={() => handleSubmit()}
                      fullWidth={true}
                      padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                      state={
                        isButtonEnabled(values)
                          ? ButtonState.DEFAULT_ENABLED
                          : ButtonState.DISABLED
                      }
                    />
                  </ButtonContainerStart>

                  <ButtonContainerEnd>
                    <ButtonComponent
                      // startIcon={<ClearSharp />}
                      text={"Cancelar"}
                      type={ButtonType.TRANSPARENT}
                      onClick={() => cancelOnClick?.()}
                    />
                    {/*<ButtonComponent*/}
                    {/*  startIcon={<CheckIcon />}*/}
                    {/*  type={ButtonType.OUTLINELINK}*/}
                    {/*  text={"Conferir Pedido"}*/}
                    {/*  onClick={() => { console.log('TODO!')}}*/}
                    {/*  padding={{ left: 24, right: 24, top: 10, bottom: 10 }}*/}
                    {/*/>*/}

                    <ButtonComponent
                      startIcon={<SendOutlinedIcon />}
                      type={ButtonType.PRIMARY}
                      state={
                        isButtonEnabled(values)
                          ? ButtonState.DEFAULT_ENABLED
                          : ButtonState.DISABLED
                      }
                      fullWidth={true}
                      text={"Enviar Pedido de Cotação"}
                      onClick={handleOpenSuppliersModal}
                      padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
                    />
                  </ButtonContainerEnd>
                </ButtonContainerWrap>

                <Modal open={isModalOpen} onClose={closeModal} keepMounted>
                  <ModalContent>
                    <ModalHeader>
                      <ModalTitle>Documentos</ModalTitle>
                      <CloseButton onClick={closeModal}>X</CloseButton>
                    </ModalHeader>

                    <ToggleButtonGroup
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      fullWidth
                    >
                      <ModalToggleButton value="anexar">
                        {alignment === "anexar" && (
                          <CheckIcon
                            fontSize="inherit"
                            style={{ marginRight: "8px" }}
                          />
                        )}
                        Anexar Arquivo
                      </ModalToggleButton>
                      <ModalToggleButton value="listar">
                        {alignment === "listar" && (
                          <CheckIcon
                            fontSize="inherit"
                            style={{ marginRight: "8px" }}
                          />
                        )}
                        Lista de Arquivos
                      </ModalToggleButton>
                    </ToggleButtonGroup>

                    {alignment === "anexar" && (
                      <ModalForm>
                        <ModalFormRow>
                          <DropDownComponent
                            values={fileCategories.map(
                              (category) => category.name
                            )}
                            key="1"
                            componentKey="category"
                            variant="filled"
                            sx={modalSelectStyle}
                            selectedValue={selectedCategory}
                            label={"Categoria"}
                            defaultValue={"Categoria"}
                            onSelectedValueChanged={(key: any, value: any) => {
                              handleCategoryChange(value);
                            }}
                          />
                        </ModalFormRow>

                        <ModalFormRow>
                          <DropDownComponent
                            values={
                              fileCategories
                                .filter(
                                  (category) =>
                                    category.name === selectedCategory
                                )[0]
                                ?.subCategories.map((sub) => sub.name) || []
                            }
                            key="1"
                            componentKey="type"
                            variant="filled"
                            sx={modalSelectStyle}
                            selectedValue={selectedType}
                            label={"Tipo"}
                            defaultValue={"Tipo"}
                            onSelectedValueChanged={(key: any, value: any) => {
                              handleTypeChange(value);
                            }}
                          />
                        </ModalFormRow>

                        <ModalFormRow>
                          <TextField
                            // helperText="Ex: Número da Nota, título do arquivo, etc"
                            label="Identificação (Número da Nota, título do arquivo, etc)"
                            variant="filled"
                            size="medium"
                            sx={modalTextFieldStyle}
                            select={false}
                            onChange={handleIdentificationChange}
                          />
                          <FormHelperText>
                            Selecione um arquivo PDF de até 15 MB
                          </FormHelperText>
                        </ModalFormRow>
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: "none" }}
                          accept=".pdf"
                          onChange={handleFileUpload}
                        />
                      </ModalForm>
                    )}

                    {alignment === "listar" && (
                      <ListFilesContainer>
                        {filesList
                          .slice()
                          .reverse()
                          .map((file: FileProps, idx) => (
                            <FileItem key={idx}>
                              <InsertDriveFileOutlinedIcon fontSize="small" />
                              <FileItemText>{file.fileName}</FileItemText>
                              <DeleteOutlineRoundedIcon
                                fontSize="small"
                                onClick={() => {
                                  handleFilesDelete(file.id, planType);
                                }}
                              />
                            </FileItem>
                          ))}
                      </ListFilesContainer>
                    )}

                    <ModalButtonRow>
                      <ButtonComponent
                        text="Buscar Arquivo"
                        type={ButtonType.PRIMARY}
                        state={
                          selectedCategory === "" ||
                          selectedType === "" ||
                          identification === ""
                            ? ButtonState.DISABLED
                            : ButtonState.DEFAULT_ENABLED
                        }
                        onClick={() => {
                          if (alignment === "listar") {
                            setAlignment("anexar");
                          } else {
                            const fileInput = document.getElementById(
                              "fileInput"
                            ) as HTMLInputElement | null;
                            if (fileInput) {
                              fileInput.click();
                            }
                          }
                        }}
                        startIcon={<SearchIcon />}
                        padding={{
                          left: 24,
                          right: 24,
                          top: 10,
                          bottom: 10,
                        }}
                      />
                    </ModalButtonRow>
                  </ModalContent>
                </Modal>

                <LoadingModal
                  isModalOpen={isLoadingModalOpen}
                  closeModal={closeLoadingModal}
                  progress={progress}
                />

                <PurchasingAndContractingListUploadSuccessModal
                  isModalFilesOpen={isModalFilesOpen}
                  closeFilesModal={closeFilesModal}
                  text="Documentos anexados com sucesso!"
                />
                <Footer />
                {isSuppliersModalOpen ? (
                  <PurchasingAndContractingSuppliersModal
                    toggleModal={handleOpenSuppliersModal}
                    open={isSuppliersModalOpen}
                    handleSubmit={(suppliers: number[]) => {
                      setFieldValue("suppliers", suppliers);
                      setTimeout(() => {
                        handleSubmit();
                      }, 100);
                    }}
                  />
                ) : null}
              </ContentContainer>
            )}}
          </Formik>
        )}

        {isConstructionNotSelected() ? (
          <CenterContainer>
            <EmptyClientAndConstructionState pageDescription="o plano de compras e contratação" />{" "}
          </CenterContainer>
        ) : null}

        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <CenterContainer>
            <LoadingComponent />
          </CenterContainer>
        ) : null}

        {state === BaseState.error ? (
          <CenterContainer>
            <ErrorComponent />
          </CenterContainer>
        ) : null}

        <Snackbar
          message={snackbarMessage}
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={() => {
            setOpenSnackbar(false);
          }}
        />
        <Alert
          onClose={() => setAlert({ title: "", message: "", isOpened: false })}
          isOpened={alert.isOpened}
          title={alert.title}
          message={alert.message}
          buttonLabel="Sair"
        />
      </PageContainer>
    </ReactPageBaseComponent>
  );
});

export default PurchasingAndContractingPlanForm;
