import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../../../theme/v2/variants";
import { IconButton } from "@mui/material";
import {
  ArrowDropUp,
  ArrowDropDown,
  DeleteSweepOutlined,
  Save as SaveIcon,
  Add as AddIcon,
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon
} from "@mui/icons-material";
import ButtonComponent, { ButtonState, ButtonType } from "../../../../components/v2/Button/ButtonComponent";
import { PurchasingItem } from "../../models/PurchasingModel"
import InputValueComponent from "../../../../components/InputValues/InputValueComponent";
import { Field } from "formik";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmDialog";
import { MOBILE_BREAKPOINT } from "../../../../utils/mobileDimen";


type FormPurchasingItemsProps = {
  description: string;
  items: PurchasingItem[];
  onChange: (items: PurchasingItem[], description: string) => void
}

const FormPurchasingItems = (
  { description: descriptionName, items, onChange }: FormPurchasingItemsProps
) => {
  const [listItems, setListItems] = useState<PurchasingItem[]>(items)
  const [description, setDescription] = useState(descriptionName)
  const [product, setProduct] = useState("")
  const [quantity, setQuantity] = useState(0)
  const [unit, setUnit] = useState("")
  const [specifications, setSpecifications] = useState("")
  const [hideList, setHideList] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [selectedEditingItemIdx, setSelectedEditingItemIdx] = useState<any>(null)
  const [selectedDeletingItemIdx, setSelectedDeletingItemIdx] = useState<any>(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeletingAll, setIsDeletingAll] = useState(false)

  const isButtonEnabled = product && quantity && unit && specifications

  useEffect(() => {
    onChange(listItems, description)
  }, [listItems, description]);

  const resetItemForm = () => {
    setProduct("")
    setQuantity(0)
    setUnit("")
    setSpecifications("")
  }

  const addItem = () => {
    const newItem: PurchasingItem = {
      name: product,
      quantity,
      unit,
      specifications,
      cost: 0
    }

    setListItems([...listItems, newItem])
    resetItemForm()
  }

  const updateItem = () => {
    const editedItem: PurchasingItem = {
      name: product,
      quantity: quantity,
      unit: unit,
      specifications: specifications,
      cost: listItems[selectedEditingItemIdx].cost
    }

    const newListItems = [
      ...listItems.slice(0,selectedEditingItemIdx),
      editedItem,
      ...listItems.slice(selectedEditingItemIdx + 1, listItems.length)
    ]

    setIsEditing(false)
    setSelectedEditingItemIdx(null)
    setListItems([...newListItems])

    resetItemForm()
  }

  const onEditItem = (item:PurchasingItem, idx: number) => {
    setIsEditing(true)
    setSelectedEditingItemIdx(idx)
    setProduct(item.name)
    setQuantity(item.quantity)
    setUnit(item.unit)
    setSpecifications(item.specifications)
  }

  const removeItem = (idx: number) => {
    const newListItems = [...listItems.slice(0,idx), ...listItems.slice(idx + 1, listItems.length)]

    setListItems([...newListItems])

    setSelectedDeletingItemIdx(null)
    setIsDeleting(false)
  }

  const removeAll = () => {
    setListItems([])
    setIsDeletingAll(false)
  }

  const onDeleteItem = (idx: number) => {
    setSelectedDeletingItemIdx(idx)
    setTimeout(() => {
      setIsDeleting(true)
    }, 100)
  }

  const onDeleteAll = () => {
    setIsDeletingAll(true)
  }

  return (
    <>
      <Container>
        <Fields>
          <FieldRowOne>
            <Field
              name="description"
              render={() => {
                return (
                  <InputValueComponent
                    variant="filled"
                    label="Descrição"
                    value={description}
                    onChange={(value: string) => {
                      setDescription(value);
                    }}
                  />
                );
              }}
            />
          </FieldRowOne>
          <FieldRowTwo>
            <Field
              name="product"
              render={() => {
                return (
                  <InputValueComponent
                    variant="filled"
                    label="Produto"
                    value={product}
                    onChange={(value: string) => {
                      setProduct(value);
                    }}
                  />
                );
              }}
            />
            <Field
              name="quantity"
              render={() => {
                return (
                  <InputValueComponent
                    variant="filled"
                    type="number"
                    mask=""
                    label="Quantidade"
                    value={quantity || ''}
                    onChange={(value: string) => {
                      let newValue = Number.parseFloat(value);
                      if (isNaN(newValue)) {
                        newValue = 0;
                      }
                      setQuantity(newValue);
                    }}
                  />
                );
              }}
            />
            <Field
              name="unit"
              render={() => {
                return (
                  <InputValueComponent
                    variant="filled"
                    label="Unidade medida"
                    value={unit}
                    onChange={(value: string) => {
                      setUnit(value);
                    }}
                  />
                );
              }}
            />
            <Field
              name="specifications"
              render={() => {
                return (
                  <InputValueComponent
                    variant="filled"
                    label="Especificação"
                    value={specifications}
                    onChange={(value: string) => {
                      setSpecifications(value);
                    }}
                  />
                );
              }}
            />
          </FieldRowTwo>
        </Fields>
        <ButtonContainerEnd>
          { isEditing ? (
            <ButtonComponent
              type={ButtonType.PRIMARY}
              startIcon={<SaveIcon />}
              text={"Atualizar Item"}
              onClick={updateItem}
              padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
              state={isButtonEnabled ? ButtonState.DEFAULT_ENABLED : ButtonState.DISABLED}
            />
          ) : (
            <ButtonComponent
              type={ButtonType.PRIMARY}
              startIcon={<AddIcon />}
              text={"Adicionar Item"}
              onClick={addItem}
              padding={{ left: 24, right: 24, top: 10, bottom: 10 }}
              state={isButtonEnabled ? ButtonState.DEFAULT_ENABLED : ButtonState.DISABLED}
            />
          )}
        </ButtonContainerEnd>
        <ItemsContainer>
          <ItemsHeader hide={hideList}>
            <IconButton
              onClick={() => setHideList(!hideList)}
            >
              {
                hideList ? (
                  <ArrowDropDown sx={{ color: 'white' }} />
                ) : (
                  <ArrowDropUp sx={{ color: 'white' }} />
                )
              }
            </IconButton>
            <ItemHeaderDescription>
              <ItemHeaderDescriptionLabel>Descrição</ItemHeaderDescriptionLabel>
              <ItemHeaderDescriptionValue>{ description }</ItemHeaderDescriptionValue>
            </ItemHeaderDescription>
            <ButtonComponent
              text='Remover Lista'
              type={ButtonType.FULL_TRANSPARENT}
              startIcon={<DeleteSweepOutlined />}
              color={'white'}
              onClick={onDeleteAll}
            />
          </ItemsHeader>
          <ItemsList hide={hideList}>
            <ItemSubHeader>
              <div>Produto</div>
              <div>Quantidade</div>
              <div>Unid Medida</div>
              <div>Especificações</div>
              <div>Ações</div>
            </ItemSubHeader>
            {
              listItems.map((item: any, index: number) => {
                const odd = index % 2
                return (
                  <ItemContainer odd={odd > 0} key={`${index}-${item.name}`}>
                    <ItemField
                      limitText
                      title={item.name}
                    >
                      { item.name }
                    </ItemField>
                    <ItemField
                      limitText
                      title={item.name}
                    >
                      { item.quantity }
                    </ItemField>
                    <ItemField
                      limitText
                      title={item.name}
                    >
                      { item.unit }
                    </ItemField>
                    <ItemField
                      limitText
                      title={item.name}
                    >
                      { item.specifications }
                    </ItemField>
                    {/* <ItemField
                      limitText
                      title={item.name}
                    >
                      { item.cost }
                    </ItemField> */}
                    <ItemField>
                      <IconButton
                        onClick={() => onEditItem(item, index)}
                      >
                        <EditIcon sx={{ color: Colors.greenLight }} />
                      </IconButton>
                      <IconButton
                      onClick={() => onDeleteItem(index)}>
                        <DeleteIcon sx={{ color: Colors.greenLight }} />
                      </IconButton>
                    </ItemField>
                  </ItemContainer>
                )
              })
            }
          </ItemsList>

        </ItemsContainer>
      </Container>
      <ConfirmDialog
        open={isDeleting}
        title={'Confirmação'}
        onConfirm={() => removeItem(selectedDeletingItemIdx)}
        onClose={() => setIsDeleting(false)}
      >
        {
          (selectedDeletingItemIdx !== null && listItems.length) ? (
            <p>Deseja remover o
              item? <b><ItemField>{listItems[selectedDeletingItemIdx].name.slice(0, 50)} {listItems[selectedDeletingItemIdx].name.length > 50 ? "..." : ""}</ItemField></b>
            </p>
          ) : null
        }
      </ConfirmDialog>

      <ConfirmDialog
        open={isDeletingAll}
        title={'Confirmação'}
        onConfirm={removeAll}
        onClose={() => setIsDeletingAll(false)}
      >
        <p>Deseja remover o os item na lista?</p>
      </ConfirmDialog>
    </>
  )
}

const Container = styled.div`
    /* display: grid;
    grid-row-gap: 24px; */
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 60px;
`;

const ItemsContainer = styled.div`
  display: block;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.2);
  border-radius: 8px;
  overflow: hidden;
`

const ItemsHeader = styled.div<{ hide?: boolean }>`
  display: flex;
  height: 60px;
  background-color: ${Colors.gullGray};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  padding: 0 16px;
  border-bottom-left-radius: ${({ hide }) => (hide ? '8px' : '0')};
  border-bottom-right-radius: ${({ hide }) => (hide ? '8px' : '0')};
`

const ItemHeaderDescription = styled.div`
  flex: 1;
  color: white;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
`

const ItemHeaderDescriptionLabel = styled.span`
  
`

const ItemHeaderDescriptionValue = styled.span`
  font-weight: bold;
`

const ItemsList = styled.div<{ hide?: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'grid')};
  overflow-x: scroll;
`

const ItemSubHeader = styled.div`
  display: grid;
    grid-template-columns: 3fr 2fr 2fr 6fr 1fr 1fr;
  background-color: ${Colors.periwinkleGray};
  height: 54px;
  align-content: center;
  padding: 0 16px;
  
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(5, minmax(135px, 1fr));
  }
`

const ItemContainer = styled.div<{ odd?: boolean }>`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 6fr 1fr 1fr;
  background-color: ${({ odd }) => (odd ? Colors.athensGray : 'white')};
  height: 54px;
  align-content: center;
  padding: 0 16px;
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(5, minmax(135px, 1fr));
  }
`

const ItemField = styled.span<{ limitText?: boolean }>`
  align-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ${({ limitText }) => (limitText ? 'ellipsis' : 'unset')};
  padding-right: 30px;  
`

const Fields = styled.div`
  display: grid;
  /* grid-template-rows: max-content max-content max-content max-content;*/
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-top: 24px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    /* grid-column: auto !important;
    grid-template-columns: 1fr; */
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`

const FieldRowOne = styled.div`
  display: grid;
  grid-column: span 4;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-column: auto;
    grid-template-columns: 1fr;
    gap: 16px;
  }
`

const FieldRowTwo = styled.div`
  display: grid;
  grid-column: span 4;
  grid-template-columns: 2fr 1fr 1fr 3fr;
  gap: 24px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-column: auto;
    grid-template-columns: 1fr;
    gap: 16px;
  }
`

const ButtonContainerEnd = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  /* margin-top: -48px; */
`;

export default FormPurchasingItems;