export const selectStyle = {
  "& .MuiSelect-root": { backgroundColor: "white" },
  "& .MuiSelect-root.Mui-focused": {
    backgroundColor: "white",
  },
  "& .MuiSelect-select": {
    fontSize: "16px",
    color: "#3B425A",
  },
  "& .MuiInputLabel-root": {
    fontSize: "16px",
    color: "#3B425A",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#2DB6A3",
  },
  "& .MuiInputLabel-shrink": {
    fontSize: "14px",
  },
  "& .MuiSelect-select::placeholder": {
    fontSize: "16px",
  },
};