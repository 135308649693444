import { Button } from "@mui/material";
import { Colors } from "../../theme/variants";
import { useState } from "react";

interface ButtonComponentProps {
  onClick?: (text: String, event?: any) => void;
  type?: ButtonType;
  size?: ButtonSize;
  state?: ButtonState;
  text: string;
  isPressed?: boolean;
  children?: any;
  startIcon?: any;
  endIcon?: any;
  padding?: { left: number; right: number; top: number; bottom: number };
  color?: string;
  fullWidth?: boolean;
}

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  DANGER,
  TRANSPARENT,
  OUTLINE,
}

export enum ButtonSize {
  SMALL,
  MEDIUM,
  LARGE,
}

export enum ButtonState {
  DEFAULT_ENABLED,
  PRESSED,
  DISABLED,
  LOADING,
}

export default function ButtonComponent(props: ButtonComponentProps) {

  const [hover, setHover] = useState(false);

  const buttonType = props.type ? props.type : ButtonType.PRIMARY;
  const buttonSize = props.size ? props.size : ButtonSize.MEDIUM;
  const buttonState = props.state ? props.state : ButtonState.DEFAULT_ENABLED;
  const isDisabled = props.state == ButtonState.DISABLED ? true : false;

  const buttonStyle: React.CSSProperties = {
    color: props.color ? props.color : Colors.primaryColor, // Change the font color to red
    textAlign: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: 0.1,
    textTransform: "none",
    fontFamily: "Noto Sans",
    textWrap: 'nowrap',
    height: "40px",
    width: props.fullWidth ? "100%" : "fit-content",
    paddingLeft: props.padding?.left ? props?.padding?.left || "0px" : "16px",
    paddingRight: props.padding?.right
      ? props?.padding?.right || "0px"
      : "16px",
    paddingTop: props.padding?.top ? props?.padding?.top || "0px" : "10px",
    paddingBottom: props.padding?.bottom
      ? props?.padding?.bottom || "0px"
      : "10px",
  };

  const onClick = () => {
    if (isDisabled) {
      return;
    }
    props.onClick?.(props.text);
  };

  function handleStyleByProps(): React.CSSProperties {
    if (buttonType == ButtonType.TRANSPARENT) {
      return transparentTypeStyle();
    }

    if (buttonState == ButtonState.PRESSED) {
      return pressedStyle();
    }

    if (buttonState == ButtonState.DISABLED) {
      return disabledStyle();
    }

    if (buttonType == ButtonType.OUTLINE) {
      return outlineTypeStyle();
    }

    if (buttonState == ButtonState.DEFAULT_ENABLED) {
      return defaultEnabledStyle();
    }

    return buttonStyle;
  }

  function pressedStyle(): React.CSSProperties {
    const baseStyle = buttonStyle;
    baseStyle.backgroundColor = Colors.primaryColor;
    baseStyle.borderRadius = "48px";
    baseStyle.color = Colors.neutralColorWhite;

    return baseStyle;
  }

  function defaultEnabledStyle(): React.CSSProperties {
    const baseStyle = buttonStyle;
    baseStyle.backgroundColor = Colors.primaryColor;
    baseStyle.borderRadius = "48px";
    baseStyle.color = Colors.neutralColorWhite;

    return baseStyle;
  }

  function transparentTypeStyle(): React.CSSProperties {
    const baseStyle = buttonStyle;
    baseStyle.color = Colors.primaryColor;
    baseStyle.borderRadius = "48px";
    baseStyle.backgroundColor = "#00000000";
    if (hover) {
      baseStyle.backgroundColor = "#e6e4e8"; // Estilo no hover
    }
    return baseStyle;
  }

  function outlineTypeStyle(): React.CSSProperties {
    const baseStyle = buttonStyle;
    baseStyle.color = Colors.primaryColor;
    baseStyle.borderRadius = "48px";
    baseStyle.backgroundColor = "#00000000";
    baseStyle.border = "1px solid " + Colors.primaryColor;

    return baseStyle;
  }

  function disabledStyle(): React.CSSProperties {
    const baseStyle = buttonStyle;
    baseStyle.backgroundColor = Colors.neutralColor;
    baseStyle.borderRadius = "48px";
    baseStyle.color = Colors.neutralColorWhite;

    return baseStyle;
  }

  return (
    <Button
      onClick={() => onClick()}
      style={handleStyleByProps()}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      disabled={buttonState === ButtonState.DISABLED ? true : false}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {props.text}
      {props.children ? props.children : null}
    </Button>
  );
}
