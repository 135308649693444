import { useEffect, useState } from "react";
import { useDashboard } from "../../../contexts/dashboard";
import { BaseState } from "../../Actions/ViewActions/utils/BaseState";
import ReactPageBaseComponent from "../../../components/BaseComponents/ReactPageBaseComponent";
import styled from "styled-components";
import { Colors } from "../../../theme/v2/variants";
import LoadingComponent from "../../../components/StateComponents/LoadingComponent";
import ErrorComponent from "../../../components/StateComponents/ErrorComponent";
import EmptyClientAndConstructionState from "../../../components/StateComponents/EmptyClientAndConstruction";
import { MOBILE_BREAKPOINT } from "../../../utils/mobileDimen";
import RestrictionsService from "../../../services/restrictions/RestrictionsService";
import NewRestrictionComponent from "./components/NewRestrictionComponent";
import { ActionModel, IActionModel } from "../../Actions/models/ActionModel";
import NewActionComponent from "./components/NewActionComponent";
import RestrictionMappingModel, {
  IRestrictionMappingModel,
} from "../models/RestrictionModel";
import ButtonComponent, {
  ButtonType,
} from "../../../components/v2/Button/ButtonComponent";
import { ReactComponent as AddIcon } from "../../../assets/icon-plus-blue.svg";
import React from "react";
import CriteriaService from "../../../services/criteria/CriteriaService";
import ICriteriaModel from "../models/CriteriaModel";
import UserService, { UserResponsible } from "../../../services/UserService";
import RestrictionMappingService from "../../../services/restrictionMapping/RestrictionMappingService";
import { ActionItem, MappingItem, Restriction } from "../../../config/app-info";
import { useAuth } from "../../../contexts/auth";
import { Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ActionsService from "../../../services/action/ActionsService";
import { RestrictionFormEntity } from ".";
import ConfirmDeleteItemPopUp from "../../../components/Dialogs/ConfirmDeleteItemPopUp";
import CheckIcon from "@mui/icons-material/Check";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Footer from "../../../components/v2/Footer";

enum PageState {
  ADD,
  EDIT,
}

interface RestrictionsFormPageProps {}

const RestrictionFormPage: React.FC<RestrictionsFormPageProps> = () => {
  const navigation = useNavigate();
  const restrictionService = new RestrictionsService();
  const criteriaService = new CriteriaService();
  const userService = new UserService();
  const actionsService = new ActionsService();
  const restrictionMappingService = new RestrictionMappingService();
  const { constructionId, constructions, clientId, clients } = useDashboard();

  const [restrictions, setRestrictions] = useState<IRestrictionMappingModel[]>(
    []
  );
  const [criteria, setCriteria] = useState<ICriteriaModel[]>([]);
  const [newRestrictionMappingObject, setNewRestriction] =
    useState<IRestrictionMappingModel>(RestrictionMappingModel.emptyObject());
  const [selectedRestrictionId, setSelectedRestrictionId] = useState<
    string | null
  >();
  const [disabledRestriction, setDisabledRestriction] =
    useState<boolean>(false);
  const [newRestrictionName, setNewRestrictionName] = useState<string>();
  const [actions, setActions] = useState<IActionModel[]>([
    ActionModel.emptyObject(),
  ]);
  const [state, setState] = useState<BaseState>(BaseState.loading);
  const [inputValue, setInputValue] = useState<string>("");
  const [responsibles, setResponsibles] = useState<UserResponsible[]>([]);
  let { id: restrictionId } = useParams();
  const [pageState, setPageState] = useState<PageState>(
    restrictionId ? PageState.EDIT : PageState.ADD
  );
  const { user } = useAuth();
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedActionId, setSelectedActionId] = useState<string>();
  const [firstContructionId, setFirstContructionId] = useState<number>();

  useEffect(() => {
    loadPageData(constructionId);
    setFirstContructionId(constructionId);

    if (constructionId !== undefined && constructionId !== null) {
      setFirstContructionId(constructionId);
    }

    if (isConstructionIdChanged()) {
      goToList();
    }

    function isConstructionIdChanged() {
      return (
        constructionId !== undefined &&
        firstContructionId !== undefined &&
        constructionId !== firstContructionId
      );
    }
  }, [constructionId]);

  // useEffect(() => {

  // }, [constructionId]);

  function isConstructionNotSelected() {
    return (
      constructionId === null ||
      constructionId === undefined ||
      constructionId === 0
    );
  }

  const loadPageData = async (constructionId: number) => {
    try {
      if (isConstructionNotSelected()) return;
      setState(BaseState.loading);

      const restrictions = await fetchRestrictions(constructionId);
      const criteria = await fetchCriteria();
      const responsibles = await userService.fetchResponsibleUsers(
        constructionId.toString()
      );

      if (pageState === PageState.EDIT && restrictionId) {
        const restriction =
          await restrictionService.findByConstructionAndRestriction(
            constructionId,
            restrictionId
          );
        console.log(restriction);
        console.log(RestrictionMappingModel.parseFromEntity(restriction[0]));

        setNewRestriction(
          RestrictionMappingModel.parseFromEntity(restriction[0])
        );
        setActions(
          ActionModel.parseFromEntityArray(restriction[0].actionItems || [])
        );
        setDisabledRestriction(true);
      }

      setResponsibles(responsibles);
      setRestrictions(restrictions);
      setCriteria(criteria);
      setState(BaseState.success);
    } catch (error) {
      console.log(error);
      setState(BaseState.error);
    }
  };

  const loadActionsByRestriction = async (restrictionId: string) => {
    try {
      const result = await restrictionService.findByConstructionAndRestriction(
        constructionId,
        restrictionId
      );

      if (result.length === 0) {
        setActions([ActionModel.emptyObject()]);
        return;
      }

      const actions = ActionModel.parseFromEntityArray(
        result[0].actionItems || []
      );

      setActions(actions);
      setNewRestriction(RestrictionMappingModel.parseFromEntity(result[0]));
      setDisabledRestriction(true);
    } catch (error) {
      console.error(error);
    }
  };

  const loadActionsByCriteria = async (
    constructionId: number,
    criteriaId: number
  ) => {
    setState(BaseState.loading);
    actionsService
      .findActionsByConstructionAndCriteria(
        constructionId.toString(),
        criteriaId.toString(),
        true
      )
      .then((result) => {
        const emptyAction = ActionModel.emptyObject();
        const actionArray = [];
        const actions = ActionModel.parseFromEntityArray(
          result[0].actionItems || []
        );
        actionArray.push(...actions);
        actionArray.push(emptyAction);
        setActions(actionArray);
        setState(BaseState.success);
      })
      .catch((error) => {
        console.error(error);
        setState(BaseState.error);
      });
  };

  const fetchRestrictions = async (constructionId: number) => {
    const restrictions =
      await restrictionService.fetchRestrictionByConstructionId(constructionId);
    return RestrictionMappingModel.parseFromEntityArray(restrictions);
  };

  const fetchCriteria = async () => {
    const result = await criteriaService.fetchCriteria();
    return ICriteriaModel.fromEntityArray(result);
  };

  const onActionChange = (action: IActionModel) => {
    const index = actions.findIndex((a) => a.id === action.id);
    const newActions = [...actions];
    newActions[index] = action;
    setActions(newActions);
  };

  const onAddAction = () => {
    const newAction = ActionModel.emptyObject();
    setActions([...actions, newAction]);
  };

  const onCriteriaChange = (value: string, name: string) => {
    setActions([ActionModel.emptyObject()]);
    // const newRestriction = RestrictionMappingModel.emptyObject();
    // newRestriction.criteriaId = value;
    // newRestriction.criteriaName = name;
    // setNewRestriction(newRestriction);
    if (disabledRestriction === true) {
      setDisabledRestriction(false);
    }
  };

  const onNewRestrictionNameChange = (value: string | undefined) => {
    console.log("onNewRestrictionNameChange", value);
    setNewRestrictionName(value);
    setActions([ActionModel.emptyObject()]);
  };

  const onSelectedRestrictionChange = (id: string | null) => {
    console.log("onSelectedRestrictionChange", id);

    if (id === null || id === "") {
      return;
    }

    if (
      id === null &&
      actions.length > 0 &&
      actions[0].generatedByEmptyObject === false
    ) {
      setActions([ActionModel.emptyObject()]);
      setSelectedRestrictionId(null);
      return;
    }
    loadActionsByRestriction(id!!);
    setSelectedRestrictionId(id);
    setNewRestrictionName(undefined);
  };

  const onRestrictionChange = (restriction: IRestrictionMappingModel) => {
    console.log("onRestrictionChange", restriction);
    setNewRestriction(restriction);
  };

  const onSubmmit = async () => {
    if (state === BaseState.loading) {
      return;
    }
    setState(BaseState.loading);

    if (pageState === PageState.ADD) {
      try {
        await onSaveActions();
        setState(BaseState.success);
      } catch (error) {
        setState(BaseState.success);
        setSnackbarMessage("Erro ao salvar ações");
        setOpenSnackbar(true);
      }
      return;
    }

    try {
      onEditActions();
      setState(BaseState.success);
    } catch (error) {
      setState(BaseState.success);
      setSnackbarMessage("Erro ao editar ações");
      setOpenSnackbar(true);
    }
  };

  const onSaveActions = async () => {
    const validActions = actions.filter((action) =>
      ActionModel.isValid(action)
    );

    if (validActions.length === 0) {
      setSnackbarMessage(
        "Nenhuma ação está válida para salvar. Verifque se todas as ações estão preenchidas corretamente"
      );
      setOpenSnackbar(true);
      return;
    }

    if (selectedRestrictionId === null && newRestrictionName === undefined) {
      setSnackbarMessage("Nenhuma restrição selecionada");
      setOpenSnackbar(true);
      return;
    }

    if (newRestrictionName !== undefined) {
      console.log("criar nova restrição - newRestrictionName !== undefined");
      createNewRestrictionMappingByNewRestriction();
      return;
    }

    if (selectedRestrictionId) {
      const restricrionMapping = await existsRestrictionMapping(
        selectedRestrictionId,
        constructionId
      );
      console.log("restrição existente", restricrionMapping);
      if (restricrionMapping && restricrionMapping.id) {
        await addOrUpdateActions(restricrionMapping.id, restricrionMapping);
      } else {
        await createNewRestrictionMappingAndNewActions(selectedRestrictionId);
      }
    }
  };

  const createNewRestrictionMappingAndNewActions = async (
    restrictionId: string
  ) => {
    const restrictipnMapping = await newRestrictionMapping(restrictionId);
    const newActions = await addOrUpdateActions(
      restrictipnMapping.id!!,
      restrictipnMapping
    );
  };

  const createNewRestrictionMappingByNewRestriction = async () => {
    try {
      const newRestriction = await createNewRestriction();
      restrictionId = newRestriction.id.toString();
      const restrictionMapping = await newRestrictionMapping(restrictionId);
      const newActions = await addOrUpdateActions(
        restrictionMapping.id!!,
        restrictionMapping
      );
    } catch (error) {
      console.error(error);
      setSnackbarMessage(
        "Ocorreu um erro ao criar um novo mapeamento de restrição com nova restrição"
      );
      setOpenSnackbar(true);
    }
  };

  const newRestrictionMapping = async (
    restrictionId: string
  ): Promise<MappingItem> => {
    console.log(newRestrictionMappingObject);
    const { category, priority, impact } = newRestrictionMappingObject;

    if (priority === undefined) {
      setSnackbarMessage("Preencha todos os campos obrigatórios");
      setOpenSnackbar(true);
      throw new Error("Preencha todos os campos obrigatórios");
    }

    const restrictionMapping: MappingItem = {
      index: 0,
      criteriaId: Number(newRestrictionMappingObject.criteriaId),
      restrictionId: Number(restrictionId),
      constructionId: constructionId,
      userId: user.id,
      status: true,
      category: category || null,
      priority: priority,
      impact: impact || null,
      comments: newRestrictionMappingObject.comment || "",
      addedByPlanning: false,
    };

    console.log(restrictionMapping);

    const restrictionMappingResult =
      await restrictionMappingService.createRestrictionsMapping([
        restrictionMapping,
      ]);
    return restrictionMappingResult[0];
  };

  const addOrUpdateActions = async (
    restrictionMappingId: number,
    restrictionMapping: MappingItem,
    isUpdate: boolean = false
  ) => {
    const validActions =
      isUpdate === false
        ? actions
            .filter((action) => ActionModel.isValid(action))
            .filter((action) => action.generatedByEmptyObject === true)
        : actions.filter((action) => ActionModel.isValid(action));

    console.log("valid actions", validActions.length);

    const actionsAsActionItems: ActionItem[] = validActions.map(
      (action, index) => {
        const { priority } = action;
        const { category, impact } = newRestrictionMappingObject;

        if (priority === undefined) {
          setSnackbarMessage("Preencha todos os campos obrigatórios");
          setOpenSnackbar(true);
          throw new Error("Preencha todos os campos obrigatórios");
        }

        const actionItem: ActionItem = {
          index: index,
          what: action.actionDescription,
          how: action.how || "",
          whenBegin: action.beginDate.toISOString(),
          whenEnd: action.endDate.toISOString(),
          category: category || null,
          priority: priority,
          impact: impact || null,
          status: false,
          userId: user.id,
          restrictionMappingId: restrictionMappingId,
          responsibleUsersId:
            action.responsibles?.map((responsible) => Number(responsible.id)) ||
            [],
          addedByPlanning: action.generatedByEmptyObject,
          id:
            !action.generatedByEmptyObject && action.id
              ? Number(action.id)
              : undefined,
        };
        return actionItem;
      }
    );

    restrictionMappingService
      .createActions(actionsAsActionItems, [restrictionMapping])
      .then((result) => {
        setSnackbarMessage("Ações salvas com sucesso!");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setSnackbarMessage("Erro ao salvar ações");
        setOpenSnackbar(true);
      });
  };

  const createNewRestriction = async () => {
    const restriction = {
      name: newRestrictionName || "",
      status: true,
      code: "",
      criteriaId: Number(newRestrictionMappingObject.criteriaId),
      userId: user.id,
    } as RestrictionFormEntity;
    return await restrictionService.createNewRestriction(restriction);
  };

  const getRestrictionMappingId = async (
    constructionId: number,
    restrictionId: number
  ): Promise<MappingItem[]> => {
    return await restrictionMappingService.getRestrictionsMappingByConstructionIdAndRestrictionid(
      constructionId,
      restrictionId
    );
  };

  const existsRestrictionMapping = async (
    restrictionId: string,
    constructionId: number
  ): Promise<MappingItem | undefined> => {
    try {
      const restricrionMapping = await getRestrictionMappingId(
        constructionId,
        Number(restrictionId)
      );
      return restricrionMapping[0];
    } catch (error) {
      return undefined;
    }
  };

  // -- delect action methods---

  const actionDeleteOnClick = (actionId: string) => {
    setSelectedActionId(actionId);
    const index = actions.findIndex((action) => action.id === actionId);
    // remove item from array if actions[index].generatedByEmptyObject === true
    if (actions[index].generatedByEmptyObject === true) {
      setActions(actions.filter((action) => action.id !== actionId));
      return;
    }
    setIsDialogOpen(true);
  };

  const dialogButtonCancelOnClick = () => {
    setIsDialogOpen(false);
  };

  const dialogButtonConfirmOnClick = () => {
    if (selectedActionId !== undefined) {
      setIsLoadingDialog(true);
      actionsService
        .deleteAction(selectedActionId)
        .then((result) => {
          setIsLoadingDialog(false);
          setIsDialogOpen(false);
          setActions(
            actions.filter((action) => action.id !== selectedActionId)
          );
        })
        .catch((error) => {
          setIsLoadingDialog(false);
          setSnackbarMessage("Erro ao excluir ação");
          setOpenSnackbar(true);
          console.error(error);
        });
    }
  };

  // -- end delect methods ---

  // -- edit methods --

  const onEditActions = async () => {
    setState(BaseState.loading);
    const restricrionMapping = await existsRestrictionMapping(
      restrictionId!!,
      constructionId
    );
    if (restricrionMapping) {
      await addOrUpdateActions(
        restricrionMapping.id!!,
        restricrionMapping,
        true
      );
    } else {
      setSnackbarMessage("Erro ao editar ações. Restrição não encontrada");
      setOpenSnackbar(true);
    }
  };

  const onCancel = () => {
    goToList();
  };

  const goToList = () => {
    navigation(`/mapeamento-restricoes/listar/`);
  };

  return (
    <ReactPageBaseComponent>
      <PageContainer>
        {isConstructionNotSelected() ? (
          <CenterContainer>
            <EmptyClientAndConstructionState pageDescription="o cadastro de restrições" />
          </CenterContainer>
        ) : null}
        {state === BaseState.loading && !isConstructionNotSelected() ? (
          <CenterContainer>
            <LoadingComponent />
          </CenterContainer>
        ) : null}
        {state === BaseState.error ? (
          <CenterContainer>
            <ErrorComponent />
          </CenterContainer>
        ) : null}
        {state === BaseState.success && !isConstructionNotSelected() ? (
          <ContentContainer>
            <HeaderContainer>
              <AboutContainer>
                <Title>
                  {pageState === PageState.ADD
                    ? "Nova Restrição"
                    : "Editar Restrição"}
                </Title>
                <NewRestrictionContainer>
                  <NewRestrictionComponent
                    restriction={newRestrictionMappingObject}
                    restrictions={restrictions}
                    criteria={criteria}
                    onRestrictionChange={onRestrictionChange}
                    disabled={disabledRestriction}
                    disabledAllCriteriaAndRestriction={
                      disabledRestriction && pageState === PageState.EDIT
                    }
                    onCriteriaChangeCallback={(value: string, name: string) =>
                      onCriteriaChange(value, name)
                    }
                    onSelectedRestrictionChange={(id: string | null) =>
                      onSelectedRestrictionChange(id)
                    }
                    onNewRestrictionNameChange={onNewRestrictionNameChange}
                  />
                </NewRestrictionContainer>
              </AboutContainer>
            </HeaderContainer>
            <TableContainer>
              <Title>Plano de Ação</Title>
              <ActionsContainer>
                {actions.map((action, index) => (
                  <React.Fragment key={action.id}>
                    <NewActionComponent
                      action={action}
                      index={index}
                      responsibles={responsibles}
                      onActionChange={(action: IActionModel) =>
                        onActionChange(action)
                      }
                      isDisabled={
                        pageState === PageState.ADD &&
                        action.generatedByEmptyObject !== true
                      }
                      isAddState={pageState === PageState.ADD}
                      onDeletedAction={(action) =>
                        actionDeleteOnClick(action.id)
                      }
                    />
                    {index !== actions.length - 1 && <ListDivider />}
                  </React.Fragment>
                ))}
              </ActionsContainer>
              <PlusActionContainer>
                <ButtonComponent
                  text="Ação"
                  type={ButtonType.TRANSPARENT}
                  startIcon={<AddOutlinedIcon />}
                  onClick={() => onAddAction()}
                  padding={{ left: 16, right: 16, top: 8, bottom: 8 }}
                />
              </PlusActionContainer>
              <ButtonsContainer>
                <ButtonComponent
                  color={Colors.neutralGrayDark}
                  text="Cancelar"
                  type={ButtonType.TRANSPARENT_DARK}
                  onClick={() => onCancel()}
                  startIcon={<HighlightOffOutlinedIcon />}
                />
                <ButtonComponent
                  text="Salvar"
                  startIcon={<CheckIcon />}
                  onClick={() => onSubmmit()}
                  padding={{
                    top: 15,
                    bottom: 15,
                    left: 32,
                    right: 32,
                  }}
                />
              </ButtonsContainer>
            </TableContainer>
          </ContentContainer>
        ) : null}
        <Footer />
      </PageContainer>
      <Snackbar
        message={snackbarMessage}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      />
      <ConfirmDeleteItemPopUp
        title="Excluir ação"
        description="Deseja realmente excluir a ação?"
        openDeleteActionDialog={isDialogOpen}
        isLoadingDialog={isLoadingDialog}
        dialogButtonCancelOnClick={dialogButtonCancelOnClick}
        dialogButtonConfirmOnClick={dialogButtonConfirmOnClick}
      />
    </ReactPageBaseComponent>
  );
};

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  place-self: end;
  column-gap: 26px;
`;

const Title = styled.div`
  font-family: "Noto Sans";
  max-width: 100%;
  color: ${Colors.primaryColor};
  font-size: 24px;
  font-weight: 600;
  word-wrap: break-word;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
    margin-left: 16px;
  }
`;

const PageContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  background-color: ${Colors.neutralColorSurface};
  overflow-x: hidden;
  align-content: start;
`;

const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-columns: 1fr;
  max-width: 100%;
  gap: 32px;
`;

const TableContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: 1fr;
`;

const HeaderContainer = styled.div`
  display: grid;
  max-width: 100%;

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content;
    align-content: start;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-rows: max-content max-content;
  }
`;

const AboutContainer = styled.div`
  display: grid;
  align-content: start;
  grid-template-rows: 1fr max-content;
`;

const NewRestrictionContainer = styled.div`
  margin-top: 19px;
`;

const Subtitle = styled.div`
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: ${Colors.textColorBlack};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 16px;
  }
`;

const Divider = styled.div`
  display: grid;
  width: 100%;
  margin-top: 16px;
  background-color: ${Colors.primaryColor};
  height: 2px;
`;

const ListDivider = styled.div`
  display: grid;
  width: 100%;
  margin-top: 32px;
  border: 1px dashed ${Colors.neutralColor};
  margin-bottom: 32px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 19px;
`;

const PlusActionContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 86px;
  place-self: end;
`;

const ButtonContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 106px 88px;
  justify-content: end;
  margin-top: 48px;
  column-gap: 16px;
`;

export default RestrictionFormPage;
