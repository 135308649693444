import {
  Box,
  Dialog,
  Typography,
  Stack,
  DialogActions,
  DialogContent,
  IconButton,
  Checkbox,
  LinearProgress,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../../components/v2/Button/ButtonComponent";
import { ArrowBackSharp } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InputValueComponent from "../../../../components/InputValues/InputValueComponent";
import {
  ButtonContainer,
  NoContentContainer,
  SuppliersFormContainer,
  SupplierTableRow,
  SupplierTag,
} from "./PurchasingAndContractingSuppliersModal.styles";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import typography from "../../../../theme/v2/typography";
import ManageSuppliersService from "../../../../services/suppliers/ManageSuppliersService";
import { Colors } from "../../../../theme/v2/variants";
import DeleteModal from "../DeleteModal/DeleteModal";
import NoContentFoundModal from "../NoContentModal/NoContentFoundModal";
import { useDashboard } from "../../../../contexts/dashboard";
import Title from "../../../../components/v2/Typography/Tittle";

interface PurchasingAndContractingSuppliersModalProps {
  toggleModal: () => void;
  handleSubmit: (suppliers: number[]) => void;
  open: boolean;
}

export interface SuppliersProps {
  id: number;
  name: string;
  email: string;
}

export interface SuppliersValuesProps {
  id?: number;
  name: string;
  email: string;
}

const PurchasingAndContractingSuppliersModal: React.FC<
  PurchasingAndContractingSuppliersModalProps
> = ({ toggleModal, open, handleSubmit }) => {
  const { clientId } = useDashboard();
  const [suppliersList, setSuppliersList] = useState<SuppliersProps[]>([]);
  const [fieldValues, setFieldValues] = useState<SuppliersValuesProps>({
    name: "",
    email: "",
  });
  const [selectedSuppliers, setSelectedSuppliers] = useState<SuppliersProps[]>(
    []
  );
  const [selectedId, setSelectedId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const manageSuppliersService = new ManageSuppliersService();

  useEffect(() => {
    getAllClientSuppliersHandler();
  }, []);

  const getAllClientSuppliersHandler = async () => {
    try {
      const data = await manageSuppliersService.getAllClientSuppliers(clientId);
      if (data && data.length > 0) {
        setSuppliersList([...data]);
        setError(null);
      } else {
        setSuppliersList([]);
        setError(null);
      }
    } catch (error) {
      console.error("Erro ao buscar arquivos:", error);
      setError("Erro ao buscar arquivos");
      setSuppliersList([]);
    }
  };

  const openDeleteModalHandler = (id: number) => {
    setSelectedId(id);
    setOpenDeleteModal(!openDeleteModal);
  };

  const onSubmitHandler = () => {
    const selectedSupplierIds: number[] = selectedSuppliers.map(
      (supplier) => supplier.id
    );
    handleSubmit(selectedSupplierIds);
  };

  const createNewSupplierHandler = async () => {
    setIsLoading(true);

    if (!fieldValues.name.trim() || !fieldValues.email.trim()) {
      setError("Nome e e-mail são obrigatórios");
      setIsLoading(false);
      return;
    }

    const newSupplier = { ...fieldValues, client: clientId };
    try {
      const response = await manageSuppliersService.createNewSupplier(
        newSupplier
      );
      const supplier = response?.data;

      if (response?.status == 201 && response.statusText == "Created") {
        setSuppliersList([...suppliersList, supplier]);
        setFieldValues({
          name: "",
          email: "",
        });
        setError(null);
        return setIsLoading(false);
      }
    } catch (error) {
      console.error("Erro ao adicionar fornecedor:", error);
      setError("Erro ao adicionar fornecedor");
      setIsLoading(false);
    }
  };

  const deleteSupplierHandler = async (id: number) => {
    try {
      const response = await manageSuppliersService.deleteSupplier(id);

      if (response?.status == 200 && response.statusText == "OK") {
        const newSuppliersList = suppliersList.filter((item) => item.id !== id);
        setSuppliersList([...newSuppliersList]);
        setError(null);
        return setOpenDeleteModal(false);
      }
    } catch (error) {
      console.error("Erro ao excluir fornecedor:", error);
      setError("Erro ao excluir fornecedor");
      setOpenDeleteModal(false);
    }
  };

  const selectSuppliersHandler = (newSupplier: SuppliersProps) => {
    const isSelected = selectedSuppliers.some(
      (supplier) => supplier.id === newSupplier.id
    );

    if (isSelected) {
      setSelectedSuppliers(
        selectedSuppliers.filter((item) => item.id !== newSupplier.id)
      );
    } else {
      setSelectedSuppliers([...selectedSuppliers, newSupplier]);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="paper"
      onClose={toggleModal}
      open={open}
      PaperProps={{
        style: dialogPaperStyles,
      }}
    >
      <Box sx={{ paddingX: "25px", paddingTop: "25px" }}>
        <Box sx={boxHeaderStyles}>
          <ButtonComponent
            text={"Voltar"}
            type={ButtonType.TRANSPARENT}
            startIcon={<ArrowBackSharp />}
            onClick={toggleModal}
          />
          <CloseRoundedIcon
            fontSize="medium"
            onClick={toggleModal}
            style={closeIconStyles}
          />
        </Box>
        <Title>Lista de Fornecedores</Title>
        <Typography mt="25px" sx={dialogSubtitleStyles}>
          Adicionar Novo Fornecedor
        </Typography>
      </Box>
      <DialogContent sx={{ overflow: "hidden" }}>
        <SuppliersFormContainer>
          <InputValueComponent
            variant="filled"
            label="Nome do Fornecedor"
            value={fieldValues.name}
            onChange={(value: string) => {
              setFieldValues({ ...fieldValues, name: value });
            }}
          />
          <InputValueComponent
            variant="filled"
            label="E-mail"
            value={fieldValues.email}
            onChange={(value: string) => {
              setFieldValues({ ...fieldValues, email: value });
            }}
            endIcon={
              <IconButton
                onClick={createNewSupplierHandler}
                disableRipple={false}
              >
                <SendOutlinedIcon fontSize="medium" />
              </IconButton>
            }
          />
        </SuppliersFormContainer>
        {suppliersList.length !== 0 ? (
          <Box
            mt="24px"
            paddingBottom="15px"
            sx={{
              overflowY: "auto",
              height: "30vh",
              "&::-webkit-scrollbar": {
                width: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: Colors.secondaryColorLight,
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: Colors.borderColorGray,
              },
            }}
          >
            <Box
              mr={suppliersList.length > 2 ? "20px" : ""}
              borderRadius="10px"
              border={`1px solid ${Colors.borderColorGray}`}
              boxShadow={`0px 5px 10px  ${Colors.borderColorGray}`}
              overflow="hidden"
            >
              {error ? (
                <Alert variant="filled" severity="error">
                  {error}
                </Alert>
              ) : null}
              {isLoading ? (
                <LinearProgress
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: Colors.borderColorGray,
                  }}
                  sx={{
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: Colors.greenLight,
                    },
                  }}
                />
              ) : null}
              {suppliersList.map((row) => (
                <SupplierTableRow key={row.id}>
                  <Stack
                    display="flex"
                    alignItems="center"
                    gap="10px"
                    direction="row"
                  >
                    <Checkbox
                      sx={{
                        color: Colors.secondaryColorLight,
                        "&.Mui-checked": {
                          color: Colors.secondaryColorLight,
                        },
                      }}
                      onChange={() => selectSuppliersHandler(row)}
                      size="medium"
                    />
                    <SupplierTag>
                      <Typography
                        lineHeight="100%"
                        fontSize={typography.h5?.fontSize}
                        fontWeight={typography.fontWeightRegular}
                        sx={{ textTransform: "uppercase" }}
                        color="white"
                      >
                        {row.name.at(0)}
                        {row.name.split(" ")[1]?.at(0)}
                      </Typography>
                    </SupplierTag>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        lineHeight="100%"
                        fontSize={typography.h6?.fontSize}
                      >
                        {row.name}
                      </Typography>
                      <Typography color={Colors.greenLight}>
                        {row.email}
                      </Typography>
                    </Box>
                  </Stack>
                  <Box>
                    <IconButton
                      disableRipple={false}
                      onClick={() => openDeleteModalHandler(row.id!)}
                    >
                      <DeleteOutlineIcon fontSize="medium" />
                    </IconButton>
                  </Box>
                </SupplierTableRow>
              ))}
            </Box>
          </Box>
        ) : (
          <NoContentContainer>
            <Typography
              fontSize={typography.h3?.fontSize}
              fontWeight={typography.fontWeightBold}
            >
              Nenhum Fornecedor Cadastrado.
            </Typography>
          </NoContentContainer>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "20px" }}>
        <ButtonContainer>
          <ButtonComponent
            text={"Cancelar"}
            type={ButtonType.OUTLINE}
            onClick={toggleModal}
          />
          <ButtonComponent
            state={
              selectedSuppliers.length === 0
                ? ButtonState.DISABLED
                : ButtonState.DEFAULT_ENABLED
            }
            startIcon={<SendOutlinedIcon fontSize="medium" />}
            text={"Enviar Pedido de Cotação"}
            onClick={onSubmitHandler}
          />
        </ButtonContainer>
        {openDeleteModal && (
          <DeleteModal
            open={openDeleteModal}
            isLoading={isLoading}
            toggleModal={() => setOpenDeleteModal(!openDeleteModal)}
            deleteHandler={() => deleteSupplierHandler(selectedId!)}
            title=" Excluir Fornecedor"
            text="Tem certeza que deseja excluir esse fornecedor? Essa ação é irreversível."
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

const dialogPaperStyles = {
  width: "100vw",
  height: "80vh",
  borderRadius: "16px",
};

const boxHeaderStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const closeIconStyles = {
  cursor: "pointer",
};

const dialogTitleStyles = {
  fontSize: "32px",
  fontWeight: "bold",
};

const dialogSubtitleStyles = {
  fontSize: "18px",
  fontWeight: "regular",
};

export default PurchasingAndContractingSuppliersModal;
