import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PurchasingItem } from "../../pages/PurchasingContractingPlanPage/models/PurchasingModel";

type Props = {
  open: boolean
  title: string
  onConfirm: () => void
  onClose: () => void
  children: React.ReactNode
}

export default function ConfirmDialog({
  open,
  title,
  onConfirm,
  onClose,
  children
}: Props) {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          { title }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { children }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Sair</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}