import styled from "styled-components";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Snackbar,
} from "@mui/material";
import React, { useImperativeHandle } from "react";
import InputValueComponent from "./InputValueComponent";
import { set } from "date-fns";
import { ref } from "yup";

import { selectStyle } from "../../../utils/custom-styles/selectCustomStyle";

export enum DropDownComponentStyle {
  OUTLINED = "outlined",
  TRANSPARENT = "transparent",
}

interface DropDownComponentProps {
  key: string;
  selectedValues?: string[];
  onSelectedValueChanged: (selectedItems: string[]) => void;
  values: any[];
  defaultValue?: string;
  size?: "small" | "medium";
  style?: DropDownComponentStyle;
  selectedValue?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isWhite?: boolean;
  isNavBar?: boolean;
  variant?: "outlined" | "filled" | "standard" | undefined;
  sx?: any;
  backgroundColor?: string;
  height?: string;
}

export interface DropDownComponentRef {
  cleanData: () => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DropDownChecklistComponent = React.forwardRef<
  DropDownComponentRef | undefined,
  DropDownComponentProps
>(({ ...props }, ref) => {
  const key = props.key;
  const values = props.values;
  const deaultValue = props.defaultValue;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const style = props.style ?? DropDownComponentStyle.OUTLINED;
  const [item, setItem] = React.useState<string[]>(props.selectedValues ?? []);

  React.useEffect(() => {
    setItem(props.selectedValues ?? []);
  }, [props.selectedValues]);

  useImperativeHandle(ref, () => ({
    cleanData: () => {
      setItem([]);
    },
  }));

  const handleChange = (event: SelectChangeEvent<typeof item>) => {
    const {
      target: { value },
    } = event;
    const selectedItems = typeof value === "string" ? value.split(",") : value;
    setItem(
      // On autofill we get a stringified value.
      selectedItems
    );
    props.onSelectedValueChanged(selectedItems);
  };

  const inputStyles = props.isWhite
    ? {
        color: "white",
        borderRadius: 16,
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
        },
        ".MuiSvgIcon-root ": {
          fill: "white !important",
        },
      }
    : null;

  const defaultStyles = {
    borderRadius: props.variant ? "" : props.isNavBar ? "50px" : "16px",
    height: props.isNavBar ? "45px" : props.height ? props.height : "auto",
    backgroundColor: props.backgroundColor ? props.backgroundColor : "transparent",
    fontSize: "16px",
  };

  return (
    <Root>
      <FormControl variant={props.variant} sx={{ width: "100%" }}>
        <InputLabel
          size={props.size == "small" ? "small" : "normal"}
          id="demo-multiple-checkbox-label"
          style={
            props.isWhite
              ? { color: "white" }
              : props.variant
              ? { fontSize: "16px", color: "#49454F" }
              : {}
          }
        >
          {props.placeholder}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={item}
          onChange={handleChange}
          variant={props.variant}
          size={props.size}
          style={defaultStyles}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          disabled={props.isDisabled}
          sx={
            props.sx
              ? { ...inputStyles, ...props.sx }
              : { ...inputStyles, ...(props.variant && selectStyle) }
          }
        >
          {values.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={item.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Root>
  );
});

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export default DropDownChecklistComponent;
