import styled from "styled-components";
import { Colors } from "../../theme/v2/variants";
import { MOBILE_BREAKPOINT } from "../../utils/mobileDimen";
import { Tab } from "@mui/material";

const CenterContainer = styled.div`
  display: grid;
  height: 85vh;
  align-content: center;
`;

const PageContainer = styled.div`
  /* display: grid; */
  //height: calc(100vh);
  max-width: 100%;
  min-height: 90vh;
  /* grid-template-columns: 1fr; */
  /* grid-template-rows: 1fr; */
  background-color: ${Colors.neutralColorSurface};
  /* overflow: visible; */
  position: relative;
  align-content: start;
`;

const TitleContainer = styled.div`
  /* display: grid; */
  display: flex;
  flex-direction: column;
  /* max-width: 100%; */
  /* grid-template-columns: 1fr max-content max-content; */
  /* margin-top: 112px; */
  column-gap: 24px;
  /* align-items: center; */
  /* margin-right: 32px;
  margin-left: 32px; */

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

const Description = styled.p`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  color: ${Colors.textColorGray};
  margin: 0;
  margin-top: 12px;
  /* padding-left: 32px; */
  /* max-width: 100%; */
  height: fit-content;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    /* padding-left: 16px;
    padding-right: 32px; */
    // go to next-line if the text is too long
    font-size: 14px;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
  margin-bottom: 150px;
`;

const SliderContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  /* padding: 100px; */
  /* display: none; */
/* 
  @media (max-width: 1200px) {
    width: 80vw;
  }
    
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  } */

`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  
  @media (max-width: ${MOBILE_BREAKPOINT}px) {

  }
`;

const MobileMainButtonContainer = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 1200px) {
    align-self: flex-end;
    margin-top: 20px;
  }
 
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 20px;
  }

`;

const TabContainer = styled.div`
  margin-top: 32px;
  /* margin-left: 32px; */

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    /* margin-left: 16px;
    margin-right: 16px; */
  }
`;

const ListPurchasingContractionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
  /* width: 150vw; */
  /* margin-left: 32px; */
  /* margin-right: 32px; */
  //overflow: hidden;
  //height: 100vh;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    /* margin-left: 16px;
    margin-right: 16px; */
    max-width: 100%;
    /* width: 150vw; */
    /* overflow-x: auto; */
  }

  @media (max-width: 900px) {
    width: 150vw;
  }

  @media (min-width: 900px) and (max-width: 1400px) {
      width: 80vw;
  }
`;

const ListContainer = styled.div`
  height: 100%;
  width: 100%;

  /* background-color: red;
  padding: 10px; */
  //overflow-y: auto;

  //&::-webkit-scrollbar {
  //  display: none;
  //}

  /* Para navegadores Firefox */
  //-ms-overflow-style: none;
  //scrollbar-width: none;
`;



const CustomTab = styled((props) => <Tab {...props} />)(() => ({
  fontFamily: "Noto Sans",
  fontSize: 16,
  fontStyle: "normal",
  lineHeight: "normal",
  fontWeight: 400,
  paddingLeft: 48,
  paddingRight: 48,
  color: `${Colors.LinkColor}`,
  "&.Mui-selected": {
    color: `${Colors.primaryColorDark}`,
    fontWeight: 700,
  },
}));

const EmptyListContainer = styled.div`
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: ${Colors.textColorBlack};
  width: 454px;
  place-self: center;
  margin-top: 146px;
  text-align: center;
`;

export {
  CenterContainer,
  PageContainer,
  TitleContainer,
  Description,
  ContentContainer,
  TabContainer,
  CustomTab,
  ListPurchasingContractionContainer,
  MobileMainButtonContainer,
  EmptyListContainer,
  ListContainer,
  HeaderContainer,
  SliderContainer
};
